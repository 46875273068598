import { InlineNotification, Modal } from "@carbon/react";
import { useFormik } from "formik";
import Margin from "~/components/Margin";

import * as Yup from "yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import useLoading from "~/hooks/useLoading";
import Select from "react-select";

import useAttend from "~/hooks/useAttend";

import { IArea, IAttendLog } from "~/types";
import { useParams } from "react-router-dom";
import {
  getAttendDetailsAPI,
  patchAttendDetailsAPI,
} from "~/services/api/attend";
import useArea from "~/hooks/useArea";
import { getAreasAPI } from "~/services/api/areas";
import Label from "~/components/Label";
import useAreaType from "~/hooks/useAreaType";
import { getAreaTypesAPI } from "~/services/api/areaTypes";
import { getAttendLogsAPI, patchAttendLogAPI } from "~/services/api/attendLog";
import useUser from "~/hooks/useUser";

type Values = {
  area: string;
  area_type: string;
};

const initialValues: Values = {
  area: "",
  area_type: "",
};

const validationSchema = Yup.object({
  area: Yup.string(),
  area_type: Yup.string(),
});

const UpdateAreaAttendModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();
  const {
    attendDetails,

    setAttendDetails,
    setOpenUpdatedAttendAreaModal,
    attendLog,
    setAttendLog,
  } = useAttend();
  const { areas, setAreas } = useArea();
  const { setAreaTypes, areaTypes } = useAreaType();

  const { userProfile } = useUser();
  const { id } = useParams();
  const [error, setError] = useState<boolean>(false);

  const fetchAreas = async () => {
    try {
      const { data } = await getAreasAPI();
      setAreas(data.results);
    } catch (e: unknown) {
      console.log(e);
    }
  };

  const { handleSubmit, setFieldValue, values } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const areaOptions = useMemo(() => {
    return areas?.map((area: IArea) => ({
      value: String(area.id),
      label: area.name,
    }));
  }, [areas]);

  const areaTypesOptions = useMemo(() => {
    return areaTypes
      ?.filter((areaType) => areaType.area.id === Number(values.area))
      .map((areaType) => ({
        value: String(areaType.type.id),
        label: `${areaType.type.name}`,
      }));
  }, [areaTypes, values.area]);

  type SelectOption = {
    value: string;
    label: string;
  };

  const handleSelectAreaType = (
    selectedOption: SelectOption | null,
    actionMeta: { action: string }
  ) => {
    if (selectedOption) {
      setFieldValue("area_type", selectedOption.value);
    } else if (actionMeta.action === "clear") {
      setFieldValue("area_type", "");
    }
  };

  const handleSelectArea = (selectedOption: SelectOption | null) => {
    if (selectedOption) {
      setFieldValue("area", selectedOption.value);
    }
  };

  const fetchAreaTypes = useCallback(async () => {
    try {
      const { data } = await getAreaTypesAPI();
      setAreaTypes(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setAreaTypes]);

  const fetchAttends = useCallback(async () => {
    if (id) {
      try {
        const { data } = await getAttendDetailsAPI(Number(id));

        setAttendDetails(data);

        setLoading(false);
      } catch (err: unknown) {
        console.error(err);
        setLoading(false);
      }
    }
  }, [id]);

  const fetchSendNewArea = useCallback(async () => {
    setLoading(true);
    try {
      await patchAttendDetailsAPI(Number(id), {
        type: Number(values.area_type),
        owner: null,
        status: 1,
      });

      await patchAttendLogAPI(
        {
          actual_area: Number(values.area),
          actual_profile: userProfile?.id,
          previous_profile: attendDetails?.owner?.id,
          previous_area: attendLog?.actual_area.id,
        },
        Number(attendLog?.id)
      );

      const { data } = await getAttendLogsAPI();
      const filteredData = data.results.filter(
        (log: IAttendLog) => log.attend === Number(id)
      );

      setAttendLog(filteredData[0]);

      await fetchAttends();

      setLoading(false);
      setOpenUpdatedAttendAreaModal(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, [
    values.area_type,
    id,
    setLoading,
    setOpenUpdatedAttendAreaModal,
    attendDetails?.owner?.id,
    attendLog?.actual_area.id,
    attendLog?.id,
    userProfile?.id,
  ]);

  useEffect(() => {
    fetchAreas();
    fetchAreaTypes();
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Modal
        style={{ zIndex: 3 }}
        modalHeading={`Atualizar área - #${attendDetails?.id} ${attendDetails?.type?.name}`}
        size="sm"
        open={open}
        onRequestClose={() => {
          onClose();
          setError(false);
        }}
        primaryButtonText="Concluir"
        secondaryButtonText="Cancelar"
        primaryButtonDisabled={
          values.area === "" ||
          values.area === undefined ||
          values.area_type === "" ||
          values.area_type === undefined
        }
        onRequestSubmit={async () => {
          fetchSendNewArea();
        }}
      >
        {error && (
          <InlineNotification
            title="Erro!"
            // subtitle={error}
            subtitle="Não foi possivel trocar a àrea de atendimento."
            hideCloseButton
            kind="error"
            lowContrast
          />
        )}
        <Margin mt={20} />
        <Label text="Selecione uma área" />
        <Select
          options={areaOptions}
          onChange={handleSelectArea}
          placeholder="Pesquisar ou selecionar uma área..."
        />{" "}
        <Margin mb={20} />
        <Label text="Selecione uma tipo de atendimento" />
        <Select
          isDisabled={values.area === undefined || values.area === ""}
          isClearable={true}
          options={areaTypesOptions}
          onChange={(selectedOption, actionMeta) =>
            handleSelectAreaType(selectedOption, actionMeta)
          }
          placeholder="Pesquisar ou selecionar Tipo de atendimento..."
        />
        <Margin mb={204} />
      </Modal>
    </form>
  );
};

export default UpdateAreaAttendModal;
