import {
  Table,
  TableBatchAction,
  TableBatchActions,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Grid,
  Column,
  Button,
  Pagination,
} from "@carbon/react";

import {
  Add,
  Save,
  Download,
  Edit,
  TrashCan,
  Renew,
} from "@carbon/icons-react";
import Margin from "~/components/Margin";
import { useCallback, useEffect, useMemo, useState } from "react";
import Padding from "~/components/Padding";
import { permissions } from "~/services/permissions";
import useLoading from "~/hooks/useLoading";
import usePaymentType from "~/hooks/useTypesPayment";
import { getPaymentTypeAPI } from "~/services/api/paymentTypes";
import NewPaymentTypeModal from "./NewPaymentTypeModal";
import DeletePaymentTypeModal from "./DeletePaymentTypeModal";
import EditPaymentTypeModal from "./EditPaymentTypeModal";

const tableHeadersArea: string[] = ["Nome", ""];

const PaymentsTypes = () => {
  const {
    paymentTypes,
    setPaymentTypes,
    setOpenNewPaymentTypeModal,
    openNewPaymentTypeModal,
    setPaymentTypesDetails,
    setOpenDeletePaymentTypeModal,
    setOpenEditPaymentTypeModal,
    openDeletePaymentTypeModal,
    openEditPaymentTypeModal,
  } = usePaymentType();
  const { setLoading } = useLoading();

  const canReadAttendTypes = permissions.includes("can_read_attend_types");
  const canCreateAttendTypes = permissions.includes("can_create_attend_types");
  const canEditAttendTypes = permissions.includes("can_edit_attend_types");
  const canDeleteAttendTypes = permissions.includes("can_delete_attend_types");

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const paginatedPaymentTypes = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return paymentTypes
      ?.sort((a, b) =>
        a.name.localeCompare(b.name, "pt-BR", { sensitivity: "base" })
      )
      ?.slice(start, end);
  }, [currentPage, itemsPerPage, paymentTypes]);

  const fetchPaymentTypes = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getPaymentTypeAPI();
      setPaymentTypes(data.results.reverse());
      setLoading(false);
    } catch (err: unknown) {
      setLoading(false);
      console.error(err);
    }
  }, [setPaymentTypes, setLoading]);

  useEffect(() => {
    fetchPaymentTypes();
  }, [fetchPaymentTypes]);

  return (
    <Grid>
      <Column span={16}>
        <Margin mt={64} /> <h1>Tipos de Despesas</h1>
        <Margin mt={34} /> <Margin mt={20} />
        <TableContainer>
          <Margin mb={20} />
          <TableToolbar>
            {canReadAttendTypes && (
              <Button
                tabIndex={0}
                onClick={() => {
                  fetchPaymentTypes();
                }}
                kind="secondary"
                renderIcon={Renew}
              >
                Atualizar
              </Button>
            )}
            {!canReadAttendTypes && (
              <div
                style={{
                  height: "400px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <h4 style={{ position: "relative", left: "45%" }}>
                  Você não possui permissão para visualizar os tipos de
                  atendimento
                </h4>
              </div>
            )}
            <TableBatchActions onCancel={console.log} totalSelected={0}>
              <TableBatchAction
                tabIndex={0}
                renderIcon={TrashCan}
                onClick={console.log}
              >
                Delete
              </TableBatchAction>
              <TableBatchAction
                hasIconOnly
                iconDescription="Add"
                tabIndex={0}
                renderIcon={Add}
                onClick={console.log}
              >
                Delete
              </TableBatchAction>
              <TableBatchAction
                hasIconOnly
                iconDescription="Save"
                tabIndex={0}
                renderIcon={Save}
                onClick={console.log}
              >
                Save
              </TableBatchAction>
              <TableBatchAction
                tabIndex={0}
                renderIcon={Download}
                onClick={console.log}
              >
                Download
              </TableBatchAction>
            </TableBatchActions>
            <TableToolbarContent>
              {/* <TableToolbarSearch
                  tabIndex={0}
                  onChange={handleChange}
                  value={values.search}
                  id="search"
                  placeholder="Pesquisar"
                /> */}
              {canCreateAttendTypes && (
                <Button
                  tabIndex={0}
                  onClick={() => {
                    setOpenNewPaymentTypeModal(true);
                  }}
                  kind="primary"
                >
                  Novo tipo de despesa
                </Button>
              )}
            </TableToolbarContent>
          </TableToolbar>
          {canReadAttendTypes && (
            <Table>
              <TableHead>
                <TableRow>
                  {/* <TableSelectAll
                    name={""}
                    id={""}
                    onSelect={console.log}
                    checked={false}
                    ariaLabel={""}
                  /> */}
                  {tableHeadersArea.map((columnTitle, index) => {
                    return (
                      <TableHeader key={String(index)}>
                        {columnTitle}
                      </TableHeader>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedPaymentTypes?.length === 0 ? (
                  <TableCell colSpan={tableHeadersArea.length + 1}>
                    <Padding pt={24} pb={24} pl={12}>
                      <div
                        style={{
                          height: "300px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <h4>Nenhum tipo de despesa criado</h4>
                      </div>
                    </Padding>
                  </TableCell>
                ) : paginatedPaymentTypes.length > 0 ? (
                  paginatedPaymentTypes?.map((paymentType, index) => (
                    <TableRow key={String(index)} style={{ cursor: "pointer" }}>
                      {/* <TableSelectRow
                        name={""}
                        id={""}
                        onSelect={console.log}
                        checked={false}
                      />{" "} */}
                      <TableCell>{paymentType?.name}</TableCell>

                      <TableCell>
                        {canEditAttendTypes && (
                          <Button
                            kind="ghost"
                            hasIconOnly
                            iconDescription="Editar"
                            renderIcon={Edit}
                            id="filter"
                            onClick={(e) => {
                              e.stopPropagation();
                              setPaymentTypesDetails(paymentType);
                              setOpenEditPaymentTypeModal(true);
                            }}
                          />
                        )}

                        {canDeleteAttendTypes && (
                          <Button
                            kind="ghost"
                            hasIconOnly
                            iconDescription="Exluir"
                            renderIcon={TrashCan}
                            onClick={(e) => {
                              e.stopPropagation();
                              setPaymentTypesDetails(paymentType);
                              setOpenDeletePaymentTypeModal(true);
                            }}
                            id="filter"
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  paginatedPaymentTypes?.map((type, index) => (
                    <TableRow
                      key={String(index)}
                      style={{ cursor: "pointer" }}
                      // onClick={() => alert(index)}
                    >
                      {/* <TableSelectRow
                        name={""}
                        id={""}
                        onSelect={console.log}
                        checked={false}
                      /> */}
                      <TableCell>{type?.name}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          )}
          <Pagination
            totalItems={paymentTypes?.length}
            pageSize={itemsPerPage}
            pageSizes={[5, 10, 20, 30, 40, 50]}
            onChange={({ page, pageSize }) => {
              setCurrentPage(page);
              setItemsPerPage(pageSize);
            }}
            page={currentPage}
            size="md"
          />
        </TableContainer>
      </Column>
      <NewPaymentTypeModal
        onClose={() => setOpenNewPaymentTypeModal(false)}
        open={openNewPaymentTypeModal}
      />
      <EditPaymentTypeModal
        onClose={() => setOpenEditPaymentTypeModal(false)}
        open={openEditPaymentTypeModal}
      />

      <DeletePaymentTypeModal
        onClose={() => setOpenDeletePaymentTypeModal(false)}
        open={openDeletePaymentTypeModal}
      />
    </Grid>
  );
};

export default PaymentsTypes;
