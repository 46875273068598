import { HTMLProps, ReactNode } from "react";

import styled from "styled-components";

type SpacingProps = {
  pt?: number;
  pb?: number;
  pl?: number;
  pr?: number;
  x?: number;
  y?: number;
  all?: number;
  children?: ReactNode;
};

type PaddingProps = HTMLProps<HTMLDivElement> & SpacingProps;

type Directions = "top" | "bottom" | "left" | "right";

const getPadding = (direction: Directions) => (props: SpacingProps) => {
  switch (direction) {
    case "top":
      if (props.pt) return props.pt;
      if (props.y) return props.y;
      if (props.all) return props.all;

      return 0;
    case "bottom":
      if (props.pb) return props.pb;
      if (props.y) return props.y;
      if (props.all) return props.all;

      return 0;
    case "left":
      if (props.pl) return props.pl;
      if (props.x) return props.x;
      if (props.all) return props.all;

      return 0;
    case "right":
      if (props.pr) return props.pr;
      if (props.x) return props.x;
      if (props.all) return props.all;

      return 0;
    default:
      return 0;
  }
};

const PaddingContainer = styled.div<PaddingProps>`
  padding-top: ${getPadding("top")}px;
  padding-bottom: ${getPadding("bottom")}px;
  padding-left: ${getPadding("left")}px;
  padding-right: ${getPadding("right")}px;
`;

const Padding = ({ children, ...props }: PaddingProps) => {
  return <PaddingContainer {...props}>{children}</PaddingContainer>;
};

export default Padding;
