import {
  Table,
  TableBatchAction,
  TableBatchActions,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  // TableToolbarSearch,
  Grid,
  Column,
  Button,
  Pagination,
  Tile,
  // Tooltip,
  // Tile,
} from "@carbon/react";

import {
  TrashCan,
  Add,
  Save,
  Download,
  Edit,
  Renew,
} from "@carbon/icons-react";
import Margin from "~/components/Margin";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import useLoading from "~/hooks/useLoading";

import FiltersModal from "./FilterModal";
import styled from "styled-components";
import useTask from "~/hooks/useTask";
import { getTasksAPI } from "~/services/api/task";
import { useFormik } from "formik";
import { format } from "date-fns";
import { permissions } from "~/services/permissions";
import Padding from "~/components/Padding";
import { Tabs } from "antd";
import { getAttendTaskAPI } from "~/services/api/attendTask";
import { IAttendTask } from "~/types";
import DeleteTaskModal from "./DeleteTaskModal";
import EditTaskModal from "./EditTaskModal";
import Badge from "~/components/Badge";
import UpdateOwnerTaskModal from "./UpdateOwnerTaskModal";

const TileContainer = styled.div`
  display: flex;
  gap: 15px;
`;

const tableHeadersTasksCreatedByMe: string[] = [
  "ID",
  "Status",
  "Nome",
  "Responsável",
  "Criada em",
  "Atualizada em",
  "",
];

const tableHeadersTasksForMe: string[] = [
  "ID",
  "Status",
  "Nome",
  "Responsável",
  // "Criada em",
  // "Atualizada em",
];

const renderStatus: { [key: string]: React.ReactNode } = {
  opened: <Badge label="Em aberto" color="#e3e3e3" textColor="#696868" />,
  created: <Badge label="Criada" color="#e3e3e3" textColor="#696868" />,
  in_progress: (
    <Badge color="#ffe9c9" textColor="#ff9900" label="Em andamento" />
  ),
  done: <Badge color="#CCEFDF" textColor="#00B360" label="Finalizada" />,
  return_to_client: (
    <Badge color="#bacbff" textColor="#1906ec" label="Retor. solicitante" />
  ),
  canceled: <Badge label="Cancelada" textColor="#ff0000" color="#f9c6c6" />,
};

// const tableHeadersTasks: string[] = [
//   "ID",
//   "Status",
//   "Nome",
//   "Responsável",
//   "Atendimento",
//   "Atribuida por",
//   "data de atribuição",
// ];

const Tasks = () => {
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const { TabPane } = Tabs;

  const {
    setTasks,
    tasks,
    attendTasks,
    setAttendTasks,
    openDeleteTaskModal,
    setOpenDeleteTaskModal,
    setTaskDetails,
    setOpenEditTaskModal,
    openEditTaskModal,
    openUpdateOwnerTaskModal,
    setOpenUpdateOwnerTaskModal,
  } = useTask();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  // const [searchTerm, setSearchTerm] = useState("");
  // const [filteredAttends, setFilteredAttends] = useState(tasks);

  // const renderTiles: { label: string; total: number }[] = [
  //   { label: "Abertas", total: 1 },
  //   { label: "Em andamento", total: 0 },
  //   { label: "Retor. solicitante", total: 0 },
  //   { label: "Finalizadas", total: 0 },
  //   { label: "Canceladas", total: 0 },
  // ];

  const canReadTasks = permissions.includes("can_read_tasks");
  const canCreateTasks = permissions.includes("can_create_tasks");
  const canEditTasks = permissions.includes("can_edit_tasks");
  const canDeleteTasks = permissions.includes("can_delete_tasks");

  const paginatedTasks = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return tasks.slice(start, end);
  }, [currentPage, itemsPerPage, tasks]);

  const { values } = useFormik({
    initialValues: {
      search: "",
    },
    onSubmit: () => {},
  });

  // const filterTasks = () => {
  //   const filtered = tasks.filter((profile) =>
  //     profile.subject.toLowerCase().includes(searchTerm.toLowerCase())
  //   );
  //   setFilteredAttends(filtered);
  // };

  const fetchTasks = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getTasksAPI();
      setTasks(data.results.reverse().sort((a, b) => b.id - a.id));

      setLoading(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, [setLoading, setTasks]);

  const fetchAttendTasks = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getAttendTaskAPI();

      setAttendTasks(data.results.reverse());

      await fetchTasks();
      setLoading(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, []);

  const renderTiles: { label: string; total: number }[] = useMemo(() => {
    return [
      { label: "Criadas", total: tasks.length },
      { label: "Em aberto ", total: 0 },
      { label: "Em andamento", total: 0 },
      {
        label: "Aguardando solicitante",
        total: 0,
      },
      { label: "Finalizadas", total: 0 },
      { label: "Canceladas", total: 0 },
    ];
  }, [tasks]);

  const renderTilesAttendTasks: { label: string; total: number }[] =
    useMemo(() => {
      return [
        { label: "Criadas", total: attendTasks.length },
        { label: "Em aberto ", total: 0 },
        { label: "Em andamento", total: 0 },
        {
          label: "Aguardando solicitante",
          total: 0,
        },
        { label: "Finalizadas", total: 0 },
        { label: "Canceladas", total: 0 },
      ];
    }, [tasks]);

  useEffect(() => {
    fetchAttendTasks();
    fetchTasks();
  }, []);

  useEffect(() => {
    // filterTasks();
  }, [values.search]);

  return (
    <Grid>
      <Column span={16}>
        <Margin mt={64} /> <h1>Tarefas</h1>
        <Margin mt={14} />{" "}
        <TileContainer>
          {/* {renderTiles.map((tile, index) => {
            return (
              <Tile style={{ flex: 1 }} key={String(index)}>
                <div>
                  <p>{tile.label}</p>
                  <h2>{tile.total}</h2>
                </div>
              </Tile>
            );
          })} */}
        </TileContainer>
        <Tabs
          defaultActiveKey="1"
          tabBarStyle={{
            margin: 0,
            borderBottom: "none",
            color: "red",
          }}
        >
          <TabPane tab="Criadas por mim" key="1">
            <div>
              <Margin mt={30} />
              <TileContainer>
                {renderTiles.map((tile, index) => {
                  return (
                    <Tile style={{ flex: 1 }} key={String(index)}>
                      <div>
                        <p>{tile.label}</p>
                        <h2>{tile.total}</h2>
                      </div>
                    </Tile>
                  );
                })}
              </TileContainer>
              <Margin mt={20} />
              <TableContainer>
                <TableToolbar>
                  {canReadTasks && canCreateTasks && (
                    <Button
                      tabIndex={0}
                      onClick={() => {
                        fetchTasks();
                      }}
                      kind="secondary"
                      renderIcon={Renew}
                    >
                      Atualizar
                    </Button>
                  )}
                  {!canCreateTasks && (
                    <div
                      style={{
                        height: "400px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <h4 style={{ position: "relative", left: "45%" }}>
                        Você não possui permissão para criar tarefas
                      </h4>
                    </div>
                  )}
                  <TableBatchActions onCancel={console.log} totalSelected={0}>
                    <TableBatchAction
                      tabIndex={0}
                      renderIcon={TrashCan}
                      onClick={console.log}
                    >
                      Delete
                    </TableBatchAction>
                    <TableBatchAction
                      hasIconOnly
                      iconDescription="Add"
                      tabIndex={0}
                      renderIcon={Add}
                      onClick={console.log}
                    >
                      Delete
                    </TableBatchAction>
                    <TableBatchAction
                      hasIconOnly
                      iconDescription="Save"
                      tabIndex={0}
                      renderIcon={Save}
                      onClick={console.log}
                    >
                      Save
                    </TableBatchAction>
                    <TableBatchAction
                      tabIndex={0}
                      renderIcon={Download}
                      onClick={console.log}
                    >
                      Download
                    </TableBatchAction>
                  </TableBatchActions>
                  <TableToolbarContent>
                    {/* <TableToolbarSearch tabIndex={0} onChange={console.log} /> */}

                    {/* {user.type === "tool_manager" && (
                <Tooltip defaultOpen label="Exportar relatório" align="top">
                  <Button
                    kind="ghost"
                    hasIconOnly
                    renderIcon={Report}
                    id="export"
                  />
                </Tooltip>
              )}
              <Tooltip defaultOpen label="Filtrar" align="top">
                <Button
                  kind="ghost"
                  hasIconOnly
                  renderIcon={Filter}
                  id="filter"
                  onClick={() => setOpenModal(true)}
                />
              </Tooltip> */}
                    {canCreateTasks && (
                      <Button
                        tabIndex={0}
                        onClick={() => {
                          navigate("/app/tasks/new");
                        }}
                        kind="primary"
                      >
                        Nova tarefa
                      </Button>
                    )}
                  </TableToolbarContent>
                </TableToolbar>
                {canCreateTasks && (
                  <Table>
                    <TableHead>
                      <TableRow>
                        {/* <TableSelectAll
                  name={""}
                  id={""}
                  onSelect={console.log}
                  checked={false}
                  ariaLabel={""}
                /> */}
                        {tableHeadersTasksCreatedByMe.map(
                          (columnTitle, index) => {
                            return (
                              <TableHeader key={String(index)}>
                                {columnTitle}
                              </TableHeader>
                            );
                          }
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedTasks.length > 0 ? (
                        paginatedTasks?.map((task, index) => (
                          <TableRow
                            key={String(index)}
                            style={{ cursor: "pointer" }}
                          >
                            {/* <TableSelectRow
  name={""}
  id={""}
  onSelect={console.log}
  checked={false}
/> */}
                            <TableCell>#{task.id}</TableCell>
                            <TableCell>{renderStatus["created"]}</TableCell>
                            <TableCell>{task.name}</TableCell>
                            {/* <TableCell>
  {attend?.status?.slug
    ? renderStatus[attend?.status?.slug]
    : "Não encontrado"}
</TableCell> */}

                            <TableCell>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {task?.owner?.name ?? "Não atríbuida"}
                                {canEditTasks && (
                                  <Button
                                    kind="ghost"
                                    hasIconOnly
                                    onClick={() => {
                                      setTaskDetails(task);
                                      setOpenUpdateOwnerTaskModal(true);
                                    }}
                                    iconDescription={
                                      task?.owner?.name
                                        ? "Trocar responsável"
                                        : "Atribuir responsável"
                                    }
                                    renderIcon={Edit}
                                    id="filter"
                                  />
                                )}
                              </div>
                            </TableCell>

                            <TableCell>
                              {format(task.created_on, "dd-MM-yyyy")}
                            </TableCell>
                            <TableCell>
                              {format(task.updated_on, "dd-MM-yyyy")}
                            </TableCell>
                            <TableCell>
                              {canEditTasks && (
                                <Button
                                  kind="ghost"
                                  hasIconOnly
                                  iconDescription="Editar"
                                  renderIcon={Edit}
                                  onClick={() => {
                                    setTaskDetails(task);
                                    setOpenEditTaskModal(true);
                                  }}
                                  id="filter"
                                />
                              )}
                              {canDeleteTasks && (
                                <Button
                                  kind="ghost"
                                  hasIconOnly
                                  iconDescription="Excluir"
                                  renderIcon={TrashCan}
                                  onClick={() => {
                                    setTaskDetails(task);
                                    setOpenDeleteTaskModal(true);
                                  }}
                                  id="filter"
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableCell
                          colSpan={tableHeadersTasksCreatedByMe.length + 1}
                        >
                          <Padding pt={24} pb={24} pl={12}>
                            <div
                              style={{
                                height: "300px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <h4>Nenhuma tarefa criada até o momento.</h4>
                            </div>
                          </Padding>
                        </TableCell>
                      )}
                    </TableBody>
                  </Table>
                )}
                <Pagination
                  totalItems={tasks.length}
                  pageSize={itemsPerPage}
                  pageSizes={[5, 10, 20, 30, 40, 50]}
                  onChange={({ page, pageSize }) => {
                    setCurrentPage(page);
                    setItemsPerPage(pageSize);
                  }}
                  page={currentPage}
                  size="md"
                />
                <Margin mb={100} />
              </TableContainer>
            </div>
          </TabPane>
          <TabPane tab="Atribuidas a mim" key="2">
            <div>
              <Margin mt={30} />
              <TileContainer>
                {renderTilesAttendTasks.map((tile, index) => {
                  return (
                    <Tile style={{ flex: 1 }} key={String(index)}>
                      <div>
                        <p>{tile.label}</p>
                        <h2>{tile.total}</h2>
                      </div>
                    </Tile>
                  );
                })}
              </TileContainer>
              <Margin mt={20} />
              <TableContainer>
                <TableToolbar>
                  {canReadTasks && canCreateTasks && (
                    <Button
                      tabIndex={0}
                      onClick={() => {
                        fetchTasks();
                      }}
                      kind="secondary"
                      renderIcon={Renew}
                    >
                      Atualizar
                    </Button>
                  )}
                  {!canCreateTasks && (
                    <div
                      style={{
                        height: "400px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <h4 style={{ position: "relative", left: "45%" }}>
                        Você não possui permissão para vizualizar tarefas
                      </h4>
                    </div>
                  )}
                  <TableBatchActions onCancel={console.log} totalSelected={0}>
                    <TableBatchAction
                      tabIndex={0}
                      renderIcon={TrashCan}
                      onClick={console.log}
                    >
                      Delete
                    </TableBatchAction>
                    <TableBatchAction
                      hasIconOnly
                      iconDescription="Add"
                      tabIndex={0}
                      renderIcon={Add}
                      onClick={console.log}
                    >
                      Delete
                    </TableBatchAction>
                    <TableBatchAction
                      hasIconOnly
                      iconDescription="Save"
                      tabIndex={0}
                      renderIcon={Save}
                      onClick={console.log}
                    >
                      Save
                    </TableBatchAction>
                    <TableBatchAction
                      tabIndex={0}
                      renderIcon={Download}
                      onClick={console.log}
                    >
                      Download
                    </TableBatchAction>
                  </TableBatchActions>
                  <TableToolbarContent>
                    {/* <TableToolbarSearch tabIndex={0} onChange={console.log} /> */}

                    {/* {user.type === "tool_manager" && (
                <Tooltip defaultOpen label="Exportar relatório" align="top">
                  <Button
                    kind="ghost"
                    hasIconOnly
                    renderIcon={Report}
                    id="export"
                  />
                </Tooltip>
              )}
              <Tooltip defaultOpen label="Filtrar" align="top">
                <Button
                  kind="ghost"
                  hasIconOnly
                  renderIcon={Filter}
                  id="filter"
                  onClick={() => setOpenModal(true)}
                />
              </Tooltip> */}
                  </TableToolbarContent>
                </TableToolbar>
                {canCreateTasks && (
                  <Table>
                    <TableHead>
                      <TableRow>
                        {/* <TableSelectAll
                  name={""}
                  id={""}
                  onSelect={console.log}
                  checked={false}
                  ariaLabel={""}
                /> */}
                        {tableHeadersTasksForMe.map((columnTitle, index) => {
                          return (
                            <TableHeader key={String(index)}>
                              {columnTitle}
                            </TableHeader>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {attendTasks.length > 0 ? (
                        attendTasks?.map((attendTask: IAttendTask, index) => (
                          <TableRow
                            key={String(index)}
                            style={{ cursor: "pointer" }}
                          >
                            {/* <TableSelectRow
  name={""}
  id={""}
  onSelect={console.log}
  checked={false}
/> */}
                            <TableCell>#{attendTask.id}</TableCell>
                            <TableCell>{renderStatus["created"]}</TableCell>
                            <TableCell>
                              {attendTask?.task?.name ?? "Nome da tarefa"}
                            </TableCell>
                            {/* <TableCell>
  {attend?.status?.slug
    ? renderStatus[attend?.status?.slug]
    : "Não encontrado"}
</TableCell> */}

                            <TableCell>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {"Você"}
                              </div>
                            </TableCell>

                            {/* <TableCell>
                              {format(
                                attendTask.task.created_on,
                                "dd-MM-yyyy"
                              ) ?? "-"}
                            </TableCell>
                            <TableCell>
                              {format(
                                attendTask.task.updated_on ?? ,
                                "dd-MM-yyyy"
                              ) ?? "-"}
                            </TableCell> */}
                            {/* <TableCell>
                              {canEditTasks && (
                                <Button
                                  kind="ghost"
                                  hasIconOnly
                                  iconDescription="Editar"
                                  renderIcon={Edit}
                                  id="filter"
                                />
                              )}
                         
                            </TableCell> */}
                          </TableRow>
                        ))
                      ) : (
                        <TableCell
                          colSpan={tableHeadersTasksCreatedByMe.length + 1}
                        >
                          <Padding pt={24} pb={24} pl={12}>
                            <div
                              style={{
                                height: "300px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <h4>Nenhum Tarefa até o momento.</h4>
                            </div>
                          </Padding>
                        </TableCell>
                      )}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            </div>
          </TabPane>
        </Tabs>
      </Column>
      <FiltersModal onClose={() => setOpenModal(false)} open={openModal} />
      <DeleteTaskModal
        onClose={() => setOpenDeleteTaskModal(false)}
        open={openDeleteTaskModal}
      />
      <EditTaskModal
        onClose={() => setOpenEditTaskModal(false)}
        open={openEditTaskModal}
      />
      <UpdateOwnerTaskModal
        onClose={() => setOpenUpdateOwnerTaskModal(false)}
        open={openUpdateOwnerTaskModal}
      />
    </Grid>
  );
};

export default Tasks;
