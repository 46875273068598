import { Modal, TextInput, Toggle } from "@carbon/react";
import { useFormik } from "formik";
import Margin from "~/components/Margin";
import * as Yup from "yup";
import { useCallback, useEffect, useState } from "react";
import useLoading from "~/hooks/useLoading";

import { toast } from "react-toastify";
import MaskedTextInput from "~/components/InputMask";
import { getFundsAPI, postFundsAPI } from "~/services/api/fund";
import { onlyNumbers } from "~/services/utils";
import useFund from "~/hooks/useFund";

const notifySuccess = () => toast.success(" Área criada com sucesso!");
const notifyError = () =>
  toast.error("A área não pode ser criada. Tente novamente mais tarde.");

type Values = {
  name: string;
  document_number: string;
  short_name: string;
};

const initialValues: Values = {
  name: "",
  short_name: "",
  document_number: "",
};

const validationSchema = Yup.object({
  name: Yup.string().required("Nome da tarefa é obrigatório"),
  sort_name: Yup.string().required("Nome abreviado da tarefa é obrigatório"),
  document_number: Yup.string().required("Nome da tarefa é obrigatório"),
});

const NewFundModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();
  const { setFunds } = useFund();

  const {
    handleSubmit,

    resetForm,

    values,
    handleBlur,
    handleChange,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const fetchAreas = useCallback(async () => {
    try {
      const { data } = await getFundsAPI();

      setFunds(data.results.reverse());
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setFunds]);

  const fetchSendNewArea = async () => {
    setLoading(true);
    try {
      await postFundsAPI({
        name: values.name,
        raw_data: {
          active,
          short_name: values.short_name,
          document_number: onlyNumbers(values.document_number),
          authenticated: false,
        },
      });
      resetForm();
      await fetchAreas();
      setLoading(false);
      notifySuccess();
    } catch (error) {
      console.error("Erro ao enviar Area:", error);
      setLoading(false);
      notifyError();
    }
  };

  const [active, setActive] = useState<boolean>(true);

  const handleToggle = () => {
    setActive(!active);
  };

  useEffect(() => {
    setActive(true);
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Modal
        modalHeading="Novo Fundo"
        size="sm"
        open={open}
        onRequestClose={onClose}
        primaryButtonDisabled={
          values.name === "" ||
          values.short_name === "" ||
          values.document_number === "" ||
          onlyNumbers(values.document_number).length < 14
        }
        primaryButtonText="Concluir"
        secondaryButtonText="Cancelar"
        onRequestSubmit={async () => {
          fetchSendNewArea();
          onClose();
        }}
      >
        <TextInput
          id="name"
          name="name"
          labelText="Nome do fundo"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
        />
        <Margin mb={10} />
        <TextInput
          id="short_name"
          name="short_name"
          labelText="Nome abreviado do fundo"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.short_name}
        />
        <Margin mb={20} />
        <p style={{ fontSize: "12px", color: "black", fontWeight: "300" }}>
          {" "}
          CNPJ
        </p>
        <Margin mb={5} />
        <MaskedTextInput
          mask="99.999.999/9999-99"
          id="document_number"
          onChange={handleChange}
          name="document_number"
          placeholder="Digite o CNPJ do fundo"
          value={values.document_number}
        />
        <Margin mb={13} />
        <Toggle
          id="toggle-1"
          labelText="Status"
          onToggle={handleToggle}
          toggled={active}
          labelA="Inativo"
          labelB="Ativo"
        />

        <Margin mb={24} />
      </Modal>
    </form>
  );
};

export default NewFundModal;
