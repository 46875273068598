import constate from "constate";
import { useState } from "react";
import { ICompanyType } from "~/types";

const [CompanyTypeProvider, useCompanyType] = constate(() => {
  const [companyTypes, setCompanyTypes] = useState<ICompanyType[]>([]);
  const [openNewCompanyTypeModal, setOpenNewCompanyTypeModal] =
    useState<boolean>(false);
  const [openDeleteCompanyTypeModal, setOpenDeleteCompanyTypeModal] =
    useState<boolean>(false);
  const [openEditCompanyTypeModal, setOpenEditCompanyTypeModal] =
    useState<boolean>(false);
  const [companyTypeDetails, setCompanyTypeDetails] = useState<ICompanyType>();

  return {
    companyTypes,
    setCompanyTypes,
    openDeleteCompanyTypeModal,
    setOpenDeleteCompanyTypeModal,
    openNewCompanyTypeModal,
    setOpenNewCompanyTypeModal,
    openEditCompanyTypeModal,
    setOpenEditCompanyTypeModal,
    companyTypeDetails,
    setCompanyTypeDetails,
  };
});

export { CompanyTypeProvider };

export default useCompanyType;
