import { useCallback, useEffect, useState } from "react";
import { InlineNotification, Modal, TextInput } from "@carbon/react";
import useLoading from "~/hooks/useLoading";
import useTask from "~/hooks/useTask";
import { getTasksAPI, patchTaskAPI } from "~/services/api/task";
import * as Yup from "yup";
import { useFormik } from "formik";

type Values = {
  name: string;
};

const validationSchema = Yup.object({
  name: Yup.string().required("Nome da tarefa é obrigatório"),
});

const EditTaskModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { taskDetails, setOpenEditTaskModal, setTasks } = useTask();
  const [error, setError] = useState<boolean>(false);
  const { setLoading } = useLoading();

  const initialValues: Values = {
    name: "",
  };

  const { handleChange, values, handleBlur, setFieldValue } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {},
  });

  const fetchTasks = useCallback(async () => {
    try {
      const { data } = await getTasksAPI();
      setTasks(data.results.reverse().sort((a, b) => b.id - a.id));
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setTasks]);

  const fetchEditTask = useCallback(async () => {
    setLoading(true);
    try {
      if (taskDetails) {
        await patchTaskAPI(taskDetails.id, { name: values.name });
        await fetchTasks();
        setOpenEditTaskModal(false);
        setLoading(false);
        setError(false);
        // setTimeout(() => {
        //   setSuccessDeleteFile(false);
        // }, 5000);
      }
    } catch (e) {
      console.log(e);
      setError(true);
      setLoading(false);
    }
  }, [setLoading, setOpenEditTaskModal, taskDetails, fetchTasks, values.name]);

  useEffect(() => {
    setFieldValue("name", taskDetails?.name);
  }, [taskDetails?.name, setFieldValue]);
  return (
    <Modal
      modalHeading={`Editar tarefa - ${taskDetails?.name}`}
      size="sm"
      open={open}
      primaryButtonDisabled={values.name === ""}
      onRequestClose={() => {
        onClose();
        setError(false);
      }}
      primaryButtonText="Concluir"
      secondaryButtonText="Cancelar"
      onRequestSubmit={() => fetchEditTask()}
      style={{ zIndex: 2 }}
    >
      <div style={{ height: "300px", padding: "20px" }}>
        {error && (
          <InlineNotification
            title="Erro!"
            // subtitle={error}
            subtitle="Não foi possivel editar a tarefa"
            hideCloseButton
            kind="error"
            lowContrast
          />
        )}
        <TextInput
          id="name"
          name="name"
          labelText="Nome da tarefa"
          placeholder="Digite o novo nome da tarefa"
          required
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
    </Modal>
  );
};

export default EditTaskModal;
