import { Modal } from "@carbon/react";

import Margin from "~/components/Margin";
import { useCallback } from "react";
import useLoading from "~/hooks/useLoading";
import useArea from "~/hooks/useArea";
import { deleteAreaTypeAPI, getAreaTypesAPI } from "~/services/api/areaTypes";
import useAreaType from "~/hooks/useAreaType";
import { toast } from "react-toastify";

const DeleteAreaTypeModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();
  const { areaTypeDetails } = useArea();
  const { setAreaTypes } = useAreaType();

  const notifySuccess = () =>
    toast.success("Tipo de atendimento removido com sucesso!");
  const notifyError = () =>
    toast.error(
      "Não foi possível remover o tipo de atendimento. Tente novamente mais tarde."
    );

  const fetchAreaTypes = useCallback(async () => {
    try {
      const { data } = await getAreaTypesAPI();

      setAreaTypes(data.results.reverse());
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, [setLoading, setAreaTypes]);

  const fetchDeleteAreaType = useCallback(async (id?: number) => {
    setLoading(true);
    try {
      await deleteAreaTypeAPI(String(id));
      await fetchAreaTypes();
      setLoading(false);
      notifySuccess();
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
      notifyError();
    }
  }, []);

  return (
    <Modal
      modalHeading="Remover tipo de atendimento"
      size="sm"
      open={open}
      onRequestClose={onClose}
      primaryButtonText="Sim, remover"
      secondaryButtonText="Cancelar"
      onRequestSubmit={async () => {
        fetchDeleteAreaType(areaTypeDetails?.id);
        onClose();
      }}
    >
      <h3 style={{ textAlign: "center" }}>
        Deseja realmente remover o tipo de atendimento{" "}
        {areaTypeDetails?.type?.name} da área {areaTypeDetails?.area.name} ?
      </h3>
      <Margin mb={100} />
    </Modal>
  );
};

export default DeleteAreaTypeModal;
