import constate from "constate";
import { useState } from "react";
import { IAreaType } from "~/types";

const [AreaTypeProvider, useAreaType] = constate(() => {
  const [areaTypes, setAreaTypes] = useState<IAreaType[]>([]);

  return {
    areaTypes,
    setAreaTypes,
  };
});

export { AreaTypeProvider };

export default useAreaType;
