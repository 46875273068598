import { InlineNotification, Modal } from "@carbon/react";
import { useFormik } from "formik";
import Margin from "~/components/Margin";

import * as Yup from "yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import useLoading from "~/hooks/useLoading";
import Select from "react-select";

import useAttendType from "~/hooks/useType";
import useAttend from "~/hooks/useAttend";

import { IAreaType } from "~/types";
import { useParams } from "react-router-dom";
import {
  getAttendDetailsAPI,
  patchAttendDetailsAPI,
} from "~/services/api/attend";
import useArea from "~/hooks/useArea";
import { getAreasAPI } from "~/services/api/areas";
import Label from "~/components/Label";
import useAreaType from "~/hooks/useAreaType";
import { getAreaTypesAPI } from "~/services/api/areaTypes";

type Values = {
  area: string;
  area_type: string;
};

const initialValues: Values = {
  area: "",
  area_type: "",
};

const validationSchema = Yup.object({
  area: Yup.string(),
  area_type: Yup.string(),
});

const UpdateAttendTypeModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();
  const {
    attendDetails,

    setAttendDetails,
    setOpenUpdatedAreaTypeModal,
  } = useAttend();
  const { setAreas } = useArea();
  const { setAreaTypes, areaTypes } = useAreaType();
  const { setAttendTypes } = useAttendType();
  const { id } = useParams();
  const [error, setError] = useState<boolean>(false);

  const fetchAreas = async () => {
    try {
      const { data } = await getAreasAPI();
      setAreas(data.results);
    } catch (e: unknown) {
      console.log(e);
    }
  };

  const { handleSubmit, setFieldValue, values } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const actualAreaId = useMemo(
    () =>
      areaTypes.filter(
        (areaType: IAreaType) => areaType.type.id === attendDetails?.type.id
      )[0]?.area?.id,
    [areaTypes, attendDetails]
  );

  const areaTypesOptions = useMemo(() => {
    return areaTypes
      ?.filter((areaType) => areaType.area.id === actualAreaId)
      .map((areaType) => ({
        value: String(areaType.type.id),
        label: `${areaType.type.name}`,
      }));
  }, [areaTypes, actualAreaId]);

  type SelectOption = {
    value: string;
    label: string;
  };

  const handleSelectAreaType = (
    selectedOption: SelectOption | null,
    actionMeta: { action: string }
  ) => {
    if (selectedOption) {
      setFieldValue("area_type", selectedOption.value);
    } else if (actionMeta.action === "clear") {
      setFieldValue("area_type", "");
    }
  };

  const fetchAreaTypes = useCallback(async () => {
    try {
      const { data } = await getAreaTypesAPI();
      setAreaTypes(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setAreaTypes]);

  const fetchAttends = useCallback(async () => {
    if (id) {
      try {
        const { data } = await getAttendDetailsAPI(Number(id));

        setAttendDetails(data);

        setLoading(false);
      } catch (err: unknown) {
        console.error(err);
        setLoading(false);
      }
    }
  }, [id]);

  const fetchSendNewStatus = useCallback(async () => {
    setLoading(true);
    try {
      await patchAttendDetailsAPI(Number(id), {
        type: Number(values.area_type),
      });
      fetchAttends();
      setOpenUpdatedAreaTypeModal(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, [
    setAttendTypes,
    values.area_type,
    id,
    fetchAttends,
    setOpenUpdatedAreaTypeModal,
    patchAttendDetailsAPI,
  ]);

  useEffect(() => {
    fetchAreas();
    fetchAreaTypes();
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Modal
        style={{ zIndex: 3 }}
        modalHeading={`Trocar tipo de atendimento - #${attendDetails?.id} ${attendDetails?.type?.name}`}
        size="sm"
        open={open}
        onRequestClose={() => {
          onClose();
          setError(false);
        }}
        primaryButtonText="Concluir"
        secondaryButtonText="Cancelar"
        primaryButtonDisabled={
          values.area_type === "" || values.area_type === undefined
        }
        onRequestSubmit={async () => {
          fetchSendNewStatus();
        }}
      >
        {error && (
          <InlineNotification
            title="Erro!"
            // subtitle={error}
            subtitle="Não foi possivel trocar a àrea de atendimento."
            hideCloseButton
            kind="error"
            lowContrast
          />
        )}
        <Margin mt={20} />

        <Label text="Selecione uma tipo de atendimento" />
        <Select
          isDisabled={actualAreaId === undefined}
          isClearable={true}
          options={areaTypesOptions}
          onChange={(selectedOption, actionMeta) =>
            handleSelectAreaType(selectedOption, actionMeta)
          }
          placeholder="Pesquisar ou selecionar Tipo de atendimento..."
        />
        <Margin mb={204} />
      </Modal>
    </form>
  );
};

export default UpdateAttendTypeModal;
