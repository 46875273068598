import { ICompanyType } from "~/types";
import ApiService from "../api";

type IResponseGetCompanyTypes = {
  count: number;
  next: null;
  previous: null;
  results: ICompanyType[];
};

export type ISendNewCompanyTypeBody = {
  name: string;
};

export type IUpdateNewCompanyTypeBody = {
  name: string;
};

export const postCompanyTypeAPI = (data: ISendNewCompanyTypeBody) => {
  return ApiService.HttpPost({
    route: "company-type/",
    body: data,
    token: true,
  });
};

export const getCompanyTypesAPI = () => {
  return ApiService.HttpGet<IResponseGetCompanyTypes>({
    route: "company-type/",
    token: true,
  });
};

export const updateCompanyTypeAPI = (
  body: IUpdateNewCompanyTypeBody,
  id: string
) => {
  return ApiService.HttpPatch<IResponseGetCompanyTypes>({
    route: `company-type/${id}/`,
    token: true,
    body: body,
  });
};

export const deleteCompanyTypeAPI = (id: string) => {
  return ApiService.HttpDelete({
    route: `company-type/${id}/`,
    token: true,
  });
};
