import { Button, Column } from "@carbon/react";
import Badge from "~/components/Badge";
import Margin from "~/components/Margin";

import useAttend from "~/hooks/useAttend";

import { calculateSla, formatCPFCNPJ } from "~/services/utils";

import { Edit } from "@carbon/icons-react";
import useUser from "~/hooks/useUser";
import { useMemo } from "react";

const renderStatus: { [key: string]: React.ReactNode } = {
  opened: <Badge label="Em aberto" color="#e3e3e3" textColor="#696868" />,
  created: <Badge label="Criado" color="#e3e3e3" textColor="#696868" />,
  in_progress: (
    <Badge color="#ffe9c9" textColor="#ff9900" label="Em andamento" />
  ),
  done: <Badge color="#CCEFDF" textColor="#00B360" label="Finalizado" />,
  return_to_client: (
    <Badge color="#bacbff" textColor="#1906ec" label="Retor. solicitante" />
  ),
  canceled: <Badge label="Cancelado" textColor="#ff0000" color="#f9c6c6" />,
};

const renderSla: { [key: string]: React.ReactNode } = {
  noPrazo: <Badge color="#CCEFDF" textColor="#00B360" label="No prazo" />,
  return_to_client: (
    <Badge color="#bacbff" textColor="#1906ec" label="Aguard. solicitante" />
  ),
  done: <Badge color="#bacbff" textColor="#1906ec" label="Atend. Finalizado" />,
  canceled: (
    <Badge color="#bacbff" textColor="#1906ec" label="Atend. Cancelado" />
  ),
  atrasado: <Badge label="Atrasado" textColor="#ff0000" color="#f9c6c6" />,
};

const LateralDetailsAttends = () => {
  const {
    attendDetails,
    setOpenUpdatedAttendStatusModal,
    // setOpenUpdatedAttendAreaModal,
    subAttendDetails,
    setOpenUpdatedOwnerAttendModal,
    // setOpenUpdatedAreaTypeModal,
    attendLog,
    attendParentData,
  } = useAttend();

  const { userProfile } = useUser();

  const isSolicitant = useMemo(() => {
    return (
      userProfile?.type === "client" ||
      attendDetails?.client.id === userProfile?.id
    );
  }, [attendDetails, userProfile]);

  return (
    <Column span={4} sm={{}}>
      <div
        style={{
          width: "100%",
          flex: 1,
          height: "80vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          background: "#f7f7f7",
          position: "relative",
          top: 20,
        }}
      >
        <Margin mb={20} />

        <div
          style={{
            marginLeft: "50px",
            width: "100%",
          }}
        >
          {subAttendDetails !== undefined && (
            <div>
              <h6 style={{ fontWeight: "bold" }}>Vinculado ao</h6>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div style={{ width: "100%" }}>
                  <a
                    href={`/app/attends/${attendParentData?.id}`}
                    style={{ cursor: "pointer" }}
                  >
                    #{attendParentData?.id} -{" "}
                    {attendParentData?.type.name ?? "-"}
                  </a>
                </div>
              </div>
              <Margin mb={20} />
            </div>
          )}
          <div>
            <h6 style={{ fontWeight: "bold" }}>Status</h6>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "85%",
              }}
            >
              <div style={{ width: "100%" }}>
                <Margin mb={isSolicitant ? 10 : -10} />
                {renderStatus[attendDetails?.status?.slug ?? ""]}
              </div>
              {!isSolicitant && (
                <Button
                  kind="ghost"
                  hasIconOnly
                  iconDescription="Editar"
                  renderIcon={Edit}
                  onClick={() => setOpenUpdatedAttendStatusModal(true)}
                  id="filter"
                />
              )}
            </div>
          </div>
          <Margin mb={10} />
          {isSolicitant && <Margin mb={20} />}
          <div>
            <h6 style={{ fontWeight: "bold" }}>Solicitante</h6>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div style={{ width: "100%" }}>
                <h4 style={{}}>
                  {attendDetails?.client?.raw_data?.company_name ??
                    attendDetails?.client?.name}
                </h4>
                {attendDetails?.client?.raw_data?.company_document_number && (
                  <h5 style={{}}>
                    {formatCPFCNPJ(
                      attendDetails?.client?.raw_data?.company_document_number
                    )}
                  </h5>
                )}
              </div>
            </div>
          </div>
          <Margin mb={20} />
          <div>
            <h6 style={{ fontWeight: "bold" }}>Fundo</h6>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div style={{ width: "100%" }}>
                <h4> {attendDetails?.fund?.raw_data?.short_name} </h4>
              </div>
            </div>
          </div>
          <Margin mb={20} />
          <div>
            <h6 style={{ fontWeight: "bold" }}>Despesa</h6>
            <Margin mb={10} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "85%",
              }}
            >
              <div style={{ width: "100%" }}>
                <Margin mb={isSolicitant ? 5 : -5} />
                <h4> {"Conta de luz"}</h4>
              </div>
              {/* {!isSolicitant && (
                <Button
                  kind="ghost"
                  hasIconOnly
                  iconDescription="Trocar tipo de atendimento"
                  renderIcon={Edit}
                  onClick={() => setOpenUpdatedAreaTypeModal(true)}
                  id="filter"
                />
              )} */}
            </div>
          </div>
          <Margin mb={20} />

          <div>
            <h6 style={{ fontWeight: "bold" }}>Responsável</h6>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "85%",
              }}
            >
              <div style={{ width: "100%" }}>
                <Margin mb={isSolicitant ? 5 : -5} />
                <h4> {attendDetails?.owner?.name ?? "-"}</h4>
              </div>
              {!isSolicitant && (
                <Button
                  kind="ghost"
                  hasIconOnly
                  iconDescription="Atribuir responsável"
                  renderIcon={Edit}
                  onClick={() => setOpenUpdatedOwnerAttendModal(true)}
                  id="filter"
                />
              )}
            </div>
          </div>
          <Margin mb={20} />
          <div>
            <h6 style={{ fontWeight: "bold" }}>Área</h6>

            <Margin mb={isSolicitant ? 5 : -5} />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "85%",
              }}
            >
              <div style={{ width: "100%" }}>
                <h4>{attendLog?.actual_area?.name ?? "-"}</h4>
              </div>
              {/* {!isSolicitant && (
                <Button
                  kind="ghost"
                  hasIconOnly
                  iconDescription="Trocar área"
                  renderIcon={Edit}
                  id="filter"
                  onClick={() => setOpenUpdatedAttendAreaModal(true)}
                />
              )} */}
            </div>
          </div>
          <Margin mb={20} />
          <div>
            <h6 style={{ fontWeight: "bold" }}>SLA</h6>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div style={{ width: "100%", marginTop: "8px" }}>
                {attendDetails?.status?.slug === "done" ? (
                  renderSla["done"]
                ) : attendDetails?.status?.slug === "canceled" ? (
                  renderSla["canceled"]
                ) : attendDetails?.status?.slug === "return_to_client" ? (
                  renderSla["return_to_client"]
                ) : (
                  <div>
                    {" "}
                    {
                      renderSla[
                        calculateSla(
                          attendDetails?.type?.sla ?? 0,
                          attendDetails?.updated_on ?? ""
                        ).status
                      ]
                    }
                    <h4>
                      {
                        calculateSla(
                          attendDetails?.type?.sla ?? 0,
                          attendDetails?.updated_on ?? ""
                        ).formattedDuration
                      }
                    </h4>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Margin mb={20} />
        </div>
      </div>
    </Column>
  );
};
export default LateralDetailsAttends;
