import {
  Button,
  Column,
  Grid,
  SideNav,
  SideNavItems,
  SideNavLink,
  TextInput,
} from "@carbon/react";

import { useFormik } from "formik";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Margin from "~/components/Margin";
import Padding from "~/components/Padding";
import { permissions } from "~/services/permissions";
// import { postAttendLogAPI } from "~/services/api/attendLog";
import useUser from "~/hooks/useUser";
import useLoading from "~/hooks/useLoading";
import MaskedTextInput from "~/components/InputMask";
import ChangePassword from "./ChangePassword";
import { getProfilesAPI } from "~/services/api/profile";

const TicketFormContainer = styled.div`
  position: relative;

  .form-sidenav {
    position: relative;

    background: #f4f4f4;
  }

  .form {
    background: #f4f4f4;
    min-height: 50vh;

    .action-button {
      width: 100%;
    }
  }
`;

type Values = {
  full_name: string;
  email: string;
  document_number: string;
};

const initialValues: Values = {
  full_name: "",
  email: "",
  document_number: "",
};

const MyData = () => {
  const navigate = useNavigate();

  const canCreateAttends = permissions.includes("can_create_attends");

  const {
    userProfile,
    setOpenChangePasswordModal,
    openChangePasswordModal,
    setUserProfile,
  } = useUser();

  const { setLoading } = useLoading();

  const fetchUserProfile = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getProfilesAPI();
      setUserProfile(data.results[0]);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const { values, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues,

    onSubmit: (values) => {
      console.log(values);

      //   fetchSendNewAttend(sendNewAttendPayload);
    },
  });

  useEffect(() => {
    setFieldValue("full_name", userProfile?.name);
    setFieldValue("document_number", userProfile?.document_number);
    setFieldValue("email", userProfile?.user?.email);
  }, [
    setFieldValue,
    userProfile?.name,
    userProfile?.user?.email,
    userProfile?.document_number,
  ]);

  return (
    <TicketFormContainer>
      {canCreateAttends ? (
        <form onSubmit={handleSubmit}>
          <Grid>
            <Column span={16}>
              <Margin mb={64} />
              <h1>Meus Perfil</h1>
              <Margin mb={22} />
              <Grid className="form">
                <Column span={4}>
                  <SideNav
                    style={{ zIndex: 0 }}
                    aria-label="Side navigation"
                    className="form-sidenav"
                  >
                    <SideNavItems>
                      <SideNavLink href="#" isActive>
                        Dados Gerais
                      </SideNavLink>
                    </SideNavItems>
                  </SideNav>
                </Column>

                <Column span={12}>
                  <Grid>
                    <Column span={12}>
                      <Padding y={12}></Padding>
                    </Column>
                    <Column span={6}>
                      <h2>Dados gerais</h2>
                      <Margin mb={50} />{" "}
                      <TextInput
                        id="name"
                        name="name"
                        labelText="Nome completo"
                        value={values.full_name}
                        readOnly
                        helperText="No momento não é possivel editar este dado"
                      />
                      <Margin mb={15} />
                      <p
                        style={{
                          fontSize: "12px",
                          color: "black",
                          fontWeight: "300",
                        }}
                      >
                        {" "}
                        CPF
                      </p>
                      <Margin mb={5} />
                      <MaskedTextInput
                        mask="999.999.999-99"
                        id="document_number"
                        name="document_number"
                        placeholder="00.000.000/0000-00"
                        value={values.document_number}
                      />
                      <p
                        style={{
                          fontSize: "12px",
                          color: "black",
                          fontWeight: "300",
                        }}
                      >
                        {" "}
                        No momento não é possivel editar este dado{" "}
                      </p>
                      <Margin mb={15} />
                      <TextInput
                        id="email"
                        name="email"
                        labelText="Email"
                        value={values.email}
                        readOnly
                        onChange={handleChange}
                        helperText="No momento não é possivel editar este dado"
                      />
                      <Margin mb={25} />
                      <Button
                        kind="ghost"
                        onClick={() => setOpenChangePasswordModal(true)}
                      >
                        Alterar senha
                      </Button>
                    </Column>

                    <Column span={12}>
                      <Margin mb={64} />
                      <Grid>
                        <Column span={3}>
                          <Button
                            kind="tertiary"
                            className="action-button"
                            onClick={() => navigate("/app/attends/")}
                          >
                            Sair
                          </Button>
                        </Column>
                        <Column span={3}>
                          <Button
                            kind="primary"
                            className="action-button"
                            type="submit"
                            onClick={() => navigate("/app/attends/")}
                          >
                            Salvar dados
                          </Button>
                        </Column>
                      </Grid>
                    </Column>

                    <Column span={6} />
                  </Grid>

                  <Margin mb={64} />
                </Column>
              </Grid>

              <Margin mb={64} />
            </Column>
          </Grid>
        </form>
      ) : (
        <div
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {" "}
          <h4> Você não possui permissão para criar atendimentos</h4>
        </div>
      )}
      <ChangePassword
        onClose={() => setOpenChangePasswordModal(false)}
        open={openChangePasswordModal}
      />
    </TicketFormContainer>
  );
};

export default MyData;
