import constate from "constate";
import { useState } from "react";
import { IAttendType } from "~/types";

const [AttendTypeProvider, useAttendType] = constate(() => {
  const [attendTypes, setAttendTypes] = useState<IAttendType[]>([]);

  const [openNewAttendTypeModal, setOpenNewAttendTypeModal] =
    useState<boolean>(false);
  const [openEditAttendTypeModal, setOpenEditAttendTypeModal] =
    useState<boolean>(false);

  const [openDeleteAttendTypeModal, setOpenDeleteAttendTypeModal] =
    useState<boolean>(false);

  const [attendTypesDetails, setAttendTypesDetails] = useState<IAttendType>();

  return {
    attendTypes,
    setAttendTypes,
    openNewAttendTypeModal,
    setOpenNewAttendTypeModal,
    openDeleteAttendTypeModal,
    setOpenDeleteAttendTypeModal,
    attendTypesDetails,
    setAttendTypesDetails,
    openEditAttendTypeModal,
    setOpenEditAttendTypeModal,
  };
});

export { AttendTypeProvider };

export default useAttendType;
