import { IAttendTask, ITask } from "~/types";
import ApiService from "../api";

type IResponseTask = {
  count: number;
  next: null;
  previous: null;
  results: ITask[];
};

const expand = ["status,owner"];

export const getTasksAPI = () => {
  return ApiService.HttpGet<IResponseTask>({
    route: "workflow/task/",
    token: true,
    params: { expand },
  });
};
export const getTaskAPI = (id: number) => {
  return ApiService.HttpGet<IAttendTask>({
    route: `workflow/task/${id}/`,
    token: true,
    // params: { expand },
  });
};

export type ISendNewTaskBody = {
  attend?: number;
  name?: string;
  owner?: number | null;
  status?: number | null;
};

export const postTaskAPI = (data: ISendNewTaskBody) => {
  return ApiService.HttpPost<IAttendTask>({
    route: "workflow/task/",
    body: data,
    token: true,
  });
};

export const deleteTaskAPI = (id: number) => {
  return ApiService.HttpDelete({
    route: `workflow/task/${id}/`,
    token: true,
  });
};

export const patchTaskAPI = (id: number, body: ISendNewTaskBody) => {
  return ApiService.HttpPatch({
    route: `workflow/task/${id}/`,
    token: true,
    body: body,
  });
};
