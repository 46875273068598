import { Modal, Toggle } from "@carbon/react";
import Margin from "~/components/Margin";
import { useCallback, useEffect, useMemo } from "react";
import useLoading from "~/hooks/useLoading";
import useBeneficiary from "~/hooks/useBeneficiary";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import {
  getBeneficiariesAPI,
  IBodyPatchBeneficiary,
  patchBeneficiaryAPI,
} from "~/services/api/beneficiaries";
import * as Yup from "yup";
import { onlyNumbers } from "~/services/utils";

const ChangeStatusBeneficiaryModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();

  const {
    setBeneficiaries,
    setEditStatusBeneficiaryModal,
    beneficiaryDetails,
    setEditModalBeneficiary,
  } = useBeneficiary();

  const notifySuccess = () =>
    toast.success("Status do beneficiário alterado com sucesso!");
  const notifyError = () =>
    toast.error(
      "não foi possivel alterar o status do beneficiário. Tente novamente mais tarde."
    );

  const validationSchema = Yup.object({
    status: Yup.string().required("O Status é obrigatório"),
  });

  const InitialValues = {
    active: false,
  };

  const { resetForm, setFieldValue, values } = useFormik({
    initialValues: InitialValues,
    validationSchema,
    onSubmit: () => console.log(""),
  });

  const handleToggle = () => {
    setFieldValue("active", !values.active);
  };

  const fetchBeneficiaries = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getBeneficiariesAPI();
      console.log(data);
      setBeneficiaries(data.results.reverse());
      setLoading(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, [setLoading, setBeneficiaries]);

  const payload: IBodyPatchBeneficiary = useMemo(() => {
    const status = values.active === true ? "approved" : "inactive";

    return {
      raw_data: {
        status,
        pending_funds_approvations:
          beneficiaryDetails?.raw_data?.pending_funds_approvations,
      },
    };
  }, [values]);

  const fetchPatchBeneficiary = useCallback(
    async (id: number) => {
      setLoading(true);
      try {
        await patchBeneficiaryAPI(id, payload);

        setEditModalBeneficiary(false);
        await fetchBeneficiaries();
        setEditStatusBeneficiaryModal(false);
        setLoading(false);
        notifySuccess();
        resetForm();
      } catch (err: unknown) {
        console.error(err);
        setLoading(false);
        notifyError();
      }
    },
    [
      payload,
      fetchBeneficiaries,
      resetForm,
      setEditModalBeneficiary,
      setEditStatusBeneficiaryModal,
      setLoading,
    ]
  );

  useEffect(() => {
    if (beneficiaryDetails?.raw_data?.status === "approved") {
      setFieldValue("active", true);
    } else {
      setFieldValue("active", false);
    }
  }, [beneficiaryDetails, setFieldValue]);

  return (
    <Modal
      style={{ zIndex: 6 }}
      modalHeading="Alterar Status Beneficiário"
      size="sm"
      open={open}
      onRequestClose={() => {
        onClose();
        resetForm();
      }}
      primaryButtonText="Concluir"
      secondaryButtonText="Cancelar"
      onRequestSubmit={async () => {
        fetchPatchBeneficiary(Number(beneficiaryDetails?.id));
      }}
    >
      <Margin mb={10} />
      <h3> Você está alterando o status do beneficiário abaixo: </h3>
      <Margin mb={10} />
      <h4 style={{ fontWeight: "bold" }}>
        {beneficiaryDetails?.document_number &&
        onlyNumbers(beneficiaryDetails?.document_number).length > 14
          ? "Razão Social: "
          : "Nome: "}
        <span style={{ fontWeight: "normal" }}>{beneficiaryDetails?.name}</span>
      </h4>
      <h4 style={{ fontWeight: "bold" }}>
        {beneficiaryDetails?.document_number &&
        onlyNumbers(beneficiaryDetails?.document_number).length > 14
          ? "CPNJ: "
          : "CPF: "}
        <span style={{ fontWeight: "normal" }}>
          {" "}
          {beneficiaryDetails?.document_number || "Não cadastrado"}
        </span>
      </h4>
      <Margin mt={20} />
      <Margin mb={13} />
      <Toggle
        id="toggle-2"
        labelText="Status"
        onToggle={handleToggle}
        toggled={values.active}
        labelA="Inativo"
        labelB="Ativo"
      />

      <Margin mb={24} />
      <Margin mb={150} />
    </Modal>
  );
};

export default ChangeStatusBeneficiaryModal;
