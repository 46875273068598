import constate from "constate";
import { useState } from "react";
import { IArea, IAreaType, IAttendAreaProfile } from "~/types";

const [AreaProvider, useArea] = constate(() => {
  const [areas, setAreas] = useState<IArea[]>([]);
  const [openDeleteAreaModal, setOpenDeleteAreaModal] =
    useState<boolean>(false);
  const [openAssociateAreaTypeModal, setOpenAssociateAreaTypeModal] =
    useState<boolean>(false);
  const [
    openAssociateAttendAreaProfileModal,
    setOpenAssociateAttendAreaProfileModal,
  ] = useState<boolean>(false);

  const [openDeleteAreaTypeModal, setOpenDeleteAreaTypeModal] =
    useState<boolean>(false);

  const [openEditAreaModal, setOpenEditAreaModal] = useState<boolean>(false);

  const [
    openDeleteAttendAreaProfileModal,
    setOpenDeleteAttendAreaProfileModal,
  ] = useState<boolean>(false);

  const [areaDetails, setAreaDetails] = useState<IArea>();

  const [areaTypeDetails, setAreaTypeDetails] = useState<IAreaType>();

  const [attendAreaProfiles, setAttendAreaProfiles] = useState<
    IAttendAreaProfile[]
  >([]);

  const [attendAreaProfileDetails, setAttendAreaProfileDetails] =
    useState<IAttendAreaProfile>();

  return {
    areas,
    setAreas,
    setOpenDeleteAreaModal,
    openDeleteAreaModal,
    areaDetails,
    setAreaDetails,
    openDeleteAreaTypeModal,
    setOpenDeleteAreaTypeModal,
    areaTypeDetails,
    setAreaTypeDetails,
    openAssociateAreaTypeModal,
    setOpenAssociateAreaTypeModal,
    attendAreaProfiles,
    setAttendAreaProfiles,
    openAssociateAttendAreaProfileModal,
    setOpenAssociateAttendAreaProfileModal,
    attendAreaProfileDetails,
    setAttendAreaProfileDetails,
    openDeleteAttendAreaProfileModal,
    setOpenDeleteAttendAreaProfileModal,
    openEditAreaModal,
    setOpenEditAreaModal,
  };
});

export { AreaProvider };

export default useArea;
