import { useCallback } from "react";
import { Modal } from "@carbon/react";

import {
  deleteAttendTaskFileAPI,
  getAttendTaskFileAPI,
} from "~/services/api/attendTaskFile";
import useAttend from "~/hooks/useAttend";
import useLoading from "~/hooks/useLoading";
import { IFile } from "~/types";
import { toast } from "react-toastify";

const AttendFilesDeleteFileModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const {
    setOpenDeleteFileModal,
    attendFileDetails,
    attendDetails,
    setAttendFiles,
  } = useAttend();
  const { setLoading } = useLoading();

  const notifySuccess = () => toast.success("Documento excluido com sucesso!");
  const notifyError = () =>
    toast.error(
      "Ocorreu um erro ao excluir o documento. Tente novamente mais tarde"
    );

  const fetchAttendTaskFiles = useCallback(async () => {
    try {
      const { data } = await getAttendTaskFileAPI();

      const filteredData = data.results.filter(
        (file: IFile) => file.attend === attendDetails?.id
      );

      setAttendFiles(filteredData);
    } catch (e) {
      console.log(e);
    }
  }, [attendDetails?.id, setAttendFiles]);

  const fetchDeleteFile = useCallback(async () => {
    setLoading(true);
    try {
      if (attendFileDetails) {
        await deleteAttendTaskFileAPI(attendFileDetails.id);
        await fetchAttendTaskFiles();
        notifySuccess();
        setOpenDeleteFileModal(false);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      notifyError();
    }
  }, [
    attendFileDetails,
    setLoading,
    fetchAttendTaskFiles,
    setOpenDeleteFileModal,
  ]);

  return (
    <Modal
      modalHeading="Excluir documento"
      size="sm"
      open={open}
      onRequestClose={onClose}
      primaryButtonText="Sim, excluir"
      secondaryButtonText="Cancelar"
      onRequestSubmit={() => fetchDeleteFile()}
      style={{ zIndex: 2 }}
    >
      <div style={{ height: "300px", padding: "20px" }}>
        <h3 style={{ textAlign: "justify" }}>
          Deseja realmente excluir o documento {attendFileDetails?.description}{" "}
          ?
        </h3>
      </div>
    </Modal>
  );
};

export default AttendFilesDeleteFileModal;
