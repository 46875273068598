import { Modal, RadioButton, RadioButtonGroup, TextInput } from "@carbon/react";
import { useFormik } from "formik";
import Margin from "~/components/Margin";

import * as Yup from "yup";
import { useCallback, useMemo, useState } from "react";
import useLoading from "~/hooks/useLoading";

import {
  getAttendTypeAPI,
  ISendNewAttendTypeBody,
  postAttendTypeAPI,
} from "~/services/api/type";
import useAttendType from "~/hooks/useType";
import { toast } from "react-toastify";

type Values = {
  name: string;
  sla: number;
  sla_type: string;
};

const initialValues: Values = {
  name: "",
  sla: 1,
  sla_type: "",
};

const validationSchema = Yup.object({
  name: Yup.string().required("Nome da tarefa é obrigatório"),
  sla: Yup.number().required("Nome da tarefa é obrigatório"),
});

const NewAttendTypeContentModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();
  const { setOpenNewAttendTypeModal, setAttendTypes } = useAttendType();

  const [selectedRadio, setSelectedRadio] = useState<string>("day");

  const handleChangeRadio = (value: unknown) => {
    setSelectedRadio(String(value));
  };

  const notifySuccess = () =>
    toast.success("Tipo de atendimento criado com sucesso!");
  const notifyError = () =>
    toast.error(
      "Não foi possível criar o tipo de atendimento. Tente novamente mais tarde."
    );

  const { handleSubmit, resetForm, values, handleBlur, handleChange } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: (values) => {
        console.log(values);
      },
    });

  const sendNewAttendType: ISendNewAttendTypeBody = useMemo(() => {
    return {
      name: values.name,
      sla: values.sla,
      sla_type: String(selectedRadio),
    };
  }, [values, selectedRadio]);

  const fetchAttendTypes = useCallback(async () => {
    try {
      const { data } = await getAttendTypeAPI();
      setAttendTypes(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setAttendTypes]);

  const fetchSendNewAttendType = async (payload: ISendNewAttendTypeBody) => {
    setLoading(true);
    try {
      await postAttendTypeAPI(payload);

      resetForm();

      await fetchAttendTypes();

      setLoading(false);
      notifySuccess();
      setOpenNewAttendTypeModal(false);
    } catch (error) {
      console.error("Erro ao enviar Area:", error);
      setLoading(false);
      notifyError();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Modal
        style={{  zIndex: 6 }}
        modalHeading="Novo tipo de atendimento"
        size="sm"
        open={open}
        primaryButtonDisabled={
          values.name === "" ||
          Number(values.sla) < 0 ||
          String(values.sla) === ""
        }
        onRequestClose={() => {
          onClose();
        }}
        primaryButtonText="Concluir"
        secondaryButtonText="Cancelar"
        onRequestSubmit={async () => {
          fetchSendNewAttendType(sendNewAttendType);
        }}
      >
        <Margin mt={20} />
        <TextInput
          id="name"
          name="name"
          labelText="Nome"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
        />
        <Margin mb={20} />

        <RadioButtonGroup
          legendText="Tipo do SLA"
          name="radio-group"
          valueSelected={selectedRadio}
          onChange={handleChangeRadio}
        >
          <RadioButton labelText="Dia(s)" value="day" id="radio-1" />
          <RadioButton labelText="Hora(s)" value="hour" id="radio-2" />
          <RadioButton labelText="Minuto(s)" value="minute" id="radio-3" />
        </RadioButtonGroup>

        <Margin mb={10} />
        <TextInput
          type="number"
          id="sla"
          name="sla"
          labelText={`SLA (${selectedRadio === "day" ? "Dias" : selectedRadio === "hour" ? "Horas" : "Minutos"})`}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.sla}
        />
        <Margin mb={24} />
      </Modal>
    </form>
  );
};

export default NewAttendTypeContentModal;
