import { Modal, TextInput } from "@carbon/react";
import { useFormik } from "formik";
import Margin from "~/components/Margin";

import * as Yup from "yup";
import { useCallback, useMemo } from "react";
import useLoading from "~/hooks/useLoading";

import { ISendNewAttendTypeBody } from "~/services/api/type";

import { toast } from "react-toastify";
import usePaymentType from "~/hooks/useTypesPayment";
import {
  getPaymentTypeAPI,
  postPaymentTypeAPI,
} from "~/services/api/paymentTypes";

type Values = {
  name: string;
};

const initialValues: Values = {
  name: "",
};

const validationSchema = Yup.object({
  name: Yup.string().required("Nome da tarefa é obrigatório"),
  sla: Yup.number().required("Nome da tarefa é obrigatório"),
});

const NewPaymentTypeModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();

  const { setOpenNewPaymentTypeModal, setPaymentTypes } = usePaymentType();

  const notifySuccess = () =>
    toast.success("Tipo de despesa criado com sucesso!");
  const notifyError = () =>
    toast.error(
      "Não foi possível criar o tipo despesa. Tente novamente mais tarde."
    );

  const { handleSubmit, resetForm, values, handleBlur, handleChange } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: (values) => {
        console.log(values);
      },
    });

  const sendNewPaymentType: ISendNewAttendTypeBody = useMemo(() => {
    return {
      name: values.name,
    };
  }, [values]);

  const fetchPaymentTypes = useCallback(async () => {
    try {
      const { data } = await getPaymentTypeAPI();
      setPaymentTypes(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setPaymentTypes]);

  const fetchSendNewPaymentType = async (payload: ISendNewAttendTypeBody) => {
    setLoading(true);
    try {
      await postPaymentTypeAPI(payload);

      resetForm();

      await fetchPaymentTypes();

      setLoading(false);
      notifySuccess();
      setOpenNewPaymentTypeModal(false);
    } catch (error) {
      console.error("Erro ao enviar Tipo de despesa", error);
      setLoading(false);
      notifyError();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Modal
        style={{ zIndex: 6 }}
        modalHeading="Novo tipo de despesa"
        size="sm"
        open={open}
        primaryButtonDisabled={values.name === ""}
        onRequestClose={() => {
          onClose();
        }}
        primaryButtonText="Concluir"
        secondaryButtonText="Cancelar"
        onRequestSubmit={async () => {
          fetchSendNewPaymentType(sendNewPaymentType);
        }}
      >
        <Margin mt={20} />
        <TextInput
          id="name"
          name="name"
          labelText="Nome"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
        />
        <Margin mb={120} />
      </Modal>
    </form>
  );
};

export default NewPaymentTypeModal;
