import { ITypeBanks } from "~/types/index";
import ApiService from "../api";

export type Ibanks = {
  results: ITypeBanks[];
};

export const getBanks = () => {
  return ApiService.HttpGet<Ibanks>({
    route: "payment/bank/",
    token: true,
  });
};
