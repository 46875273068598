import { Modal, TextInput } from "@carbon/react";
import { useFormik } from "formik";
import Margin from "~/components/Margin";

import * as Yup from "yup";
import { useCallback, useEffect, useMemo } from "react";
import useLoading from "~/hooks/useLoading";

import { ISendNewAttendTypeBody } from "~/services/api/type";

import { toast } from "react-toastify";
import usePaymentType from "~/hooks/useTypesPayment";
import {
  getPaymentTypeAPI,
  patchPaymentTypeAPI,
} from "~/services/api/paymentTypes";

type Values = {
  name: string;
};

const validationSchema = Yup.object({
  name: Yup.string().required("Nome da tarefa é obrigatório"),
  sla: Yup.number().required("Nome da tarefa é obrigatório"),
});

const EditPaymentTypeModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();

  const { setOpenEditPaymentTypeModal, setPaymentTypes, paymentTypesDetails } =
    usePaymentType();

  const notifySuccess = () =>
    toast.success("Tipo de despesa atualizado com sucesso!");
  const notifyError = () =>
    toast.error(
      "Não foi possível atualizar o tipo de despesa. Tente novamente mais tarde."
    );

  const initialValues: Values = {
    name: paymentTypesDetails?.name || "",
  };

  const { handleSubmit, values, handleBlur, handleChange, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: (values) => {
        console.log(values);
      },
    });

  const sendNewAttendType: ISendNewAttendTypeBody = useMemo(() => {
    return {
      name: values.name,
    };
  }, [values]);

  const fetchPaymentTypes = useCallback(async () => {
    try {
      const { data } = await getPaymentTypeAPI();
      setPaymentTypes(data.results.reverse());
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setPaymentTypes]);

  const fetchSendpdatePaymentType = async (payload: ISendNewAttendTypeBody) => {
    setLoading(true);
    try {
      if (paymentTypesDetails) {
        await patchPaymentTypeAPI(payload, paymentTypesDetails?.id);
        setOpenEditPaymentTypeModal(false);

        await fetchPaymentTypes();

        setLoading(false);
        notifySuccess();
      }
    } catch (error) {
      console.error("Erro ao excluir", error);
      setLoading(false);
      notifyError();
    }
  };

  useEffect(() => {
    if (paymentTypesDetails) {
      setFieldValue("name", paymentTypesDetails?.name);
    }
  }, [paymentTypesDetails, setFieldValue]);

  return (
    <form onSubmit={handleSubmit}>
      <Modal
        style={{ zIndex: 6 }}
        modalHeading={`Editar tipo de despesa - ${paymentTypesDetails?.name}`}
        size="sm"
        open={open}
        primaryButtonDisabled={values.name === ""}
        onRequestClose={() => {
          onClose();
        }}
        primaryButtonText="Concluir"
        secondaryButtonText="Cancelar"
        onRequestSubmit={async () => {
          fetchSendpdatePaymentType(sendNewAttendType);
        }}
      >
        <TextInput
          id="name"
          name="name"
          labelText="Nome"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
        />
        <Margin mb={120} />
      </Modal>
    </form>
  );
};

export default EditPaymentTypeModal;
