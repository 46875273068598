import { IAttendType } from "~/types";
import ApiService from "../api";

type IResponseGetAttendTypes = {
  count: number;
  next: null;
  previous: null;
  results: IAttendType[];
};

export type ISendNewAttendTypeBody = {
  name: string;

  active?: boolean;
};

export const postAttendTypeAPI = (data: ISendNewAttendTypeBody) => {
  return ApiService.HttpPost({
    route: "attend/attend-type/",
    body: data,
    token: true,
  });
};

export const getAttendTypeAPI = () => {
  return ApiService.HttpGet<IResponseGetAttendTypes>({
    route: "attend/attend-type/",
    token: true,
  });
};

export const deleteAttendTypeAPI = (id: string) => {
  return ApiService.HttpDelete({
    route: `attend/attend-type/${id}/`,
    token: true,
  });
};

export const patchAttendTypeAPI = (
  data: ISendNewAttendTypeBody,
  id: number
) => {
  return ApiService.HttpPatch({
    route: `attend/attend-type/${id}/`,
    body: data,
    token: true,
  });
};
