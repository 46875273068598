import { IAreaType } from "~/types";
import ApiService from "../api";

type IResponseGetAreaTypes = {
  count: number;
  next: null;
  previous: null;
  results: IAreaType[];
};

export type ISendNewAreaTypeBody = {
  area: number;
  type: number;
};

export type IUpdateNewAreaTypeBody = {
  area: number;
  type: number;
};

const expand = "type,area";

export const postAreaTypeAPI = (data: ISendNewAreaTypeBody) => {
  return ApiService.HttpPost({
    route: "attend/attend-type-area/",
    body: data,
    token: true,
  });
};

export const getAreaTypesAPI = () => {
  return ApiService.HttpGet<IResponseGetAreaTypes>({
    route: "attend/attend-type-area/",
    token: true,
    params: { expand: expand },
  });
};

export const updateAreaTypesAPI = (
  id: string,
  body: IUpdateNewAreaTypeBody
) => {
  return ApiService.HttpPatch<IResponseGetAreaTypes>({
    route: `attend/attend-type-area/${id}/`,
    token: true,
    body: body,
  });
};

export const deleteAreaTypeAPI = (id: string) => {
  return ApiService.HttpDelete({
    route: `attend/attend-type-area/${id}/`,
    token: true,
  });
};
