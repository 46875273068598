/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/ban-types */
// @ts-nocheck
import React, { ComponentType, ReactNode } from "react";

/**
 * Uma curry que recebe um componente `Provider`, um segundo componente que será
 * encapsulado pelo `Provider` e retorna um componente cujas props são as props
 * do componente encapsulado com `providerProps` para as props do provider.
 * @example
 * const LoginWithSubscribe = withProvider(SubscribeProvider)(Login);
 * <LoginWithSubscribe {...{
 *   ...propsOfLogin,
 *   providerProps: { ...propsOfSubscribeProvider }
 * }} />
 * @param Provider - Componente `Provider`, geralmente obtido de um `Context`.
 */
// utils/withProviders.tsx

const withProviders =
  (...providers: ComponentType<{ children: ReactNode }>[]) =>
  <Props extends {}>(Component: ComponentType<Props>): ComponentType<Props> =>
  (props: Props) => {
    return providers.reduceRight(
      (children, Provider) => {
        return <Provider>{children}</Provider>;
      },
      <Component {...props} />
    );
  };

export default withProviders;
