import { Modal } from "@carbon/react";
import Margin from "~/components/Margin";
import { useCallback, useEffect } from "react";
import useLoading from "~/hooks/useLoading";
import useBeneficiary from "~/hooks/useBeneficiary";
import { toast } from "react-toastify";
import useUser from "~/hooks/useUser";
import {
  getBeneficiaryAPI,
  IBodyBeneficiary,
  patchBeneficiaryAPI,
} from "~/services/api/beneficiaries";
import { useParams } from "react-router-dom";
import { PixKey } from "~/types";

export const DeleteModalKeyPix = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();
  const { userProfile } = useUser();
  const { id } = useParams();

  const {
    setBeneficiaryDetails,
    beneficiaryDetails,
    setaddkeyPixModal,
    pixkeyUser,
    setdeleteModalKeyPix,
  } = useBeneficiary();

  const notifySuccess = () => toast.success("Chave removida com sucesso!");
  const notifyError = () => toast.error("Não foi remover a Chave");

  const UpdateBene = (IDaccount: number) => {
    if (beneficiaryDetails) {
      const updatedBankAccounts: PixKey[] = (beneficiaryDetails?.pix_keys || [])
        .flatMap((keys) => (Array.isArray(keys) ? keys : [keys]))
        .filter((keys) => keys.id !== IDaccount);
      fetchDeleteKey(updatedBankAccounts);
    }
  };

  const fetchBeneficiary = useCallback(async () => {
    try {
      const { data } = await getBeneficiaryAPI(Number(id));
      setBeneficiaryDetails(data);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [id, setBeneficiaryDetails]);

  const fetchDeleteKey = useCallback(
    async (Keys_filter: PixKey[]) => {
      const updatedPayload: IBodyBeneficiary = {
        name: beneficiaryDetails?.name || "",
        document_number: beneficiaryDetails?.document_number || "",
        bank_account: beneficiaryDetails?.bank_account || [],
        profile: userProfile?.id || 0,
        pix_keys: Keys_filter,
      };
      setLoading(true);
      try {
        await patchBeneficiaryAPI(Number(id), updatedPayload);
        setdeleteModalKeyPix(false);
        await fetchBeneficiary();
        setaddkeyPixModal(false);
        setLoading(false);
        notifySuccess();
      } catch (err: unknown) {
        console.error(err);
        setLoading(false);
        notifyError();
      }
    },
    [fetchBeneficiary, pixkeyUser, userProfile, beneficiaryDetails]
  );

  useEffect(() => {
    fetchBeneficiary();
  }, []);

  const TypeKeysLabel: { [key: string]: string } = {
    cnpj: "CNPJ",
    cpf: "CPF",
    celphone: "Celular",
    evp: "Chave Aleatoria",
    email: "Email",
  };
  return (
    <Modal
      style={{ zIndex: 6 }}
      modalHeading="Remover Chave Pix Cadastrada"
      size="sm"
      open={open}
      onRequestClose={onClose}
      primaryButtonText="Remover"
      secondaryButtonText="Cancelar"
      onRequestSubmit={async () => {
        UpdateBene(Number(pixkeyUser?.id));
      }}
    >
      <>
        <Margin mt={20} />
        <h3 style={{ textAlign: "center" }}>
          Deseja realmente remover a Chave PIx Abaixo?
        </h3>
        <Margin mt={10} />
        <h4>
          <span style={{ fontWeight: "bold" }}>Banco: </span>
          {pixkeyUser?.bank} - {pixkeyUser?.bank_name}
        </h4>
        <h4>
          <span style={{ fontWeight: "bold" }}>Nome: </span>
          {pixkeyUser?.name}
        </h4>
        <h4>
          {" "}
          <span style={{ fontWeight: "bold" }}>Chave: </span>
          {pixkeyUser?.key}
        </h4>
        <h4>
          {" "}
          <span style={{ fontWeight: "bold" }}>Tipo: </span>
          {TypeKeysLabel[pixkeyUser?.type || "Não encontrada"]}
        </h4>
      </>

      <Margin mb={40} />
    </Modal>
  );
};
