import { InlineNotification, Modal } from "@carbon/react";
import { useFormik } from "formik";
import Margin from "~/components/Margin";

import * as Yup from "yup";
import { useCallback, useEffect, useMemo, useState } from "react";
// import useLoading from "~/hooks/useLoading";
import Select from "react-select";

import useAttend from "~/hooks/useAttend";

import { IAttendAreaProfile, IAttendLog } from "~/types";
// import { useParams } from "react-router-dom";
// import {
//   getAttendDetailsAPI,
//   patchAttendDetailsAPI,
// } from "~/services/api/attend";
import useArea from "~/hooks/useArea";
import { getAreasAPI } from "~/services/api/areas";
import Label from "~/components/Label";

import { getAttendAreaProfilesAPI } from "~/services/api/attendAreaProfile";
import useLoading from "~/hooks/useLoading";
import {
  getAttendDetailsAPI,
  getAttendHistoryAPI,
  patchAttendDetailsAPI,
} from "~/services/api/attend";
import { getAttendLogsAPI, patchAttendLogAPI } from "~/services/api/attendLog";
import { useParams } from "react-router-dom";

type Values = {
  internal: string;
};

const initialValues: Values = {
  internal: "",
};

const validationSchema = Yup.object({
  internal: Yup.string(),
});

const UpdateOwnerAttendModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();
  const {
    attendDetails,
    attendLog,
    setOpenUpdatedOwnerAttendModal,
    setAttendLog,
    setAttendDetails,
  } = useAttend();

  const { setAttendAreaProfiles, attendAreaProfiles, setAreas } = useArea();
  const { setHistory } = useAttend();

  const { id } = useParams();

  const [error, setError] = useState<boolean>(false);

  const fetchAreas = async () => {
    try {
      const { data } = await getAreasAPI();
      setAreas(data.results);
    } catch (e: unknown) {
      console.log(e);
    }
  };

  const { handleSubmit, setFieldValue, values } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  type SelectOption = {
    value: string;
    label: string;
  };

  const handleSelectInternal = (
    selectedOption: SelectOption | null,
    actionMeta: { action: string }
  ) => {
    if (selectedOption) {
      setFieldValue("internal", selectedOption.value);
    } else if (actionMeta.action === "clear") {
      setFieldValue("internal", "");
    }
  };

  const fetchHistory = useCallback(async () => {
    try {
      const { data } = await getAttendHistoryAPI(Number(id));
      setHistory(data);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setHistory, id]);

  const fetchAttendAreaProfiles = useCallback(async () => {
    try {
      const { data } = await getAttendAreaProfilesAPI(
        Number(attendLog?.actual_area?.id)
      );
      setAttendAreaProfiles(data.results);
    } catch (e) {
      console.log(e);
    }
  }, [setAttendAreaProfiles, attendLog?.actual_area?.id]);

  const payloadUpdateInternal = useMemo(() => {
    return {
      owner: Number(values.internal),
    };
  }, [values.internal]);

  const fetchAttends = useCallback(async () => {
    setLoading(true);
    if (attendDetails) {
      try {
        const { data } = await getAttendDetailsAPI(attendDetails?.id);

        setAttendDetails(data);

        setLoading(false);
      } catch (err: unknown) {
        console.error(err);
        setLoading(false);
      }
    }
  }, [attendDetails, setAttendDetails, setLoading]);

  const fetchAttendLog = useCallback(async () => {
    try {
      const { data } = await getAttendLogsAPI();

      const filteredData = data.results.filter(
        (log: IAttendLog) => log.attend === Number(attendDetails?.id)
      );

      setAttendLog(filteredData[0]);
    } catch (e) {
      console.log(e);
    }
  }, [attendDetails?.id, setAttendLog]);

  const fetchUpdateInternalAttend = useCallback(async () => {
    setLoading(true);
    try {
      if (attendDetails) {
        await patchAttendDetailsAPI(attendDetails?.id, payloadUpdateInternal);

        await patchAttendLogAPI(
          {
            previous_profile: attendDetails?.owner?.id,
          },
          attendLog?.id ?? 0
        );
        fetchAttends();
        fetchAttendLog();
        setError(false);
        setOpenUpdatedOwnerAttendModal(false);
      }
      setLoading(false);
      fetchHistory();
    } catch (e) {
      console.log(e);
      setError(true);
      setLoading(false);
    }
  }, [
    setLoading,
    fetchHistory,
    attendDetails,
    payloadUpdateInternal,
    attendLog?.id,
    setOpenUpdatedOwnerAttendModal,
    fetchAttendLog,
    fetchAttends,
  ]);

  const filteredAreaProfiles = useMemo(
    () =>
      attendAreaProfiles.filter((attendAreaProfile: IAttendAreaProfile) => {
        return (
          attendAreaProfile.area === attendLog?.actual_area.id &&
          attendAreaProfile.profile.type === "internal"
        );
      }),
    [attendAreaProfiles, attendLog]
  );

  const internalsOptions = useMemo(() => {
    return filteredAreaProfiles?.map((areaProfile: IAttendAreaProfile) => ({
      value: String(areaProfile.profile.id),
      label: `${areaProfile.profile.name}`,
    }));
  }, [filteredAreaProfiles]);

  useEffect(() => {
    fetchAreas();
    fetchAttendAreaProfiles();
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Modal
        style={{ zIndex: 3 }}
        modalHeading={`Atribuir responsável - #${attendDetails?.id} ${attendDetails?.type?.name}`}
        size="sm"
        open={open}
        onRequestClose={() => {
          onClose();
          setError(false);
        }}
        primaryButtonText="Concluir"
        secondaryButtonText="Cancelar"
        primaryButtonDisabled={values.internal === ""}
        onRequestSubmit={async () => {
          fetchUpdateInternalAttend();
        }}
      >
        {error && (
          <InlineNotification
            title="Erro!"
            subtitle="Não foi possivel atribuir o responsável"
            hideCloseButton
            kind="error"
            lowContrast
          />
        )}
        <Margin mt={20} />

        <Label text="Selecione um responsável" />
        <Select
          isClearable={true}
          options={internalsOptions}
          onChange={(selectedOption, actionMeta) =>
            handleSelectInternal(selectedOption, actionMeta)
          }
          placeholder="Pesquisar ou selecionar um responsável..."
        />
        <Margin mb={204} />
      </Modal>
    </form>
  );
};

export default UpdateOwnerAttendModal;
