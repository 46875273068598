import constate from "constate";
import { useState } from "react";
import { IAttendTask, ITask } from "~/types";

const [TaskProvider, useTask] = constate(() => {
  const [tasks, setTasks] = useState<ITask[]>([]);
  const [attendTasks, setAttendTasks] = useState<IAttendTask[]>([]);
  const [taskDetails, setTaskDetails] = useState<ITask>();
  const [attendTaskDetails, setAttendTaskDetails] = useState<IAttendTask>();
  const [openDeleteTaskModal, setOpenDeleteTaskModal] =
    useState<boolean>(false);
  const [openEditTaskModal, setOpenEditTaskModal] = useState<boolean>(false);
  const [openUpdateOwnerTaskModal, setOpenUpdateOwnerTaskModal] =
    useState<boolean>(false);
  const [openUpdateStatusTaskModal, setOpenUpdateStatusTaskModal] =
    useState<boolean>(false);
  const [openUpdateStatusAttendTaskModal, setOpenUpdateStatusAttendTaskModal] =
    useState<boolean>(false);

  return {
    taskDetails,
    setTaskDetails,
    attendTaskDetails,
    setAttendTaskDetails,
    tasks,
    setTasks,
    attendTasks,
    setAttendTasks,
    openDeleteTaskModal,
    setOpenDeleteTaskModal,
    openEditTaskModal,
    setOpenEditTaskModal,
    openUpdateOwnerTaskModal,
    setOpenUpdateOwnerTaskModal,
    openUpdateStatusTaskModal,
    setOpenUpdateStatusTaskModal,
    openUpdateStatusAttendTaskModal,
    setOpenUpdateStatusAttendTaskModal,
  };
});

export { TaskProvider };

export default useTask;
