import { Modal, TextInput } from "@carbon/react";
import { useFormik } from "formik";
import Margin from "~/components/Margin";

import * as Yup from "yup";
import { useCallback, useMemo } from "react";
import useLoading from "~/hooks/useLoading";

import { toast } from "react-toastify";
import useCompanyType from "~/hooks/useCompanyType";
import {
  getCompanyTypesAPI,
  ISendNewCompanyTypeBody,
  postCompanyTypeAPI,
} from "~/services/api/company-types";

const notifySuccess = () =>
  toast.success("Tipo de empresa criado com sucesso!");
const notifyError = () =>
  toast.error(
    "O tipo de empresa não pode ser criado. Tente novamente mais tarde."
  );

type Values = {
  name: string;
};

const initialValues: Values = {
  name: "",
};

const validationSchema = Yup.object({
  name: Yup.string().required("Nome do tipo de empresa é obrigatório"),
});

const NewCompanyTypeModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();

  const { setCompanyTypes, setOpenNewCompanyTypeModal } = useCompanyType();

  const {
    handleSubmit,

    resetForm,

    values,
    handleBlur,
    handleChange,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const sendNewTaskPayload: ISendNewCompanyTypeBody = useMemo(() => {
    return {
      name: values.name,
    };
  }, [values]);

  const fetchCompanyTypes = useCallback(async () => {
    try {
      const { data } = await getCompanyTypesAPI();
      setCompanyTypes(data.results.reverse());
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setCompanyTypes]);

  const fetchSendNewCompanyType = async (payload: ISendNewCompanyTypeBody) => {
    setLoading(true);
    try {
      await postCompanyTypeAPI(payload);

      resetForm();
      setOpenNewCompanyTypeModal(false);
      await fetchCompanyTypes();
      setLoading(false);
      notifySuccess();
    } catch (error) {
      console.error("Erro ao enviar Tipo de empresa:", error);
      setLoading(false);
      notifyError();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Modal
        modalHeading="Novo tipo de empresa"
        size="sm"
        open={open}
        onRequestClose={onClose}
        primaryButtonDisabled={values.name === ""}
        primaryButtonText="Concluir"
        secondaryButtonText="Cancelar"
        onRequestSubmit={async () => {
          fetchSendNewCompanyType(sendNewTaskPayload);
        }}
      >
        <Margin mb={24} />
        <TextInput
          id="name"
          name="name"
          labelText="Nome do tipo de empresa"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
        />

        <Margin mb={54} />
      </Modal>
    </form>
  );
};

export default NewCompanyTypeModal;
