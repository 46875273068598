import { IAttendLog } from "~/types";
import ApiService from "../api";

export type IResponseAttendLog = {
  count: number;
  next: null;
  previous: null;
  results: IAttendLog[];
};

type ISendBodyAttendLog = {
  attend: number;
  actual_area: number;
  previous_area?: number;
  actual_profile?: number;
  previous_profile?: number;
};

type IUpdateAttendLog = {
  actual_area?: number;
  previous_area?: number;
  actual_profile?: number;
  previous_profile?: number;
};

const expand: string[] = [
  "actual_area,previous_area,actual_profile,previous_profile",
];

export const getAttendLogAPI = (id: number) => {
  return ApiService.HttpGet<IAttendLog>({
    route: "attend/attend-log/",
    token: true,
    params: {
      attend: id,
      expand,
    },
  });
};

export const getAttendLogsAPI = () => {
  return ApiService.HttpGet<IResponseAttendLog>({
    route: "attend/attend-log/",
    token: true,
    params: {
      expand,
    },
  });
};

export const postAttendLogAPI = (body: ISendBodyAttendLog) => {
  return ApiService.HttpPost<IAttendLog>({
    route: "attend/attend-log/",
    token: true,
    body: body,
  });
};

export const patchAttendLogAPI = (body: IUpdateAttendLog, id: number) => {
  return ApiService.HttpPatch<IAttendLog>({
    route: `attend/attend-log/${id}/`,
    token: true,
    body: body,
  });
};
