import { Modal, Toggle } from "@carbon/react";

import Margin from "~/components/Margin";
import { useCallback, useEffect, useState } from "react";
import useLoading from "~/hooks/useLoading";

import { getProfilesAPI, patchProfileAPI } from "~/services/api/profile";
import useProfile from "~/hooks/useProfile";
import { toast } from "react-toastify";
import { formatCPFCNPJ } from "~/services/utils";

const EditPermissionsApproverModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();
  const { detailsProfile, setOpenEditProfileModal, setOpenDeleteProfileModal } =
    useProfile();
  const { setProfiles } = useProfile();
  const [beneficiariesApprover, setBeneficiariesApprover] = useState(false);
  const [paymentApprover, setPaymentApprover] = useState(false);

  const notifySuccess = () =>
    toast.success("Permissões atualizados com sucesso!");
  const notifyError = () =>
    toast.error(
      "Não foi possível atualizar os permissões. Tente novamente mais tarde."
    );

  const fetchProfiles = useCallback(async () => {
    try {
      const { data } = await getProfilesAPI({ mode: "list" });
      setProfiles(data.results.reverse());
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setProfiles]);

  interface Permissions {
    beneficiary_approver?: boolean;
    payment_approver?: boolean;
  }

  const fetchDeleteProfile = useCallback(
    async (id: number) => {
      setLoading(true);
      const permissions: Permissions = {};

      if (beneficiariesApprover) {
        permissions.beneficiary_approver = true;
      } else {
        permissions.beneficiary_approver = false;
      }

      if (paymentApprover) {
        permissions.payment_approver = true;
      } else {
        permissions.payment_approver = false;
      }

      try {
        await patchProfileAPI(permissions, String(id));
        await fetchProfiles();
        setOpenDeleteProfileModal(false);
        setLoading(false);
        notifySuccess();
      } catch (err: unknown) {
        console.error(err);
        setLoading(false);
        notifyError();
      }
    },
    [
      setLoading,
      paymentApprover,
      beneficiariesApprover,
      fetchProfiles,
      setOpenEditProfileModal,
    ]
  );

  useEffect(() => {
    fetchProfiles();
  }, []);

  useEffect(() => {
    if (detailsProfile) {
      setBeneficiariesApprover(Boolean(detailsProfile?.beneficiary_approver));
      setPaymentApprover(Boolean(detailsProfile?.payment_approver));
    }
  }, [detailsProfile]);

  return (
    <Modal
      style={{ zIndex: 6 }}
      modalHeading="Editar Permissões de Aprovação"
      size="sm"
      open={open}
      onRequestClose={onClose}
      primaryButtonText="Salvar alterações"
      secondaryButtonText="Cancelar"
      onRequestSubmit={async () => {
        detailsProfile?.id && fetchDeleteProfile(detailsProfile?.id);
      }}
    >
      <Margin mt={20} />
      <h3 style={{ textAlign: "center" }}>Permissões de aprovação</h3>
      <Margin mb={20} />
      <h4>
        {" "}
        <span style={{ fontWeight: "bold" }}>Nome: </span>{" "}
        {detailsProfile?.name}
      </h4>
      <h4>
        {" "}
        <span style={{ fontWeight: "bold" }}>CPF: </span>{" "}
        {detailsProfile?.document_number &&
          formatCPFCNPJ(detailsProfile?.document_number)}
      </h4>

      {/* Toggle for Gerente de ferramenta */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "50%",
          marginTop: "25px",
        }}
      >
        <p>Aprovador(a) de Beneficiários </p>
        <Toggle
          id="toggle-1"
          labelA="Não"
          labelB="Sim"
          toggled={beneficiariesApprover}
          onToggle={(value) => setBeneficiariesApprover(value)}
        />
      </div>

      {/* Toggle for Gerente de Área */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "50%",
          marginTop: "5px",
        }}
      >
        <p>Aprovador(a) de Pagamentos</p>
        <Toggle
          id="toggle-2"
          labelA="Não"
          labelB="Sim"
          toggled={paymentApprover}
          onToggle={(value) => setPaymentApprover(value)}
        />
      </div>

      <Margin mb={100} />
    </Modal>
  );
};

export default EditPermissionsApproverModal;
