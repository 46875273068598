import { Modal, TextInput } from "@carbon/react";
import { useFormik } from "formik";
import Margin from "~/components/Margin";

import * as Yup from "yup";
import { useCallback, useEffect, useMemo } from "react";
import useLoading from "~/hooks/useLoading";

import { toast } from "react-toastify";
import useCompanyType from "~/hooks/useCompanyType";
import {
  getCompanyTypesAPI,
  IUpdateNewCompanyTypeBody,
  updateCompanyTypeAPI,
} from "~/services/api/company-types";

type Values = {
  name: string;
};

const initialValues: Values = {
  name: "",
};

const validationSchema = Yup.object({
  name: Yup.string().required("Nome da tarefa é obrigatório"),
});

const EditCompanyTypeModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();

  const { setCompanyTypes, companyTypeDetails, setOpenEditCompanyTypeModal } =
    useCompanyType();

  const notifySuccess = () =>
    toast.success("Tipo de empresa atualizado com sucesso!");
  const notifyError = () =>
    toast.error(
      "O tipo de empresa não pode ser atualizado. Tente novamente mais tarde."
    );

  const {
    handleSubmit,

    resetForm,

    values,
    handleBlur,
    setFieldValue,
    handleChange,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const sendNewTaskPayload: IUpdateNewCompanyTypeBody = useMemo(() => {
    return {
      name: values.name,
    };
  }, [values]);

  const fetchCompanyTypes = useCallback(async () => {
    try {
      const { data } = await getCompanyTypesAPI();
      setCompanyTypes(data.results.reverse());
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setCompanyTypes]);
  const fetchEditCompanyType = async (payload: IUpdateNewCompanyTypeBody) => {
    setLoading(true);

    try {
      if (companyTypeDetails) {
        await updateCompanyTypeAPI(payload, String(companyTypeDetails?.id));

        resetForm();
        setOpenEditCompanyTypeModal(false);
        await fetchCompanyTypes();
        setLoading(false);

        notifySuccess();
      }
    } catch (error) {
      console.error("Erro ao enviar Tipo de empresa", error);
      setLoading(false);
      notifyError();
    }
  };

  useEffect(() => {
    if (companyTypeDetails) {
      setFieldValue("name", companyTypeDetails.name);
    }
  }, [companyTypeDetails, setFieldValue]);

  return (
    <form onSubmit={handleSubmit}>
      <Modal
        modalHeading={` ${companyTypeDetails?.name} - Editar`}
        size="sm"
        open={open}
        primaryButtonDisabled={values.name === ""}
        onRequestClose={onClose}
        primaryButtonText="Concluir"
        secondaryButtonText="Cancelar"
        onRequestSubmit={async () => {
          fetchEditCompanyType(sendNewTaskPayload);
        }}
      >
        <Margin mb={24} />
        <TextInput
          id="name"
          name="name"
          labelText="Nome do tipo de empresa"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
        />

        <Margin mb={54} />
      </Modal>
    </form>
  );
};

export default EditCompanyTypeModal;
