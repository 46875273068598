import { Button, Modal } from "@carbon/react";
import Margin from "~/components/Margin";
import useAttend from "~/hooks/useAttend";
import { useCallback, useEffect, useState } from "react";
import {
  getAttendTaskFileAPI,
  postAttendTaskFileAPI,
} from "~/services/api/attendTaskFile";
import Label from "~/components/Label";
import styled from "styled-components";
import { TrashCan, View } from "@carbon/icons-react";
import useUser from "~/hooks/useUser";
import useLoading from "~/hooks/useLoading";
import { IFile } from "~/types";
import AttendFilesDeleteFileModal from "./AttendFilesDeleteFileModal";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { getAttendHistoryAPI } from "~/services/api/attend";
import { useParams } from "react-router-dom";
// import { saveAs } from "file-saver";

const ContainerFile = styled.div`
  padding: 10px 30px;
  border-radius: 4px;
  border: 1px solid rgba(38, 0, 255, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  margin-bottom: 5px;
`;

const AttendDetailsFiles = () => {
  const { userProfile } = useUser();
  const {
    attendDetails,
    setAttendFiles,
    attendFiles,
    setOpenDeleteFileModal,
    openDeleteFileModal,
  } = useAttend();

  const { setLoading } = useLoading();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<PreviewFile | null>(null);

  const [fileSendedModalOpen, setFileSendedModalOpen] = useState(false);
  const [selectedFileSended, setSelectedFileSended] = useState<IFile | null>(
    null
  );

  const openModalFileSended = (file: IFile) => {
    setSelectedFileSended(file);
    setFileSendedModalOpen(true);
  };

  const closeModalFileSended = async () => {
    await setFileSendedModalOpen(false);
    setSelectedFileSended(null);
  };

  const openModal = (file: PreviewFile) => {
    setSelectedFile(file);
    setModalIsOpen(true);
  };

  const closeModal = async () => {
    await setModalIsOpen(false);
    setSelectedFile(null);
  };

  const notifySuccess = () => toast.success("Documentos enviados com sucesso!");
  const notifyError = () =>
    toast.error(
      "Ocorreu um erro ao enviar os documentos. Tente novamente mais tarde"
    );

  // const downloadFile = async (url: string, filename: string) => {
  //   try {
  //     const response = await fetch(url);

  //     if (!response.ok) {
  //       throw new Error(`Failed to fetch the file from ${url}`);
  //     }

  //     const blob = await response.blob();
  //     saveAs(blob, filename);
  //     console.log(`Arquivo baixado com sucesso: ${filename}`);
  //   } catch (error) {
  //     console.error("Erro ao baixar o arquivo:", error);
  //   }
  // };

  interface PreviewFile extends File {
    preview: string;
  }
  const { setHistory } = useAttend();

  const { id } = useParams();
  const [files, setFiles] = useState<PreviewFile[]>([]);

  const handleDelete = (fileToDelete: File) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const previewFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ) as PreviewFile[];
      setFiles((prevFiles) => [...prevFiles, ...previewFiles]);
    },
  });

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const sendFile = useCallback(
    async (file: PreviewFile) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("target", String(attendDetails?.owner?.id || ""));
      formData.append("sender", String(userProfile?.id || ""));
      formData.append("attend", String(attendDetails?.id));
      formData.append("description", `${file?.name}`);

      await postAttendTaskFileAPI(formData);
    },
    [attendDetails?.id, attendDetails?.owner?.id, userProfile?.id]
  );

  const fetchAttendTaskFiles = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getAttendTaskFileAPI();

      const filteredData = data.results.filter(
        (file: IFile) => file.attend === attendDetails?.id
      );

      setAttendFiles(filteredData);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }, [attendDetails?.id, setAttendFiles, setLoading]);

  const fetchHistory = useCallback(async () => {
    try {
      const { data } = await getAttendHistoryAPI(Number(id));
      setHistory(data);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setHistory, id]);

  const fetchSendNewAttendTaskFile = useCallback(async () => {
    setLoading(true);
    try {
      const filePromises = files.map((file: PreviewFile) => sendFile(file));

      await Promise.all(filePromises);
      setFiles([]);
      await fetchAttendTaskFiles();
      notifySuccess();
      setLoading(false);
      fetchHistory();
    } catch (e) {
      console.log(e);
      notifyError();
      setLoading(false);
    }
  }, [fetchAttendTaskFiles, setLoading, files, sendFile]);

  useEffect(() => {
    fetchAttendTaskFiles();
  }, []);

  console.log();
  return (
    <div>
      <Margin mb={20} />

      <div style={{ padding: "20px", width: "100%" }}>
        <div>
          <Label text="Selecione seus documentos para enviar" />
          <div
            {...getRootProps()}
            style={{
              border: "2px dashed #cccccc",
              padding: "40px",
              textAlign: "center",
            }}
          >
            <input {...getInputProps()} />

            <p>
              Arraste e solte alguns arquivos aqui, ou clique para selecionar os
              arquivos
            </p>
          </div>
        </div>

        <div style={{ marginTop: "10px" }}>
          {files.map((file, index) => (
            <ContainerFile key={String(index)}>
              <p>{file.name}</p>
              <div className="d-flex" style={{ display: "flex" }}>
                <Button
                  hasIconOnly
                  renderIcon={View}
                  iconDescription="Visualizar"
                  size="sm"
                  kind="ghost"
                  onClick={() => openModal(file)}
                />
                {/* <Margin ml={10} />
                            <Button
                              hasIconOnly
                              renderIcon={Download}
                              iconDescription="Download"
                              size="sm"
                              kind="ghost"
                              onClick={() =>
                                downloadFile(file.file, file.description)
                              }
                            /> */}
                <Margin ml={10} />
                <Button
                  hasIconOnly
                  renderIcon={TrashCan}
                  size="sm"
                  kind="ghost"
                  onClick={() => {
                    handleDelete(file);
                  }}
                  iconDescription="Excluir"
                />
              </div>
            </ContainerFile>
          ))}

          <Modal
            open={modalIsOpen}
            onRequestClose={closeModal}
            primaryButtonText="Fechar visualização"
            onRequestSubmit={closeModal}
          >
            <>
              {selectedFile && (
                <div>
                  <h2>{selectedFile.name}</h2>
                  {selectedFile.type.startsWith("image/") ? (
                    <img
                      src={selectedFile.preview}
                      alt={selectedFile.name}
                      style={{ width: "100%" }}
                    />
                  ) : selectedFile.type === "application/pdf" ? (
                    <iframe
                      src={selectedFile.preview}
                      title={`Preview of ${selectedFile.name}`}
                      style={{
                        width: "100%",
                        height: "500px",
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        height: "400px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h4>
                        Tipo de arquivo não suportado para visualização.{" "}
                        <a
                          href={selectedFile.preview}
                          download={selectedFile.name}
                        >
                          Clique aqui para baixar
                        </a>
                      </h4>
                    </div>
                  )}
                </div>
              )}
            </>
          </Modal>

          <Modal
            open={fileSendedModalOpen}
            onRequestClose={closeModalFileSended}
            primaryButtonText="Salvar documento"
            secondaryButtonText="Fechar"
            onSecondarySubmit={() => closeModalFileSended()}
            onRequestSubmit={() =>
              window.open(selectedFileSended?.file, "_blank")
            }
          >
            <>
              {selectedFileSended && (
                <div>
                  <h2>{selectedFileSended.description}</h2>
                  {/(png|jpe?g|webp|gif)$/i.test(
                    selectedFileSended.description
                  ) ? (
                    <img
                      src={selectedFileSended.file}
                      alt={selectedFileSended.description}
                      style={{ width: "100%" }}
                    />
                  ) : selectedFileSended.description.includes("pdf") ? (
                    <iframe
                      src={selectedFileSended.file}
                      title={`Preview of ${selectedFileSended.description}`}
                      style={{
                        width: "100%",
                        height: "500px",
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        height: "400px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h4>
                        Tipo de arquivo não suportado para visualização.{" "}
                        <a
                          href={selectedFileSended.file}
                          download={selectedFileSended.description}
                        >
                          Clique aqui para baixar
                        </a>
                      </h4>
                    </div>
                  )}
                </div>
              )}
            </>
          </Modal>

          <Margin mb={10} />
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              onClick={fetchSendNewAttendTaskFile}
              kind="primary"
              disabled={files?.length === 0}
            >
              {files?.length > 1 ? "Enviar arquivos" : "Enviar arquivo"}
            </Button>
          </div>
        </div>
        <Margin mb={40} />

        <h3>Documentos enviados</h3>

        <Margin mb={20} />
        <div>
          {attendFiles && attendFiles?.length > 0 ? (
            <div>
              {attendFiles?.map((file, index) => {
                return (
                  <ContainerFile key={String(index)}>
                    <p>{file.description}</p>
                    <div className="d-flex" style={{ display: "flex" }}>
                      <Button
                        hasIconOnly
                        renderIcon={View}
                        iconDescription="Visualizar"
                        size="sm"
                        kind="ghost"
                        onClick={() => openModalFileSended(file)}
                      />
                      {/* <Margin ml={10} />
                        <Button
                          hasIconOnly
                          renderIcon={Download}
                          iconDescription="Download"
                          size="sm"
                          kind="ghost"
                          onClick={() =>
                            downloadFile(file.file, file.description)
                          }
                        /> */}
                      <Margin ml={10} />
                    </div>
                  </ContainerFile>
                );
              })}
            </div>
          ) : (
            <div style={{ marginBottom: "100px" }}>
              <h4>Nenhum documento enviado até o momento</h4>
            </div>
          )}
        </div>
      </div>

      <AttendFilesDeleteFileModal
        open={openDeleteFileModal}
        onClose={() => setOpenDeleteFileModal(false)}
      />
    </div>
  );
};

export default AttendDetailsFiles;
