import { Modal } from "@carbon/react";
import Margin from "~/components/Margin";
import { useCallback, useEffect } from "react";
import useLoading from "~/hooks/useLoading";
import useBeneficiary from "~/hooks/useBeneficiary";
import {
  getBeneficiariesAPI,
  deleteBeneficiaryAPI,
} from "~/services/api/beneficiaries";
import { toast } from "react-toastify";
import { onlyNumbers } from "~/services/utils";

// const clientTypeDisplay: { [key: string]: string } = {
//   client: "Cliente",
//   internal: "Atendente",
//   area_manager: "Gerente de àrea",
//   tool_manager: "Gerente de ferramenta",
// };

const DeleteBeneficiaryModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();

  const {
    setBeneficiaries,
    setOpenModalDeleteBeneficiary,
    beneficiaryDetails,
  } = useBeneficiary();

  const notifySuccess = () =>
    toast.success("Beneficiário removido com sucesso!");
  const notifyError = () =>
    toast.error(
      "Não foi possível remover o beneficiário. Tente novamente mais tarde."
    );

  const fetchBeneficiary = useCallback(async () => {
    try {
      const { data } = await getBeneficiariesAPI();
      setBeneficiaries(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setBeneficiaries]);

  const fetchDeleteBeneficiary = useCallback(
    async (id: number) => {
      setLoading(true);
      try {
        await deleteBeneficiaryAPI(id);
        await fetchBeneficiary();
        setOpenModalDeleteBeneficiary(false);
        setLoading(false);
        notifySuccess();
      } catch (err: unknown) {
        console.error(err);
        setLoading(false);
        notifyError();
      }
    },
    [setLoading]
  );

  useEffect(() => {
    fetchBeneficiary();
  }, [fetchDeleteBeneficiary]);

  return (
    <Modal
      style={{ zIndex: 6 }}
      modalHeading="Remover beneficiário"
      size="sm"
      open={open}
      onRequestClose={onClose}
      primaryButtonText="Sim, remover"
      secondaryButtonText="Cancelar"
      onRequestSubmit={async () => {
        beneficiaryDetails?.name &&
          fetchDeleteBeneficiary(Number(beneficiaryDetails.id));
      }}
    >
      <Margin mt={20} />
      <h3 style={{ textAlign: "center" }}>
        Deseja realmente remover o beneficiário abaixo ?
      </h3>
      <Margin mb={40} />
      <h4 style={{ fontWeight: "bold" }}>
        {beneficiaryDetails?.document_number &&
        onlyNumbers(beneficiaryDetails?.document_number).length > 0
          ? "Razão Social: "
          : "Nome: "}
        <span style={{ fontWeight: "normal" }}>{beneficiaryDetails?.name}</span>
      </h4>
      <h4 style={{ fontWeight: "bold" }}>
        {beneficiaryDetails?.document_number &&
        onlyNumbers(beneficiaryDetails?.document_number).length > 0
          ? "CNPJ: "
          : "CPF: "}
        <span style={{ fontWeight: "normal" }}>
          {beneficiaryDetails?.document_number || "Não cadastrado"}
        </span>
      </h4>
      <Margin mb={100} />
    </Modal>
  );
};

export default DeleteBeneficiaryModal;
