import {
  Table,
  TableBatchAction,
  TableBatchActions,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Grid,
  Column,
  Button,
  Pagination,
  TableToolbarSearch,
} from "@carbon/react";
import AlertIcon from "../../../assets/alert.png";
import {
  TrashCan,
  Add,
  Save,
  Download,
  Renew,
  Edit,
} from "@carbon/icons-react";
import Margin from "~/components/Margin";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import useLoading from "~/hooks/useLoading";
// import { useFormik } from "formik";
// import { getPaymentsBeneficiaryAPI } from "~/services/api/payment";
import { getBeneficiariesAPI } from "~/services/api//beneficiaries";
import Padding from "~/components/Padding";
import useBeneficiary from "~/hooks/useBeneficiary";
import { formatCPFCNPJ, onlyNumbers } from "~/services/utils";
import { permissions } from "~/services/permissions";
import { Tabs } from "antd";
import DeleteBeneficiaryModal from "./DeleteBeneficiariesModal";
//  import FilterModal from "./FilterModal";
import ApproveBeneficiaryModal from "./ApproveBeneficiaryModal";
import ReproveBeneficiaryModal from "./ReproveModalBeneficiary";
import Badge from "~/components/Badge";
import ChangeStatusBeneficiaryModal from "./ChangeStatusBeneficiaryModal";
import { useFormik } from "formik";
import { Tooltip } from "prosperita-dumbo-react";
import { IBeneficiary } from "~/types";

const tableHeadersProfile: string[] = [
  "Nome/razão social",
  "CPF/CNPJ",
  "Status",
  "",
  "",
];
const canReadProfiles = permissions.includes("can_read_profiles");
const canCreateProfiles = permissions.includes("can_create_profiles");

const renderStatus: { [key: string]: React.ReactNode } = {
  inactive: <Badge label="Inativo" color="#e3e3e3" textColor="#696868" />,

  waiting_approval: (
    <Badge color="#ffe9c9" textColor="#b46c00" label="Aguardando aprovação" />
  ),
  approved: <Badge color="#CCEFDF" textColor="#00B360" label="Ativo" />,

  reproved: <Badge label="Reprovado" textColor="#ff0000" color="#f9c6c6" />,
};

const statusTranslate: { [key: string]: string } = {
  inactive: "Inativo",
  waiting_approval: "Aguardando Aprovação",
  approved: "Ativo",
  reproved: "Reprovado",
};

const Beneficiaries = () => {
  const navigate = useNavigate();

  const {
    Beneficiaries,
    setBeneficiaries,
    openModalDeleteBeneficiary,
    setOpenModalDeleteBeneficiary,
    setBeneficiaryDetails,
    openApproveBeneficiaryModal,
    setOpenApproveBeneficiaryModal,
    openReproveBeneficiaryModal,
    setOpenReproveBeneficiaryModal,
    setEditStatusBeneficiaryModal,
    editStatusBeneficiaryModal,
  } = useBeneficiary();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const { values, handleChange } = useFormik({
    initialValues: {
      search: "",
    },
    onSubmit: () => {},
  });

  const { setLoading } = useLoading();

  const paginatedBeneficiaries = useMemo(() => {
    const lowerSearchTerm = values.search.toLowerCase();
    const searchIsNumeric = onlyNumbers(values.search).length > 0;

    const filteredBeneficiaries = Beneficiaries.filter(
      (beneficiary) => beneficiary.raw_data?.status !== "waiting_approval"
    ).filter((beneficiary) => {
      const beneficiaryName = beneficiary.name?.toLowerCase() || "";
      const beneficiaryDocument =
        onlyNumbers(beneficiary.document_number) || "";
      const beneficiaryStatus =
        statusTranslate[String(beneficiary.raw_data?.status)]?.toLowerCase() ||
        "";

      if (lowerSearchTerm === "ativo") {
        return beneficiary.raw_data?.status === "approved";
      }

      if (searchIsNumeric) {
        return beneficiaryDocument.includes(onlyNumbers(values.search));
      }

      return (
        beneficiaryName.includes(lowerSearchTerm) ||
        beneficiaryStatus.includes(lowerSearchTerm)
      );
    });

    // Função para verificar pendências
    const hasPending = (beneficiary: IBeneficiary) =>
      beneficiary.bank_account?.some(
        (account) => account.status === "waiting_approval"
      ) || beneficiary.raw_data?.pending_funds_approvations;

    // Ordenar priorizando os com pendências
    const sortedBeneficiaries = filteredBeneficiaries.sort((a, b) => {
      const aHasPending = hasPending(a);
      const bHasPending = hasPending(b);

      // Colocar os com pendências primeiro
      if (aHasPending && !bHasPending) return -1;
      if (!aHasPending && bHasPending) return 1;

      // Se ambos ou nenhum tiverem pendências, ordenar por nome
      return (a.name || "").localeCompare(b.name || "", "pt-BR", {
        sensitivity: "base",
      });
    });

    // Lógica de paginação
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return sortedBeneficiaries.slice(start, end);
  }, [currentPage, itemsPerPage, Beneficiaries, values]);

  const [
    currentPageBeneficiariesInactive,
    setCurrentPageBeneficiariesInactive,
  ] = useState(1);
  const [
    itemsPerPageBeneficiariesInactive,
    setItemsPerPageBeneficiariesInactive,
  ] = useState(20);

  const paginatedBeneficiariesInactive = useMemo(() => {
    const start =
      (currentPageBeneficiariesInactive - 1) *
      itemsPerPageBeneficiariesInactive;
    const end = start + itemsPerPageBeneficiariesInactive;

    const filteredBeneficiaries = Beneficiaries.filter(
      (beneficiary) => beneficiary.raw_data?.status === "waiting_approval"
    );

    return filteredBeneficiaries?.slice(start, end);
  }, [
    currentPageBeneficiariesInactive,
    itemsPerPageBeneficiariesInactive,
    Beneficiaries,
  ]);

  // const paginatedBeneficiariesReproved = useMemo(() => {
  //   const start =
  //     (currentPageBeneficiariesReproved - 1) *
  //     itemsPerPageBeneficiariesReproved;
  //   const end = start + itemsPerPageBeneficiariesReproved;

  //   const filteredBeneficiaries = Beneficiaries.filter(
  //     (beneficiary) => beneficiary.raw_data?.status === "reproved"
  //   );

  //   return filteredBeneficiaries?.slice(start, end);
  // }, [
  //   currentPageBeneficiariesReproved,
  //   itemsPerPageBeneficiariesReproved,
  //   Beneficiaries,
  // ]);

  const fetchBeneficiary = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getBeneficiariesAPI();
      setBeneficiaries(data.results.reverse());
      setLoading(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, [setLoading, setBeneficiaries]);

  const { TabPane } = Tabs;

  useEffect(() => {
    fetchBeneficiary();
  }, []);

  return (
    <Grid>
      <Column span={16}>
        <Margin mt={64} />
        <h1>Beneficiários</h1>
        <Margin mt={34} />
        <Margin mt={20} />
        <Tabs
          tabBarStyle={{
            margin: 0,
            borderBottom: "none",
            color: "red",
          }}
        >
          <TabPane tab="Todos" key="1">
            <TableContainer>
              <Margin mb={20} />
              <TableToolbar>
                {canReadProfiles && (
                  <Button
                    tabIndex={0}
                    onClick={() => {
                      fetchBeneficiary();
                    }}
                    kind="secondary"
                    renderIcon={Renew}
                  >
                    Atualizar
                  </Button>
                )}
                {!canReadProfiles && (
                  <div
                    style={{
                      height: "400px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <h4 style={{ position: "relative", left: "45%" }}>
                      Você não possui permissão para beneficiários
                    </h4>
                  </div>
                )}
                <TableBatchActions onCancel={console.log} totalSelected={0}>
                  <TableBatchAction
                    tabIndex={0}
                    renderIcon={TrashCan}
                    onClick={console.log}
                  >
                    Delete
                  </TableBatchAction>
                  <TableBatchAction
                    hasIconOnly
                    iconDescription="Add"
                    tabIndex={0}
                    renderIcon={Add}
                    onClick={console.log}
                  >
                    Delete
                  </TableBatchAction>
                  <TableBatchAction
                    hasIconOnly
                    iconDescription="Save"
                    tabIndex={0}
                    renderIcon={Save}
                    onClick={console.log}
                  >
                    Save
                  </TableBatchAction>
                  <TableBatchAction
                    tabIndex={0}
                    renderIcon={Download}
                    onClick={console.log}
                  >
                    Download
                  </TableBatchAction>
                </TableBatchActions>
                <TableToolbarContent>
                  <TableToolbarSearch
                    tabIndex={0}
                    onChange={handleChange}
                    value={values.search}
                    id="search"
                    placeholder="Pesquisar"
                    persistent
                  />
                  {/* {user.type === "tool_manager" && (
                  <Tooltip defaultOpen label="Exportar relatório" align="top">
                    <Button
                      kind="ghost"
                      hasIconOnly
                      renderIcon={Report}
                      id="filter"
                    />
                  </Tooltip>
                )}
                <Tooltip defaultOpen label="Filtrar" align="top">
                  <Button
                    kind="ghost"
                    hasIconOnly
                    renderIcon={Filter}
                    id="filter"
                    onClick={() => setOpenModal(true)}
                  />
                </Tooltip> */}

                  {canCreateProfiles && (
                    <Button
                      tabIndex={0}
                      onClick={() => {
                        navigate("/app/beneficiaries/new/general/");
                      }}
                      kind="primary"
                    >
                      Novo beneficiário
                    </Button>
                  )}
                </TableToolbarContent>
              </TableToolbar>
              {canReadProfiles && (
                <Table>
                  <TableHead>
                    <TableRow>
                      {/* <TableSelectAll
                    name={""}
                    id={""}
                    onSelect={console.log}
                    checked={false}
                  /> */}
                      {tableHeadersProfile.map((columnTitle, index) => {
                        return (
                          <TableHeader key={String(index)}>
                            {columnTitle}
                          </TableHeader>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedBeneficiaries.length === 0 ? (
                      <TableCell colSpan={tableHeadersProfile.length + 1}>
                        <Padding pt={24} pb={24} pl={12}>
                          <div
                            style={{
                              height: "300px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <h4>
                              Nenhum Beneficiário cadastrado ou encontrado
                            </h4>
                          </div>
                        </Padding>
                      </TableCell>
                    ) : paginatedBeneficiaries.length > 0 ? (
                      paginatedBeneficiaries.map((beneficiary, index) => (
                        <TableRow
                          key={String(index)}
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`${beneficiary.id}`);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <TableCell>
                            {beneficiary.name}

                            <Margin mb={5} />
                          </TableCell>
                          <TableCell>{beneficiary.document_number}</TableCell>
                          <TableCell>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{ position: "absolute", left: "-30px" }}
                              >
                                {(beneficiary?.bank_account?.some(
                                  (account) =>
                                    account.status === "waiting_approval"
                                ) ||
                                  beneficiary.raw_data
                                    ?.pending_funds_approvations) && (
                                  <Tooltip
                                    align="left"
                                    size="medium"
                                    label="Aprovações pendentes"
                                  >
                                    {" "}
                                    <img
                                      style={{
                                        width: "22px",
                                      }}
                                      src={AlertIcon}
                                      alt=""
                                    />{" "}
                                  </Tooltip>
                                )}
                              </div>
                              <div style={{ minWidth: "80px" }}>
                                {beneficiary?.raw_data?.status &&
                                  renderStatus[beneficiary?.raw_data?.status]}
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            {beneficiary?.raw_data?.status !== "reproved" && (
                              <Button
                                kind="ghost"
                                hasIconOnly
                                iconDescription="Alterar Status"
                                renderIcon={Edit}
                                id="filter"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setBeneficiaryDetails(beneficiary);
                                  setEditStatusBeneficiaryModal(true);
                                }}
                              />
                            )}{" "}
                            <Margin ml={3} />
                          </TableCell>
                          <TableCell>
                            <Button
                              kind="ghost"
                              hasIconOnly
                              iconDescription="Remover"
                              renderIcon={TrashCan}
                              id="filter"
                              onClick={(e) => {
                                e.stopPropagation();
                                setOpenModalDeleteBeneficiary(true),
                                  setBeneficiaryDetails(beneficiary);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      paginatedBeneficiaries.map((profile, index) => (
                        <TableRow
                          key={String(index)}
                          style={{ cursor: "pointer" }}
                        >
                          {/* <TableSelectRow
                        name={""}
                        id={""}
                        onSelect={console.log}
                        checked={false}
                        ariaLabel={""}
                      /> */}
                          <TableCell>{profile.name}</TableCell>
                          <TableCell>
                            {" "}
                            {formatCPFCNPJ(profile?.document_number)}
                          </TableCell>
                          <TableCell>
                            <Button
                              kind="ghost"
                              hasIconOnly
                              iconDescription="Editar"
                              renderIcon={Edit}
                              id="filter"
                            />
                            <Button
                              kind="ghost"
                              hasIconOnly
                              iconDescription="Excluir"
                              renderIcon={TrashCan}
                              id="filter"
                            />
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              )}
              <Pagination
                totalItems={paginatedBeneficiaries?.length}
                pageSize={itemsPerPage}
                pageSizes={[5, 10, 20, 30, 40, 50]}
                onChange={({ page, pageSize }) => {
                  setCurrentPage(page);
                  setItemsPerPage(pageSize);
                }}
                page={currentPage}
                size="md"
              />
            </TableContainer>
          </TabPane>

          <TabPane
            tab={`Aguardando Aprovação ${paginatedBeneficiariesInactive?.length > 0 ? `(${paginatedBeneficiariesInactive?.length})` : `(${paginatedBeneficiariesInactive?.length})`}`}
            key="2"
          >
            <TableContainer>
              <Margin mb={20} />
              <TableToolbar>
                {canReadProfiles && (
                  <Button
                    tabIndex={0}
                    onClick={() => {
                      fetchBeneficiary();
                    }}
                    kind="secondary"
                    renderIcon={Renew}
                  >
                    Atualizar
                  </Button>
                )}
                {!canReadProfiles && (
                  <div
                    style={{
                      height: "400px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <h4 style={{ position: "relative", left: "45%" }}>
                      Você não possui permissão para visualizar usuários
                    </h4>
                  </div>
                )}
                <TableBatchActions onCancel={console.log} totalSelected={0}>
                  <TableBatchAction
                    tabIndex={0}
                    renderIcon={TrashCan}
                    onClick={console.log}
                  >
                    Delete
                  </TableBatchAction>
                  <TableBatchAction
                    hasIconOnly
                    iconDescription="Add"
                    tabIndex={0}
                    renderIcon={Add}
                    onClick={console.log}
                  >
                    Delete
                  </TableBatchAction>
                  <TableBatchAction
                    hasIconOnly
                    iconDescription="Save"
                    tabIndex={0}
                    renderIcon={Save}
                    onClick={console.log}
                  >
                    Save
                  </TableBatchAction>
                  <TableBatchAction
                    tabIndex={0}
                    renderIcon={Download}
                    onClick={console.log}
                  >
                    Download
                  </TableBatchAction>
                </TableBatchActions>
                <TableToolbarContent>
                  {/* <TableToolbarSearch
                  tabIndex={0}
                  onChange={handleChange}
                  value={values.search}
                  id="search"
                  placeholder="Pesquisar"
                /> */}
                  {/* {user.type === "tool_manager" && (
                  <Tooltip defaultOpen label="Exportar relatório" align="top">
                    <Button
                      kind="ghost"
                      hasIconOnly
                      renderIcon={Report}
                      id="filter"
                    />
                  </Tooltip>
                )}
                <Tooltip defaultOpen label="Filtrar" align="top">
                  <Button
                    kind="ghost"
                    hasIconOnly
                    renderIcon={Filter}
                    id="filter"
                    onClick={() => setOpenModal(true)}
                  />
                </Tooltip> */}
                </TableToolbarContent>
              </TableToolbar>
              {canReadProfiles && (
                <Table>
                  <TableHead>
                    <TableRow>
                      {/* <TableSelectAll
                    name={""}
                    id={""}
                    onSelect={console.log}
                    checked={false}
                  /> */}
                      {tableHeadersProfile.map((columnTitle, index) => {
                        return (
                          <TableHeader key={String(index)}>
                            {columnTitle}
                          </TableHeader>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedBeneficiariesInactive.length === 0 ? (
                      <TableCell colSpan={tableHeadersProfile.length + 1}>
                        <Padding pt={24} pb={24} pl={12}>
                          <div
                            style={{
                              height: "300px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <h4>
                              Nenhum Beneficiário para aprovação até o momento
                            </h4>
                          </div>
                        </Padding>
                      </TableCell>
                    ) : paginatedBeneficiariesInactive.length > 0 ? (
                      paginatedBeneficiariesInactive.map(
                        (beneficiaries, index) => (
                          <TableRow
                            key={String(index)}
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(`${beneficiaries.id}`);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell>{beneficiaries.name}</TableCell>
                            <TableCell>
                              {beneficiaries.document_number}
                            </TableCell>
                            <TableCell>
                              {beneficiaries?.raw_data?.status &&
                                renderStatus[beneficiaries?.raw_data?.status]}
                            </TableCell>

                            <TableCell></TableCell>
                            <TableCell>
                              <div
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  <div>
                                    <Button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setBeneficiaryDetails(beneficiaries);
                                        setOpenReproveBeneficiaryModal(true);
                                      }}
                                      kind="danger--ghost"
                                    >
                                      Reprovar
                                    </Button>
                                  </div>
                                  <Margin ml={10} />
                                  <div>
                                    <Button
                                      kind="ghost"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setBeneficiaryDetails(beneficiaries);
                                        setOpenApproveBeneficiaryModal(true);
                                      }}
                                    >
                                      Aprovar
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        )
                      )
                    ) : (
                      paginatedBeneficiariesInactive.map((profile, index) => (
                        <TableRow
                          key={String(index)}
                          style={{ cursor: "pointer" }}
                        >
                          {/* <TableSelectRow
                        name={""}
                        id={""}
                        onSelect={console.log}
                        checked={false}
                        ariaLabel={""}
                      /> */}
                          <TableCell>{profile.name}</TableCell>
                          <TableCell>
                            {" "}
                            {formatCPFCNPJ(profile?.document_number)}
                          </TableCell>
                          <TableCell>
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <div>
                                  <Button kind="danger--tertiary">
                                    Reprovar
                                  </Button>
                                </div>
                                <Margin ml={10} />
                                <div>
                                  <Button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setBeneficiaryDetails(profile);
                                      setOpenApproveBeneficiaryModal(true);
                                    }}
                                  >
                                    Aprovar
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              )}
              <Pagination
                totalItems={paginatedBeneficiariesInactive?.length}
                pageSize={itemsPerPageBeneficiariesInactive}
                pageSizes={[5, 10, 20, 30, 40, 50]}
                onChange={({ page, pageSize }) => {
                  setCurrentPageBeneficiariesInactive(page);
                  setItemsPerPageBeneficiariesInactive(pageSize);
                }}
                page={currentPageBeneficiariesInactive}
                size="md"
              />
            </TableContainer>
          </TabPane>
        </Tabs>
        <Margin mb={50} />
      </Column>
      {/* <FilterModal open /> */}
      <DeleteBeneficiaryModal
        open={openModalDeleteBeneficiary}
        onClose={() => setOpenModalDeleteBeneficiary(false)}
      />
      <ApproveBeneficiaryModal
        open={openApproveBeneficiaryModal}
        onClose={() => setOpenApproveBeneficiaryModal(false)}
      />
      <ReproveBeneficiaryModal
        open={openReproveBeneficiaryModal}
        onClose={() => setOpenReproveBeneficiaryModal(false)}
      />
      <ChangeStatusBeneficiaryModal
        open={editStatusBeneficiaryModal}
        onClose={() => setEditStatusBeneficiaryModal(false)}
      />
    </Grid>
  );
};

export default Beneficiaries;
