import React from 'react';
import { NumericFormat, NumberFormatValues } from 'react-number-format';
import styled from 'styled-components';

interface MoneyInputProps {
    value?: string;
    onChange: (value: string) => void;
    label?: string; // Adiciona a label como variável
    name?: string;
    id?: string;
    placeholder?: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledLabel = styled.label`
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 12px;
  color: #333333;
  opacity:0.9;
  margin-bottom: 6px; /* Espaço entre a label e o input */
`;

const StyledInputCurrency = styled(NumericFormat)`
  height: 40px;
  box-sizing: border-box;
  padding: 8px;
  margin: 0;
  width: 100%;
  border: 2px solid transparent;
  border-bottom: 1px solid gray;
  background: #f4f4f4;
  color: #333333;
  font-family: "IBM Plex Sans", sans-serif;
  &::placeholder {
    color: #9b9898;
  }
  font-size: 14px;
  line-height: 18px;
  outline: none;

  &:focus {
    border: 2px solid #0072c6;
  }
`;

const MoneyInput: React.FC<MoneyInputProps> = ({ value, onChange, label, name, id, placeholder }) => {
    return (
        <Container>
            {label && <StyledLabel htmlFor={id}>{label}</StyledLabel>} {/* Label condicional */}
            <StyledInputCurrency
                value={value}
                name={name}
                id={id}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale
                prefix="R$ "
                allowNegative={false}
                placeholder={placeholder}
                onValueChange={(values: NumberFormatValues) => onChange(values.value)}
                className="money-input"
            />
        </Container>
    );
};

export default MoneyInput;
