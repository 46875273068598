import {
  Table,
  TableBatchAction,
  TableBatchActions,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Grid,
  Column,
  Button,
  Tile,
  Pagination,
} from "@carbon/react";

import { TrashCan, Add, Save, Download, Renew } from "@carbon/icons-react";
import Margin from "~/components/Margin";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import useLoading from "~/hooks/useLoading";

import { useFormik } from "formik";
import Padding from "~/components/Padding";
// import FiltersModal from "./FiltersModal";
// import { format } from "date-fns";
import styled from "styled-components";

import Badge from "~/components/Badge";
import { permissions } from "~/services/permissions";

import usePayment from "~/hooks/usePayment";
import { getAttendPaymentsAPI } from "~/services/api/payment";
// import { format } from "date-fns";
import useUser from "~/hooks/useUser";

const TileContainer = styled.div`
  display: flex;
  gap: 15px;
`;

const tableHeadersAttend: string[] = [
  "ID",

  "Solicitante",
  "Despesa",
  "Fundo",
  "Beneficiário",
  "Valor (R$)",
  "Forma de pagamento",
  "Criado em",
  "Data do pagamento",
  "Status",
];

const renderStatus: { [key: string]: React.ReactNode } = {
  opened: <Badge label="Em aberto" color="#e3e3e3" textColor="#696868" />,
  created: (
    <Badge color="#bacbff" textColor="#1906ec" label="Aguar. Pagamento" />
  ),
  in_progress: (
    <Badge color="#ffe9c9" textColor="#ff9900" label="Em andamento" />
  ),
  done: <Badge color="#CCEFDF" textColor="#00B360" label="Finalizado" />,
  return_to_client: (
    <Badge color="#bacbff" textColor="#1906ec" label="Retor. solicitante" />
  ),
  canceled: <Badge label="Cancelado" textColor="#ff0000" color="#f9c6c6" />,
};

const Payments = () => {
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const { userProfile } = useUser();
  const { payments, setPayments } = usePayment();
  // const [openModal, setOpenModal] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredAttends, setFilteredAttends] = useState(payments);

  console.debug(filteredAttends, searchTerm);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const canReadAttends = permissions.includes("can_read_attends");
  // const canDeleteAttends = permissions.includes("can_delete_attends")
  // const canEditAttends = permissions.includes("can_edit_attends")
  const canCreatAttends = permissions.includes("can_create_attends");

  const { values } = useFormik({
    initialValues: {
      search: "",
    },
    onSubmit: (values: { search: string }) => {
      console.log(values);
    },
  });

  interface StatusCounts {
    created?: number;
    done?: number;
    in_progress?: number;
    opened?: number;
    canceled?: number;
    return_to_client?: number;
    [key: string]: number | undefined;
  }

  const totalAttendsPayments = useMemo<StatusCounts>(() => {
    const statusCounts: StatusCounts = payments?.reduce((acc, payment) => {
      const status = payment?.status?.slug;
      if (status) {
        acc[status] = (acc[status] || 0) + 1;
      }
      return acc;
    }, {} as StatusCounts);

    return statusCounts || {};
  }, [payments]);

  const paginatedAttendsPayments = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return payments?.slice(start, end);
  }, [currentPage, itemsPerPage, payments]);

  const renderTiles: { label: string; total: number }[] = [
    { label: "Criados", total: 0 },
    { label: "Em aberto ", total: totalAttendsPayments?.opened ?? 0 },
    { label: "Em andamento", total: totalAttendsPayments?.in_progress ?? 0 },
    {
      label: "Aguardando Pagamento",
      total: 1,
    },
    { label: "Finalizados", total: totalAttendsPayments?.done ?? 0 },
    { label: "Cancelados", total: totalAttendsPayments?.canceled ?? 0 },
  ];

  // const filterAttends = () => {
  //   const filtered = attends.filter((profile) =>
  //     profile.subject.toLowerCase().includes(searchTerm.toLowerCase())
  //   );
  //   setFilteredAttends(filtered);
  // };
  const fetchAttendsPayments = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getAttendPaymentsAPI();
      setPayments(data.results.reverse().sort((a, b) => b.id - a.id));

      setLoading(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, []);

  // const deleteAttend = async () => {
  //   try {
  //     await deleteAttendDetailsAPI(33);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  useEffect(() => {
    fetchAttendsPayments();
  }, []);

  // useEffect(() => {
  //   filterAttends();
  // }, [values.search]);

  useEffect(() => {
    setSearchTerm(values.search);
    if (values.search === "") {
      setFilteredAttends([]);
    }
  }, [values.search]);

  return (
    <Grid>
      <Column span={16}>
        <Margin mt={64} /> <h1>Pagamentos</h1>
        <Margin mt={34} />{" "}
        <TileContainer>
          {canReadAttends &&
            renderTiles.map((tile, index) => {
              return (
                <Tile style={{ flex: 1 }} key={String(index)}>
                  <div>
                    <p>{tile.label}</p>
                    <h2>{tile.total}</h2>
                  </div>
                </Tile>
              );
            })}
        </TileContainer>
        <Margin mt={20} />
        <TableContainer>
          <Margin mb={20} />
          <TableToolbar>
            {canReadAttends && (
              <Button
                tabIndex={0}
                onClick={() => {
                  fetchAttendsPayments();
                }}
                renderIcon={Renew}
                kind="secondary"
              >
                Atualizar
              </Button>
            )}
            {!canReadAttends && (
              <div
                style={{
                  height: "400px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <h4 style={{ position: "relative", left: "45%" }}>
                  Você não possui permissão para visualizar os pagamentos
                </h4>
              </div>
            )}
            <TableBatchActions onCancel={console.log} totalSelected={0}>
              <TableBatchAction
                tabIndex={0}
                renderIcon={TrashCan}
                onClick={console.log}
              >
                Delete
              </TableBatchAction>
              <TableBatchAction
                hasIconOnly
                iconDescription="Add"
                tabIndex={0}
                renderIcon={Add}
                onClick={console.log}
              >
                Delete
              </TableBatchAction>
              <TableBatchAction
                hasIconOnly
                iconDescription="Save"
                tabIndex={0}
                renderIcon={Save}
                onClick={console.log}
              >
                Save
              </TableBatchAction>
              <TableBatchAction
                tabIndex={0}
                renderIcon={Download}
                onClick={console.log}
              >
                Download
              </TableBatchAction>
            </TableBatchActions>
            <TableToolbarContent>
              {/* <TableToolbarSearch
                tabIndex={0}
                onChange={handleChange}
                value={values.search}
                id="search"
                placeholder="Pesquisar"
              /> */}

              {/* {user.type === "tool_manager" && (
                <Tooltip defaultOpen label="Exportar relatório" align="top">
                  <Button
                    kind="ghost"
                    hasIconOnly
                    renderIcon={Report}
                    id="filter"
                  />
                </Tooltip>
              )}
              <Tooltip defaultOpen label="Filtrar" align="top">
                <Button
                  kind="ghost"
                  hasIconOnly
                  renderIcon={Filter}
                  id="filter"
                  onClick={() => setOpenModal(true)}
                />
              </Tooltip> */}
              {(userProfile?.type === "tool_manager" ||
                userProfile?.type === "tool_manager") && (
                <Button
                  tabIndex={0}
                  onClick={() => {
                    navigate("/app/approvers");
                  }}
                  kind="secondary"
                >
                  Aprovadores
                </Button>
              )}

              {canCreatAttends && (
                <Button
                  tabIndex={0}
                  onClick={() => {
                    navigate("/app/payments/new");
                  }}
                  kind="primary"
                >
                  Novo Pagamento
                </Button>
              )}
            </TableToolbarContent>
          </TableToolbar>
          {canReadAttends && (
            <Table>
              <TableHead>
                <TableRow>
                  {/* <TableSelectAll
                  name={""}
                  id={""}
                  onSelect={console.log}
                  checked={false}
                  ariaLabel={""}
                /> */}
                  {tableHeadersAttend.map((columnTitle, index) => {
                    return (
                      <TableHeader key={String(index)}>
                        {columnTitle}
                      </TableHeader>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedAttendsPayments?.length === 0 ? (
                  <TableCell colSpan={tableHeadersAttend?.length + 1}>
                    <Padding pt={24} pb={24} pl={12}>
                      <div
                        style={{
                          height: "300px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <h4>Nenhum pagamento até o momento.</h4>
                      </div>
                    </Padding>
                  </TableCell>
                ) : paginatedAttendsPayments?.length > 0 ? (
                  paginatedAttendsPayments?.map((attend, index) => (
                    <TableRow
                      key={String(index)}
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(`/app/payments/${attend.id}`)}
                    >
                      {/* <TableSelectRow
                      name={""}
                      id={""}
                      onSelect={console.log}
                      checked={false}
                    /> */}
                      <TableCell>#{attend.id}</TableCell>

                      {/* <TableCell>{attend.area}</TableCell>
                  <TableCell>{attend.area_manager}</TableCell> */}
                      <TableCell>
                        <p> {/* <b>{attend.client?.name}</b> */}</p>

                        <p>Luiz Felipe Torrano</p>
                      </TableCell>
                      <TableCell>{"Conta de luz"}</TableCell>
                      <TableCell>{"FII EXEMPLO LTDA"}</TableCell>
                      <TableCell>{"CARLOS MAGNO DA SILVA"}</TableCell>
                      <TableCell>{"547.454,88"}</TableCell>
                      <TableCell>{"PIX (Chave)"}</TableCell>

                      <TableCell>{"06/09/2024"}</TableCell>
                      <TableCell>{"10/09/2024"}</TableCell>

                      <TableCell>
                        {attend?.status?.slug
                          ? renderStatus[attend?.status?.slug]
                          : "Não encontrado"}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  paginatedAttendsPayments?.map((attend, index) => (
                    <TableRow
                      key={String(index)}
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(`/app/payments/${attend.id}`)}
                    >
                      {/* <TableSelectRow
                      name={""}
                      id={""}
                      onSelect={console.log}
                      checked={false}
                    /> */}
                      <TableCell>#{attend.id}</TableCell>

                      {/* <TableCell>{attend.area}</TableCell>
                    <TableCell>{attend.area_manager}</TableCell> */}
                      <TableCell>
                        <p>
                          {" "}
                          <b>{attend.client.name}</b>
                        </p>
                      </TableCell>
                      <TableCell>
                        {attend?.fund?.raw_data?.short_name ?? "-"}
                      </TableCell>
                      <TableCell>{"Pagamento de conta de luz"}</TableCell>

                      <TableCell>{attend?.owner?.name ?? "-"}</TableCell>
                      <TableCell>
                        {attend?.status?.slug
                          ? renderStatus[attend?.status?.slug]
                          : "Não encontrado"}
                      </TableCell>
                      {/* <TableCell>
                        {format(attend.created_on, "dd-MM-yyyy")}
                      </TableCell> */}
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          )}
          <Pagination
            totalItems={payments.length}
            pageSize={itemsPerPage}
            pageSizes={[5, 10, 20, 30, 40, 50]}
            onChange={({ page, pageSize }) => {
              setCurrentPage(page);
              setItemsPerPage(pageSize);
            }}
            page={currentPage}
            size="md"
          />
        </TableContainer>
      </Column>
      {/* <FiltersModal onClose={() => setOpenModal(false)} open={openModal} /> */}
    </Grid>
  );
};

export default Payments;
