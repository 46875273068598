import styled from "styled-components";
import Margin from "./Margin";

const BadgeLabel = styled.span``;

const BadgeContainer = styled.div<BadgeProps>`
  background-color: ${({ color }) => color ?? "green"};
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: auto;
  border-radius: 25px;
  max-width: 120px;

  ${BadgeLabel} {
    color: ${({ textColor }) => textColor ?? "black"};
    font-size: 10px;
    font-weight: bold;
  }
`;

const BadgeIcon = styled.img``;

type BadgeProps = {
  color?: string;
  textColor?: string;
  icon?: string;
  label?: string;
  iconSize?: string;
};

const Badge = (props: BadgeProps) => {
  const { color, textColor, label, icon, iconSize } = props;

  return (
    <>
      <BadgeContainer color={color} textColor={textColor}>
        {BadgeIcon && (
          <BadgeIcon
            src={icon}
            style={iconSize ? { height: `${iconSize}px` } : {}}
          />
        )}
        <Margin ml={3} />
        <BadgeLabel>{label}</BadgeLabel>
      </BadgeContainer>
    </>
  );
};

export default Badge;
