import { IAttend, IHistory } from "~/types";
import ApiService from "../api";

type IResponseAttend = {
  results: IAttend[];
};

type IPayment = {
  payment_date: string;
  title: string;
  amount: number;
  digitable_line: string;
  attend: string;
  beneficiary: number;
  type: number;
};

const expand = "status,type,fund,client,owner,parent,area";

export const getPaymentAPI = () => {
  return ApiService.HttpGet<IPayment>({
    route: "payment/payment/",
    token: true,
    params: {
      expand,
    },
  });
};

export const getPaymentDetailsAPI = (id: number) => {
  return ApiService.HttpGet<IPayment>({
    route: `payment/payment/${id}`,
    token: true,
    params: {
      attend: id,
    },
  });
};

export const getPaymentHistoryAPI = (id: number) => {
  return ApiService.HttpGet<IHistory[]>({
    route: `payment/`,
    token: true,
    params: {
      attend: id,
    },
  });
};

export type ISendUpdatePatchBody = {
  status?: number;
  owner?: number | null;
  type?: number;
};

export const patchPaymentDetailsAPI = (
  id: number,
  body: ISendUpdatePatchBody
) => {
  return ApiService.HttpPatch<IPayment>({
    route: `payment/payment/${id}/`,
    token: true,
    body: body,
  });
};

export type ISendNewPaymentBody = {
  payment_date: string;
  amount: number;
  digitable_line?: string;
  attend: number;
  type: number;
};

export const postPaymentAPI = (data: ISendNewPaymentBody) => {
  return ApiService.HttpPost<IAttend>({
    route: "payment/payment/",
    body: data,
    token: true,
  });
};

export const deletePaymentDetailsAPI = (id: number) => {
  return ApiService.HttpDelete<IAttend>({
    route: `payment/payment/${id}/`,
    token: true,
  });
};

export const getAttendPaymentsAPI = () => {
  return ApiService.HttpGet<IResponseAttend>({
    route: "attend/attend/",
    token: true,
    params: {
      expand,
      origin: "payment",
    },
  });
};
export const getAttendPaymentsDetailsAPI = (id: number) => {
  return ApiService.HttpGet<IResponseAttend>({
    route: `attend/attend/${id}`,
    token: true,
    params: {
      expand,
      origin: "payment",
      id,
    },
  });
};

export type ISendNewAttendPaymentBody = {
  message: string;
  type?: number;
  fund: number;
  client: number;
  owner?: number;
  area?: number;
  parent?: number;
  attend_type?: string;
  document_number?: string;
  celphone?: string;
  evp?: string;
  email?: string;
};

export const postAttendPaymentAPI = (data: ISendNewAttendPaymentBody) => {
  return ApiService.HttpPost<IAttend>({
    route: "attend/attend/",
    body: data,
    token: true,
  });
};


