import { ICompany } from "~/types";
import ApiService from "../api";

type IResponseGetCompanys = {
  count: number;
  next: null;
  previous: null;
  results: ICompany[];
};

type IBodyCompany = {
  name: string;
  raw_data?: {
    active?: boolean;
    brand_name: string;
    document_number: string;
    company_type?: {
      id?: number;
      name?: string;
    };
    master_user?: {
      id: number;
      name: string;
      document_number: string;
    };
  };
};

type IBodyCompanyUpdate = {
  name?: string;
  raw_data?: {
    active?: boolean;
    brand_name?: string;
    document_number?: string;
    company_type?: {
      id: number;
      name: string;
    };
    master_user?: {
      id: number;
      full_name: string;
      document_number: string;
    };
  };
};

export const getCompanysAPI = () => {
  return ApiService.HttpGet<IResponseGetCompanys>({
    route: "company/",
    token: true,
  });
};

export const getCompanyAPI = (id: number) => {
  return ApiService.HttpGet<ICompany>({
    route: `company/${id}`,
    token: true,
  });
};

export const postCompanyAPI = (body: IBodyCompany) => {
  return ApiService.HttpPost<ICompany>({
    route: "company/",
    token: true,
    body,
  });
};

export const updateCompanyAPI = (body: IBodyCompanyUpdate, id: string) => {
  return ApiService.HttpPatch<ICompany>({
    route: `company/${id}/`,
    token: true,
    body,
  });
};

export const deleteCompanyAPI = (id: string) => {
  return ApiService.HttpDelete({
    route: `company/${id}/`,
    token: true,
  });
};
