import { IArea } from "~/types";
import ApiService from "../api";

type IResponseGetAreas = {
  count: number;
  next: null;
  previous: null;
  results: IArea[];
};

export type ISendNewAreaBody = {
  name: string;
  active?: boolean;
};

export const postAreaAPI = (data: ISendNewAreaBody) => {
  return ApiService.HttpPost({
    route: "attend/attend-area/",
    body: data,
    token: true,
  });
};

export const getAreasAPI = () => {
  return ApiService.HttpGet<IResponseGetAreas>({
    route: "attend/attend-area/",
    token: true,
  });
};

export const getAreaAPI = (id: string) => {
  return ApiService.HttpGet<IArea>({
    route: `attend/attend-area/${id}/`,
    token: true,
  });
};

export const deleteAreaAPI = (id: string) => {
  return ApiService.HttpDelete({
    route: `attend/attend-area/${id}/`,
    token: true,
  });
};

export const patchAreaAPI = (data: ISendNewAreaBody, id: string) => {
  return ApiService.HttpPatch({
    route: `attend/attend-area/${id}/`,
    body: data,
    token: true,
  });
};
