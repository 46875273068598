import {
  Table,
  TableBatchAction,
  TableBatchActions,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Grid,
  Column,
  Button,
  Tile,
  Pagination,
} from "@carbon/react";

import { TrashCan, Add, Save, Download, Renew } from "@carbon/icons-react";
import Margin from "~/components/Margin";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import useLoading from "~/hooks/useLoading";
import { getAttendAPI } from "~/services/api/attend";

import { useFormik } from "formik";
import Padding from "~/components/Padding";
import FiltersModal from "./FiltersModal";
import useAttend from "~/hooks/useAttend";
// import { format } from "date-fns";
import styled from "styled-components";

import Badge from "~/components/Badge";
import { permissions } from "~/services/permissions";
import { calculateSla } from "~/services/utils";

const TileContainer = styled.div`
  display: flex;
  gap: 15px;
`;

const tableHeadersAttend: string[] = [
  "ID",
  "Status",
  "Solicitante",
  "Fundo",
  "Tipo",
  "Atendente",
  "SLA",
  // "Criado em",
];

const renderStatus: { [key: string]: React.ReactNode } = {
  opened: <Badge label="Em aberto" color="#e3e3e3" textColor="#696868" />,
  created: <Badge label="Criado" color="#e3e3e3" textColor="#696868" />,
  in_progress: (
    <Badge color="#ffe9c9" textColor="#ff9900" label="Em andamento" />
  ),
  done: <Badge color="#CCEFDF" textColor="#00B360" label="Finalizado" />,
  return_to_client: (
    <Badge color="#bacbff" textColor="#1906ec" label="Retor. solicitante" />
  ),
  canceled: <Badge label="Cancelado" textColor="#ff0000" color="#f9c6c6" />,
};
const renderSla: { [key: string]: React.ReactNode } = {
  noPrazo: <Badge color="#CCEFDF" textColor="#00B360" label="No prazo" />,
  return_to_client: (
    <Badge color="#bacbff" textColor="#1906ec" label="Aguard. solicitante" />
  ),
  done: <Badge color="#bacbff" textColor="#1906ec" label="Atend. Finalizado" />,
  canceled: (
    <Badge color="#bacbff" textColor="#1906ec" label="Atend. Cancelado" />
  ),
  atrasado: <Badge label="Atrasado" textColor="#ff0000" color="#f9c6c6" />,
};

const Attends = () => {
  const navigate = useNavigate();
  const { setLoading } = useLoading();

  const { attends, setAttends } = useAttend();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredAttends, setFilteredAttends] = useState(attends);

  console.debug(filteredAttends, searchTerm);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const canReadAttends = permissions.includes("can_read_attends");
  // const canDeleteAttends = permissions.includes("can_delete_attends")
  // const canEditAttends = permissions.includes("can_edit_attends")
  const canCreatAttends = permissions.includes("can_create_attends");

  const { values } = useFormik({
    initialValues: {
      search: "",
    },
    onSubmit: (values: { search: string }) => {
      console.log(values);
    },
  });

  interface StatusCounts {
    created?: number;
    done?: number;
    in_progress?: number;
    opened?: number;
    canceled?: number;
    return_to_client?: number;
    [key: string]: number | undefined;
  }

  const totalAttends = useMemo<StatusCounts>(() => {
    const statusCounts: StatusCounts = attends?.reduce((acc, attend) => {
      const status = attend?.status?.slug;
      if (status) {
        acc[status] = (acc[status] || 0) + 1;
      }
      return acc;
    }, {} as StatusCounts);

    return statusCounts || {};
  }, [attends]);

  const paginatedAttends = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return attends.slice(start, end);
  }, [currentPage, itemsPerPage, attends]);

  const renderTiles: { label: string; total: number }[] = [
    { label: "Criados", total: totalAttends?.created ?? 0 },
    { label: "Em aberto ", total: totalAttends?.opened ?? 0 },
    { label: "Em andamento", total: totalAttends?.in_progress ?? 0 },
    {
      label: "Aguardando solicitante",
      total: totalAttends?.return_to_client ?? 0,
    },
    { label: "Finalizados", total: totalAttends?.done ?? 0 },
    { label: "Cancelados", total: totalAttends?.canceled ?? 0 },
  ];

  // const filterAttends = () => {
  //   const filtered = attends.filter((profile) =>
  //     profile.subject.toLowerCase().includes(searchTerm.toLowerCase())
  //   );
  //   setFilteredAttends(filtered);
  // };
  const fetchAttends = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getAttendAPI();
      setAttends(data.results.reverse().sort((a, b) => b.id - a.id));

      setLoading(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, []);

  // const deleteAttend = async () => {
  //   try {
  //     await deleteAttendDetailsAPI(33);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  useEffect(() => {
    fetchAttends();
  }, []);

  // useEffect(() => {
  //   filterAttends();
  // }, [values.search]);

  useEffect(() => {
    setSearchTerm(values.search);
    if (values.search === "") {
      setFilteredAttends([]);
    }
  }, [values.search]);

  return (
    <Grid>
      <Column span={16}>
        <Margin mt={64} /> <h1>Atendimentos</h1>
        <Margin mt={34} />{" "}
        <TileContainer>
          {canReadAttends &&
            renderTiles.map((tile, index) => {
              return (
                <Tile style={{ flex: 1 }} key={String(index)}>
                  <div>
                    <p>{tile.label}</p>
                    <h2>{tile.total}</h2>
                  </div>
                </Tile>
              );
            })}
        </TileContainer>
        <Margin mt={20} />
        <TableContainer>
          <Margin mb={20} />
          <TableToolbar>
            {canReadAttends && (
              <Button
                tabIndex={0}
                onClick={() => {
                  fetchAttends();
                }}
                renderIcon={Renew}
                kind="secondary"
              >
                Atualizar
              </Button>
            )}
            {!canReadAttends && (
              <div
                style={{
                  height: "400px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <h4 style={{ position: "relative", left: "45%" }}>
                  Você não possui permissão para visualizar os atendimentos
                </h4>
              </div>
            )}
            <TableBatchActions onCancel={console.log} totalSelected={0}>
              <TableBatchAction
                tabIndex={0}
                renderIcon={TrashCan}
                onClick={console.log}
              >
                Delete
              </TableBatchAction>
              <TableBatchAction
                hasIconOnly
                iconDescription="Add"
                tabIndex={0}
                renderIcon={Add}
                onClick={console.log}
              >
                Delete
              </TableBatchAction>
              <TableBatchAction
                hasIconOnly
                iconDescription="Save"
                tabIndex={0}
                renderIcon={Save}
                onClick={console.log}
              >
                Save
              </TableBatchAction>
              <TableBatchAction
                tabIndex={0}
                renderIcon={Download}
                onClick={console.log}
              >
                Download
              </TableBatchAction>
            </TableBatchActions>
            <TableToolbarContent>
              {/* <TableToolbarSearch
                tabIndex={0}
                onChange={handleChange}
                value={values.search}
                id="search"
                placeholder="Pesquisar"
              /> */}

              {/* {user.type === "tool_manager" && (
                <Tooltip defaultOpen label="Exportar relatório" align="top">
                  <Button
                    kind="ghost"
                    hasIconOnly
                    renderIcon={Report}
                    id="filter"
                  />
                </Tooltip>
              )}
              <Tooltip defaultOpen label="Filtrar" align="top">
                <Button
                  kind="ghost"
                  hasIconOnly
                  renderIcon={Filter}
                  id="filter"
                  onClick={() => setOpenModal(true)}
                />
              </Tooltip> */}
              {canCreatAttends && (
                <Button
                  tabIndex={0}
                  onClick={() => {
                    navigate("/app/attends/new");
                  }}
                  kind="primary"
                >
                  Novo atendimento
                </Button>
              )}
            </TableToolbarContent>
          </TableToolbar>
          {canReadAttends && (
            <Table>
              <TableHead>
                <TableRow>
                  {/* <TableSelectAll
                  name={""}
                  id={""}
                  onSelect={console.log}
                  checked={false}
                  ariaLabel={""}
                /> */}
                  {tableHeadersAttend.map((columnTitle, index) => {
                    return (
                      <TableHeader key={String(index)}>
                        {columnTitle}
                      </TableHeader>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedAttends?.length === 0 ? (
                  <TableCell colSpan={tableHeadersAttend.length + 1}>
                    <Padding pt={24} pb={24} pl={12}>
                      <div
                        style={{
                          height: "300px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <h4>Nenhum atendimento até o momento.</h4>
                      </div>
                    </Padding>
                  </TableCell>
                ) : paginatedAttends.length > 0 ? (
                  paginatedAttends?.map((attend, index) => (
                    <TableRow
                      key={String(index)}
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(`/app/attends/${attend.id}`)}
                    >
                      {/* <TableSelectRow
                      name={""}
                      id={""}
                      onSelect={console.log}
                      checked={false}
                    /> */}
                      <TableCell>#{attend.id}</TableCell>
                      <TableCell>
                        {attend?.status?.slug
                          ? renderStatus[attend?.status?.slug]
                          : "Não encontrado"}
                      </TableCell>

                      {/* <TableCell>{attend.area}</TableCell>
                  <TableCell>{attend.area_manager}</TableCell> */}
                      <TableCell>
                        <p>
                          {" "}
                          <b>{attend.client?.name}</b>
                        </p>
                      </TableCell>
                      <TableCell>
                        {attend?.fund?.raw_data?.short_name ?? "-"}
                      </TableCell>
                      <TableCell>{attend?.type?.name}</TableCell>

                      <TableCell>{attend?.owner?.name ?? "-"}</TableCell>

                      <TableCell>
                        <div style={{ width: "100%" }}>
                          {attend?.status?.slug === "done" ? (
                            renderSla["done"]
                          ) : attend?.status?.slug === "canceled" ? (
                            renderSla["canceled"]
                          ) : attend?.status?.slug === "return_to_client" ? (
                            renderSla["return_to_client"]
                          ) : (
                            <div style={{ marginTop: "5px" }}>
                              {" "}
                              {
                                renderSla[
                                  calculateSla(
                                    attend?.type?.sla ?? 0,
                                    attend?.updated_on ?? ""
                                  ).status
                                ]
                              }
                            </div>
                          )}
                        </div>
                      </TableCell>
                      {/* <TableCell>
                        {format(attend.created_on, "yyyy-MM-dd")}
                      </TableCell> */}
                    </TableRow>
                  ))
                ) : (
                  paginatedAttends?.map((attend, index) => (
                    <TableRow
                      key={String(index)}
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(`/app/attends/${attend.id}`)}
                    >
                      {/* <TableSelectRow
                      name={""}
                      id={""}
                      onSelect={console.log}
                      checked={false}
                    /> */}
                      <TableCell>#{attend.id}</TableCell>
                      <TableCell>
                        {attend?.status?.slug
                          ? renderStatus[attend?.status?.slug]
                          : "Não encontrado"}
                      </TableCell>

                      {/* <TableCell>{attend.area}</TableCell>
                    <TableCell>{attend.area_manager}</TableCell> */}
                      <TableCell>
                        <p>
                          {" "}
                          <b>{attend.client.name}</b>
                        </p>
                      </TableCell>
                      <TableCell>
                        {attend?.fund?.raw_data?.short_name ?? "-"}
                      </TableCell>
                      <TableCell>{attend?.type?.name}</TableCell>

                      <TableCell>{attend?.owner?.name ?? "-"}</TableCell>

                      <TableCell>{attend?.type?.sla}h</TableCell>
                      {/* <TableCell>
                        {format(attend.created_on, "dd-MM-yyyy")}
                      </TableCell> */}
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          )}
          <Pagination
            totalItems={attends.length}
            pageSize={itemsPerPage}
            pageSizes={[5, 10, 20, 30, 40, 50]}
            onChange={({ page, pageSize }) => {
              setCurrentPage(page);
              setItemsPerPage(pageSize);
            }}
            page={currentPage}
            size="md"
          />
        </TableContainer>
      </Column>
      <FiltersModal onClose={() => setOpenModal(false)} open={openModal} />
    </Grid>
  );
};

export default Attends;
