import { Modal } from "@carbon/react";
import Margin from "~/components/Margin";
import { useCallback, useEffect, useMemo } from "react";
import useLoading from "~/hooks/useLoading";
import useBeneficiary from "~/hooks/useBeneficiary";
import { toast } from "react-toastify";
import useUser from "~/hooks/useUser";
import Select from "react-select";
import { TextInput } from "@carbon/react";
import { useFormik } from "formik";
import {
  getBeneficiaryAPI,
  IBodyBeneficiary,
  IBodyPatchBeneficiary,
  patchBeneficiaryAPI,
} from "~/services/api/beneficiaries";
import { getBanks } from "~/services/api/banks";
import useBanks from "~/hooks/useBanks";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

// const clientTypeDisplay: { [key: string]: string } = {
//   client: "Cliente",
//   internal: "Atendente",
//   area_manager: "Gerente de àrea",
//   tool_manager: "Gerente de ferramenta",
// };

type IAccount = {
  account_number: string;
  account_number_digit: string;
  agency: string;
  bank: string;
};

const EditBankAccountBeneficiaryModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();
  const { userProfile } = useUser();
  const { banks, setBanks } = useBanks();
  const { id } = useParams();

  const {
    setBeneficiaries,
    setBeneficiaryDetails,
    beneficiaryDetails,
    setEditModalBeneficiary,
    accountBeneficiary,
    seteditAccountModalBeneficiary,
  } = useBeneficiary();

  const notifySuccess = () => toast.success("conta editada com sucesso!");
  const notifyError = () => toast.error("Não Foi possivel editar conta");

  const fetchBanks = useCallback(async () => {
    try {
      const { data } = await getBanks();
      setBanks(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, []);

  const validationSchema = Yup.object({
    bank: Yup.string().required("O banco é obrigatório"),
    agency: Yup.string()
      .matches(/^\d+$/, "A agência deve conter apenas números")
      .required("A agência é obrigatória"),
    account_number: Yup.string()
      .matches(/^\d+$/, "A conta deve conter apenas números")
      .required("O número da conta é obrigatório"),
  });

  const InitialValues: Omit<IAccount, "account_number_digit"> = {
    account_number: "",
    agency: "",
    bank: "",
  };

  const {
    values,
    handleChange,
    setFieldValue,
    resetForm,
    errors,
    touched,
    validateForm,
    setTouched,
  } = useFormik({
    initialValues: InitialValues,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      console.log(values);
      resetForm();
    },
  });

  const payload: IBodyBeneficiary | unknown = useMemo(() => {
    const currentBankAccounts = beneficiaryDetails?.bank_account || [];

    const updatedBankAccounts = currentBankAccounts.map((account) =>
      account.id === accountBeneficiary?.id
        ? {
            ...account,
            account_number: String(values.account_number).slice(0, -1),
            account_number_digit: String(values.account_number).slice(-1),
            agency: values.agency,
            bank: values.bank,
          }
        : account
    );
    return {
      document_number: beneficiaryDetails?.document_number,
      bank_account: updatedBankAccounts,
      pix_keys: beneficiaryDetails?.pix_keys,
      profile: userProfile?.id,
    };
  }, [values, beneficiaryDetails, userProfile]);

  const banksOptions = useMemo(() => {
    return banks
      ?.map((banks) => ({
        value: String(banks.id),
        label: String(`${banks.code} - ${banks.description}`),
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [banks]);

  const fetchBeneficiary = useCallback(async () => {
    try {
      const { data } = await getBeneficiaryAPI(Number(id));
      setBeneficiaryDetails(data);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setBeneficiaries]);

  const fetchPatchBeneficiary = useCallback(
    async (id: number) => {
      setLoading(true);
      try {
        await patchBeneficiaryAPI(id, payload as IBodyPatchBeneficiary);
        await fetchBeneficiary();
        resetForm();
        setEditModalBeneficiary(false);
        seteditAccountModalBeneficiary(false);
        setLoading(false);
        notifySuccess();
      } catch (err: unknown) {
        console.error(err);
        setLoading(false);
        notifyError();
      }
    },
    [payload, fetchBeneficiary]
  );

  //   type MaskTypeOptions = {
  //     [key: string]: string;

  const BanksFilter = banks.reduce(
    (acc, items_) => {
      if (acc) {
        acc[items_.id] = items_.description;
      }
      return acc;
    },
    {} as { [key: string]: string }
  );

  useEffect(() => {
    if (accountBeneficiary) {
      setFieldValue(
        "account_number",
        accountBeneficiary?.account_number +
          accountBeneficiary?.account_number_digit
      );

      setFieldValue("bank", BanksFilter[accountBeneficiary.bank]);
      setFieldValue("agency", accountBeneficiary.agency);
    }

    fetchBeneficiary();
    fetchBanks();

    // setFieldValue("bank", )
  }, [accountBeneficiary]);

  return (
    <Modal
      style={{ zIndex: 6 }}
      modalHeading="Editar Conta"
      size="sm"
      open={open}
      onRequestClose={() => onClose()}
      primaryButtonText="Editar"
      secondaryButtonText="Cancelar"
      onRequestSubmit={async () => {
        const formErrors = await validateForm();
        if (Object.keys(formErrors).length === 0) {
          await fetchPatchBeneficiary(Number(id));
          onClose();
        } else {
          setTouched({
            bank: true,
            agency: true,
            account_number: true,
          });
        }
      }}
    >
      <>
        <p>Banco</p>
        <Margin mb={10} />
        <Select
          id="bank"
          options={banksOptions}
          name="bank"
          isClearable={true}
          onChange={(e) => setFieldValue("bank", e?.value)}
          placeholder={values.bank}
          onFocus={(e) => e.target.focus()}
        />
        {touched.bank && errors.bank ? <div>{errors.bank}</div> : null}
        <Margin mb={10} />
        <TextInput
          id="agency"
          name="agency"
          labelText="Agencia"
          placeholder={"Digite a agência"}
          onChange={handleChange}
          value={values.agency}
        />
        {touched.agency && errors.agency ? <div>{errors.agency}</div> : null}
        <Margin mb={20} />
        <Margin mb={5} />
        <TextInput
          id="account_number"
          name="account_number"
          labelText="Conta"
          placeholder={accountBeneficiary?.account_number}
          onChange={handleChange}
          value={values.account_number}
        />
        {touched.account_number && errors.account_number ? (
          <div>{errors.account_number}</div>
        ) : null}
        <Margin mb={20} />
        <Margin mb={10} />{" "}
      </>
    </Modal>
  );
};

export default EditBankAccountBeneficiaryModal;
