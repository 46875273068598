import { ICompanyFund } from "~/types";
import ApiService from "../api";

type IResponseGetCompanyFund = {
  count: number;
  next: null;
  previous: null;
  results: ICompanyFund[];
};

type IBodyCompanyFund = {
  fund: number;
  company: number;
};

export const getCompanyFundAPI = (company?: number) => {
  return ApiService.HttpGet<IResponseGetCompanyFund>({
    route: "company-fund/",
    token: true,
    params: { expand: "fund", company },
  });
};

export const postCompanyFundAPI = (body: IBodyCompanyFund) => {
  return ApiService.HttpPost<ICompanyFund>({
    route: "company-fund/",
    token: true,
    body,
  });
};

export const deleteCompanyFundAPI = (id: number) => {
  return ApiService.HttpDelete({
    route: `company-fund/${id}/`,
    token: true,
  });
};
