import { IAttendTask, IAttendTaskMessage } from "~/types";
import ApiService from "../api";

export type IAttendTaskMessageBody = {
  sender: string | undefined;
  subject: string | undefined;
  message: string;
  attend: number | undefined;
};

type IResponseAttendTaskMessage = {
  count: number;
  previous: null;
  next: null;
  results: IAttendTaskMessage[];
};

export const getAttendTaskMessageAPI = () => {
  return ApiService.HttpGet<IResponseAttendTaskMessage>({
    route: "attend/attend-task-message/",
    token: true,
  });
};

export const postAttendTaskMessageAPI = (body: IAttendTaskMessageBody) => {
  return ApiService.HttpPost<IAttendTask[]>({
    route: "attend/attend-task-message/",
    body: body,
    token: true,
  });
};
