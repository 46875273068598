import { Modal } from "@carbon/react";
import Margin from "~/components/Margin";
import { useCallback, useEffect, useMemo } from "react";
import useLoading from "~/hooks/useLoading";
import useBeneficiary from "~/hooks/useBeneficiary";
import { toast } from "react-toastify";
import Select, { MultiValue } from "react-select";

import { useFormik } from "formik";
import {
  getBeneficiaryFundWIDAPI,
  IBeneficiaryFundBody,
  IBodyPatchBeneficiary,
  patchBeneficiaryAPI,
  postBeneficiaryFundAPI,
} from "~/services/api/beneficiaries";

import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { IFund } from "~/types";
import { formatCPFCNPJ } from "~/services/utils";
import Label from "~/components/Label";
import useFund from "~/hooks/useFund";
import { getFundsAPI } from "~/services/api/fund";

// const clientTypeDisplay: { [key: string]: string } = {
//   client: "Cliente",
//   internal: "Atendente",
//   area_manager: "Gerente de àrea",
//   tool_manager: "Gerente de ferramenta",
// };

const AddBeneficiaryFundModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();

  const { setAddBeneficiaryFundModal, setFundsBeneficiaryRender } =
    useBeneficiary();
  const { id } = useParams();
  const { setFunds, funds } = useFund();

  const { beneficiaryDetails } = useBeneficiary();

  const notifySuccess = () =>
    toast.success("Fundo enviado para associação com sucesso!");

  const notifyError = () =>
    toast.error(
      "não foi possivel enviar o fundo para associação. Tente novamente mais tarde."
    );

  const validationSchema = Yup.object({
    bank: Yup.string().required("O banco é obrigatório"),
    agency: Yup.string()
      .matches(/^\d+$/, "A agência deve conter apenas números")
      .required("A agência é obrigatória"),
    account_number: Yup.string()
      .matches(/^\d+$/, "A conta deve conter apenas números")
      .required("O número da conta é obrigatório"),
  });
  const InitialValues = {
    funds: [],
  };
  const { setFieldValue, values, resetForm } = useFormik({
    initialValues: InitialValues,
    validationSchema,
    onSubmit: () => console.log(""),
  });

  const fetchFunds = useCallback(async () => {
    try {
      const { data } = await getFundsAPI();
      setFunds(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setFunds]);

  type SelectOption = {
    value: string;
    label: string;
  };

  const handleSelectFund = useCallback(
    (fundsOptions: MultiValue<SelectOption> | null) => {
      const values = fundsOptions
        ? fundsOptions.map((option) => option.value)
        : [];
      setFieldValue("funds", values);
    },
    [setFieldValue]
  );

  const fetchBeneficiaryFunds = useCallback(async () => {
    try {
      const { data } = await getBeneficiaryFundWIDAPI(Number(id));
      setFundsBeneficiaryRender(data.results.reverse());
    } catch (err: unknown) {
      console.error(err);
    }
  }, [id, setFundsBeneficiaryRender]);

  const fetchSendFundsBeneficiary = useCallback(
    async (data: IBeneficiaryFundBody) => {
      try {
        await postBeneficiaryFundAPI(data);
      } catch {
        console.log("");
      }
    },
    []
  );

  const payload: IBodyPatchBeneficiary = useMemo(() => {
    return {
      raw_data: {
        status: beneficiaryDetails?.raw_data?.status,
        pending_funds_approvations: true,
      },
    };
  }, [beneficiaryDetails]);

  const fetchPatchBeneficiary = useCallback(
    async (id: number) => {
      try {
        await patchBeneficiaryAPI(id, payload);
      } catch (err: unknown) {
        console.error(err);
      }
    },
    [payload]
  );

  const fetchSendBeneficiaryFund = useCallback(async () => {
    setLoading(true);
    try {
      const fetchFundBeneficiary = (fundId: number) => {
        return fetchSendFundsBeneficiary({
          fund: fundId,
          beneficiary: Number(beneficiaryDetails?.id),
          raw_data: {
            status: "waiting_approval",
          },
        });
      };

      if (values.funds.length > 0) {
        const fetchPromises = values.funds.map((fundId) =>
          fetchFundBeneficiary(Number(fundId))
        );

        await Promise.all(fetchPromises);

        beneficiaryDetails &&
          fetchPatchBeneficiary(Number(beneficiaryDetails.id));

        setAddBeneficiaryFundModal(false);
        notifySuccess();
        fetchBeneficiaryFunds();
        setLoading(false);
      }
      setLoading(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
      notifyError();
    }
  }, [
    beneficiaryDetails?.id,
    fetchSendFundsBeneficiary,
    values,
    setLoading,
    fetchBeneficiaryFunds,
    setAddBeneficiaryFundModal,
  ]);

  const fundsOptions = useMemo(() => {
    return funds
      ?.filter((fund) => fund.raw_data.active === true)
      .map((fund: IFund) => ({
        value: String(fund.id),
        label: ` ${fund?.raw_data?.short_name?.trim()} - ${formatCPFCNPJ(fund?.raw_data?.document_number).trim()}`,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [funds]);

  useEffect(() => {
    fetchFunds();
  }, [fetchFunds, fetchBeneficiaryFunds]);

  return (
    <Modal
      style={{ zIndex: 6 }}
      modalHeading="Associar fundos"
      size="sm"
      open={open}
      primaryButtonDisabled={values.funds.length === 0}
      onRequestClose={() => {
        onClose();
        resetForm();
      }}
      primaryButtonText="Concluir"
      secondaryButtonText="Cancelar"
      onRequestSubmit={() => fetchSendBeneficiaryFund()}
    >
      {" "}
      <Margin mb={20} />
      <h3 style={{ textAlign: "center" }}>Associar um ou mais fundos</h3>
      <Margin mb={80} />
      <Label text="Selecione o(s) Fundo(s)" />
      <Select
        id="funds"
        placeholder={"Selecione um ou mais fundos"}
        name="fund"
        isClearable={true}
        options={fundsOptions}
        isMulti
        onChange={(selectedOption) => handleSelectFund(selectedOption)}
      />
      <Margin mb={20} />
      <Margin mb={20} />
      <Margin mb={110} />
    </Modal>
  );
};

export default AddBeneficiaryFundModal;
