import {
  Table,
  TableBatchAction,
  TableBatchActions,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Grid,
  Column,
  Button,
  Pagination,
  Breadcrumb,
  BreadcrumbItem,
} from "@carbon/react";

import { TrashCan, Add, Save, Download, Renew } from "@carbon/icons-react";
import Margin from "~/components/Margin";

import { useCallback, useEffect, useMemo, useState } from "react";
import useLoading from "~/hooks/useLoading";

// import { useFormik } from "formik";
import Padding from "~/components/Padding";

import useArea from "~/hooks/useArea";

// import NewAreaContentModal from "./NewAreaContentModal";
import { permissions } from "~/services/permissions";
// import DeleteAreaModal from "./DeleteAreaModal";
import { useNavigate } from "react-router-dom";
import { getAttendAreaProfilesAPI } from "~/services/api/attendAreaProfile";

import { getAreaTypesAPI } from "~/services/api/areaTypes";
import useAreaType from "~/hooks/useAreaType";
import { getInternalApprovers } from "~/services/api/profile";
import useProfile from "~/hooks/useProfile";
import { formatCPFCNPJ } from "~/services/utils";
import NewApproverModal from "./NewApprover";

const tableHeadersArea: string[] = ["Nome", "CPF", ""];

const Approvers = () => {
  const { setLoading } = useLoading();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const { setAttendAreaProfiles } = useArea();

  const {
    setInternalApprovers,
    internalApprovers,
    newInternalApprovers,
    setNewInternalApprovers,
  } = useProfile();

  const paginatedInternalApprovers = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return internalApprovers?.slice(start, end);
  }, [currentPage, itemsPerPage, internalApprovers]);

  const { setAreaTypes } = useAreaType();

  const navigate = useNavigate();

  const canReadAreas = permissions.includes("can_read_areas");
  const canCreateAreas = permissions.includes("can_create_areas");

  const canDeleteAreas = permissions.includes("can_delete_areas");

  // const { values } = useFormik({
  //   initialValues: {
  //     search: "",
  //   },
  //   onSubmit: (values: { search: string }) => {
  //     console.log(values);
  //   },
  // });

  const fetchApprovers = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getInternalApprovers();
      setInternalApprovers(data.results.reverse());
      setLoading(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, [setInternalApprovers, setLoading]);

  console.log(internalApprovers);

  const fetchAttendAreaProfiles = useCallback(async () => {
    try {
      const { data } = await getAttendAreaProfilesAPI();
      setAttendAreaProfiles(data.results.reverse());
    } catch (e) {
      console.log(e);
    }
  }, [setAttendAreaProfiles]);

  const fetchAreaTypes = useCallback(async () => {
    try {
      const { data } = await getAreaTypesAPI();

      setAreaTypes(data.results.reverse());
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setAreaTypes]);

  useEffect(() => {
    fetchApprovers();
    fetchAttendAreaProfiles();
    fetchAreaTypes();
  }, [fetchApprovers, fetchAreaTypes, fetchAttendAreaProfiles]);

  return (
    <Grid>
      <Column span={16}>
        <Margin mt={64} />
        <Breadcrumb>
          <BreadcrumbItem onClick={() => navigate("/app/payments/")}>
            Pagamentos
          </BreadcrumbItem>
          <BreadcrumbItem onClick={() => navigate(`/app/approvers/`)}>
            {"Aprovadores"}
          </BreadcrumbItem>
        </Breadcrumb>
        <Margin mt={20} />
        <h1>Aprovadores de pagamentos</h1>
        <Margin mt={20} />
        <TableContainer>
          <Margin mb={20} />
          <TableToolbar>
            {canReadAreas && (
              <Button
                tabIndex={0}
                onClick={() => {
                  fetchApprovers();
                }}
                kind="secondary"
                renderIcon={Renew}
              >
                Atualizar
              </Button>
            )}
            {!canReadAreas && (
              <div
                style={{
                  height: "400px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <h4 style={{ position: "relative", left: "45%" }}>
                  Você não possui permissão para visualizar as áreas
                </h4>
              </div>
            )}
            <TableBatchActions onCancel={console.log} totalSelected={0}>
              <TableBatchAction
                tabIndex={0}
                renderIcon={TrashCan}
                onClick={console.log}
              >
                Delete
              </TableBatchAction>
              <TableBatchAction
                hasIconOnly
                iconDescription="Add"
                tabIndex={0}
                renderIcon={Add}
                onClick={console.log}
              >
                Delete
              </TableBatchAction>
              <TableBatchAction
                hasIconOnly
                iconDescription="Save"
                tabIndex={0}
                renderIcon={Save}
                onClick={console.log}
              >
                Save
              </TableBatchAction>
              <TableBatchAction
                tabIndex={0}
                renderIcon={Download}
                onClick={console.log}
              >
                Download
              </TableBatchAction>
            </TableBatchActions>
            <TableToolbarContent>
              {/* <TableToolbarSearch
                  tabIndex={0}
                  onChange={handleChange}
                  value={values.search}
                  id="search"
                  placeholder="Pesquisar"
                /> */}
              {canCreateAreas && (
                <Button
                  tabIndex={0}
                  onClick={() => {
                    setNewInternalApprovers(true);
                  }}
                  kind="primary"
                >
                  Novo aprovador
                </Button>
              )}
            </TableToolbarContent>
          </TableToolbar>
          {canReadAreas && (
            <Table>
              <TableHead>
                <TableRow>
                  {/* <TableSelectAll
                    name={""}
                    id={""}
                    onSelect={console.log}
                    checked={false}
                    ariaLabel={""}
                  /> */}
                  {tableHeadersArea.map((columnTitle, index) => {
                    return (
                      <TableHeader key={String(index)}>
                        {columnTitle}
                      </TableHeader>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedInternalApprovers?.length === 0 ? (
                  <TableCell colSpan={tableHeadersArea.length + 1}>
                    <Padding pt={24} pb={24} pl={12}>
                      <div
                        style={{
                          height: "300px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <h4>Nenhum aprovador até o momento</h4>
                      </div>
                    </Padding>
                  </TableCell>
                ) : paginatedInternalApprovers.length > 0 ? (
                  paginatedInternalApprovers?.map((internalApprover, index) => (
                    <TableRow key={String(index)} style={{ cursor: "pointer" }}>
                      {/* <TableSelectRow
                        name={""}
                        id={""}
                        onSelect={console.log}
                        checked={false}
                      /> */}
                      <TableCell>{internalApprover.name}</TableCell>

                      <TableCell>
                        {formatCPFCNPJ(internalApprover.document_number)}
                      </TableCell>
                      <TableCell>
                        {/* <Button
                          kind="ghost"
                          hasIconOnly
                          iconDescription="Detalhes"
                          renderIcon={View}
                          id="filter"
                        /> */}

                        {canDeleteAreas && (
                          <Button
                            kind="ghost"
                            hasIconOnly
                            iconDescription="Remover"
                            renderIcon={TrashCan}
                            id="filter"
                            onClick={(e) => {
                              e.stopPropagation();
                              //   setAreaDetails(internalApprover);
                              //   setOpenDeleteAreaModal(true);
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  paginatedInternalApprovers?.map((internalApprover, index) => (
                    <TableRow
                      key={String(index)}
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(`/app/areas/${internalApprover.id}`)
                      }
                    >
                      {/* <TableSelectRow
                        name={""}
                        id={""}
                        onSelect={console.log}
                        checked={false}
                      /> */}
                      <TableCell>{internalApprover.name}</TableCell>

                      <TableCell>
                        {formatCPFCNPJ(internalApprover.document_number)}
                      </TableCell>

                      <TableCell>
                        {/* <Button
                          kind="ghost"
                          hasIconOnly
                          iconDescription="Detalhes"
                          renderIcon={View}
                          id="filter"
                        /> */}

                        {canDeleteAreas && (
                          <Button
                            kind="ghost"
                            hasIconOnly
                            iconDescription="Remover"
                            renderIcon={TrashCan}
                            id="filter"
                            onClick={(e) => {
                              e.stopPropagation();
                              //   setAreaDetails(internalApprover);
                              //   setOpenDeleteAreaModal(true);
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          )}
          <Pagination
            totalItems={internalApprovers?.length}
            pageSize={itemsPerPage}
            pageSizes={[5, 10, 20, 30, 40, 50]}
            onChange={({ page, pageSize }) => {
              setCurrentPage(page);
              setItemsPerPage(pageSize);
            }}
            page={currentPage}
            size="md"
          />
        </TableContainer>
      </Column>
      {/* <NewAreaContentModal
        onClose={() => setOpenNewAreaModal(false)}
        open={openNewAreaModal}
      />
      <EditAreaModal
        onClose={() => setOpenEditAreaModal(false)}
        open={openEditAreaModal}
      />
      <DeleteAreaModal
        onClose={() => setOpenDeleteAreaModal(false)}
        open={openDeleteAreaModal}
      /> */}
      <NewApproverModal
        onClose={() => setNewInternalApprovers(false)}
        open={newInternalApprovers}
      />
    </Grid>
  );
};

export default Approvers;
