import { Modal } from "@carbon/react";
import { SingleValue } from "react-select";
import Margin from "~/components/Margin";
import { useCallback, useEffect, useMemo, useState } from "react";
import useLoading from "~/hooks/useLoading";
import useBeneficiary from "~/hooks/useBeneficiary";
import { toast } from "react-toastify";
import useUser from "~/hooks/useUser";
import Select from "react-select";
import { useFormik } from "formik";
import {
  getBeneficiaryAPI,
  IBodyBeneficiary,
  patchBeneficiaryAPI,
} from "~/services/api/beneficiaries";
import { useParams } from "react-router-dom";
import MaskedTextInput from "~/components/InputMask";
import { IkeypixType, postCheckpixAPI } from "~/services/api/checkpix";
import { Loading } from "prosperita-dumbo-react";
import { formatCPFCNPJ, onlyNumbers } from "~/services/utils";

type SelectOption = {
  value: string;
  label: string;
};

type MaskTypeOptions = {
  [key: string]: string;
};

const MaskIndex: MaskTypeOptions = {
  cnpj: "99.999.999/9999-99",
  cpf: "999.999.999-99",
  celphone: "(99)99999-9999",
};
type IBankAccountInfoPix = {
  account_number: string;
  account_number_digit: string;
  account_type: string;
  agency: string;
  bank: string;
  bank_name: string;
  document_number: string;
  key: string;
  key_type: string;
  message: string;
  name: string;
};

export const AddModalPixkey = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();
  const { userProfile } = useUser();
  const { id } = useParams();
  const [isvalidPixkey, setisvalidPixkey] = useState<IBankAccountInfoPix>({
    account_number: "",
    account_number_digit: "",
    account_type: "",
    agency: "",
    bank: "",
    bank_name: "",
    key: "",
    key_type: "",
    document_number: "",
    message: "",
    name: "",
  });
  type InitialValues = {
    cnpj: string;
    cpf: string;
    celphone: string;
    evp: string;
    email: string;
    type: string;
    bank: string;
  };

  const initialValues: InitialValues = {
    cnpj: "",
    cpf: "",
    celphone: "",
    evp: "",
    email: "",
    type: "",
    bank: "",
  };
  const [stateValuesPix, setstateValuesPix] = useState<SelectOption[]>([]);
  const [step, setStep] = useState("initial");
  const { setBeneficiaryDetails, beneficiaryDetails, setaddkeyPixModal } =
    useBeneficiary();
  const [stepLoading, setStepLoading] = useState(false);
  const [pagekeyPixError, setpagekeyPixError] = useState<boolean>(false);

  const notifySuccess = () => toast.success("Chave adicionada com sucesso!");
  const notifyError = () => toast.error("Não foi possivel adicionar a Chave");
  const notifyErrorkeyPix = () => toast.error("Erro ao tentar encotrar chave");

  const OptionsKey = useMemo(() => {
    return [
      { label: "CNPJ", value: "cnpj" },
      { label: "CPF", value: "cpf" },
      { label: "Celular", value: "celphone" },
      { label: "Email", value: "email" },
      { label: "Aleatória", value: "evp" },
    ] as const;
  }, []);

  const {
    values,
    // handleChange,
    setFieldValue,
    // errors,
    // touched,
    // handleSubmit,
    resetForm,
  } = useFormik({
    initialValues,
    onSubmit: () => { },
  });
  const filteredValuesKey = OptionsKey.map(
    (option) => values[option.value]
  ).filter((value) => value && value.trim() !== "");

  const payload_key = useMemo(() => {
    return {
      key:
        values.type === "email" || values.type === "evp"
          ? filteredValuesKey[0]
          : onlyNumbers(filteredValuesKey[0]),
      type: values.type,
      bank: isvalidPixkey.bank,
      bank_name: isvalidPixkey.bank_name,
      name: isvalidPixkey.name,
    };
  }, [values.type, filteredValuesKey[0], isvalidPixkey]);

  const payload: IBodyBeneficiary = useMemo(() => {
    const currentPixKeys = beneficiaryDetails?.pix_keys || [];

    const existingIds = currentPixKeys.map(
      (account) => Number(account.id) || 1
    );
    const nextId = Math.max(...existingIds, 0) + 1;

    return {
      name: beneficiaryDetails?.name || "",
      document_number: beneficiaryDetails?.document_number || "",
      bank_account: beneficiaryDetails?.bank_account || [],
      pix_keys: [
        ...currentPixKeys,
        {
          id: nextId,
          key: payload_key.key,
          type: payload_key.type,
          bank: payload_key.bank,
          bank_name: payload_key.bank_name,
          name: payload_key.name,
        },
      ],
      profile: userProfile?.id || Number(""),
    };
  }, [values, beneficiaryDetails, userProfile, isvalidPixkey, filteredValuesKey]);



  const fetchPixkeyvalidation = useCallback(async () => {

    setStepLoading(true);
    resetForm()
    try {
      const response = await postCheckpixAPI(payload_key as IkeypixType);

      const data = response.data as IBankAccountInfoPix

      setisvalidPixkey(data as IBankAccountInfoPix);

      const documentNumberInput = onlyNumbers(beneficiaryDetails?.document_number || '');

      const documentNumberKeyPix = onlyNumbers(data?.document_number || "");

      if (documentNumberInput !== documentNumberKeyPix) {
        toast.error("A chave Pix adicionada não corresponde ao documento cadastrado.");
      } else {
        toast.success("Chave Pix validada e os dados cadastrados conferem.");
      }
      console.log(data, "response");
      setpagekeyPixError(false);
      setStepLoading(false);
    } catch {
      setStepLoading(false);
      setpagekeyPixError(true);
      notifyErrorkeyPix();
      console.log("erro ao validar");
    }
  }, [payload_key, values.type, filteredValuesKey, setisvalidPixkey]);



  const fetchBeneficiary = useCallback(async () => {

    try {
      const { data } = await getBeneficiaryAPI(Number(id));
      setBeneficiaryDetails(data);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [id, setBeneficiaryDetails]);

  const handleChangeSelect = (e: SingleValue<SelectOption>) => {
    if (e) {
      setstateValuesPix([e]);
      setFieldValue("type", e.value);
    }
  };

  const res = isvalidPixkey.account_number !== "" && onlyNumbers(isvalidPixkey.document_number) !== onlyNumbers(String(beneficiaryDetails?.document_number))



  const fetchPatchBeneficiary = useCallback(
    async (id: number) => {
      setLoading(true);
      try {
        await patchBeneficiaryAPI(id, payload);
        await fetchBeneficiary();
        setaddkeyPixModal(false);
        setLoading(false);
        notifySuccess();
      } catch (err: unknown) {
        console.error(err);
        setLoading(false);

        notifyError();
      }
    },
    [payload, fetchBeneficiary]
  );


  useEffect(() => {
    fetchBeneficiary();
  }, []);


  return (
    <Modal
      style={{ zIndex: 6 }}
      modalHeading={stepLoading ? "Carregando..." : "Informaçoes da Chave Pix"}
      size="md"
      open={open}
      onRequestClose={() => {
        if (step === "initial") {
          onClose();
        } else if (step === "hasKey") {
          setpagekeyPixError(false)
          setisvalidPixkey({
            account_number: "",
            account_number_digit: "",
            account_type: "",
            agency: "",
            bank: "",
            bank_name: "",
            key: "",
            key_type: "",
            document_number: "",
            message: "",
            name: "",
          })
          setStep("initial");
        }
      }}

      primaryButtonText={step === "hasKey" ? "Concluir" : "Avançar"}
      secondaryButtonText={step === "hasKey" ? "Voltar" : "Cancelar"}

      primaryButtonDisabled={res || isvalidPixkey.account_number === "" && pagekeyPixError}

      onRequestSubmit={async () => {
        if (step === "initial") {
          await fetchPixkeyvalidation();
          setStep("hasKey");
        } else if (step === "hasKey") {
          if (pagekeyPixError) {
            notifyError();
          } else {
            await fetchPatchBeneficiary(Number(id));
          }
        }
      }}
    >
      <>
        {stepLoading && (
          <>
            <div
              style={{
                position: "absolute",
                transform: "translateY(80%) translateX(50%)",
                right: "50%",
              }}
            >
              <Loading colorSpinner="#0F62FE" />
            </div>
            <Margin mb={250} />
          </>
        )}

        {step === "initial" && !stepLoading && (
          <>
            <Margin mt={10} />
            <h4 style={{ margin: "0 auto", textAlign: "center" }}>
              Cadastro De Chave Pix
            </h4>
            <Margin mb={10} />
            <Select
              placeholder={"Tipo de Chave pix..."}
              options={OptionsKey}
              onChange={(ValuesChange) => {
                handleChangeSelect(ValuesChange);
                OptionsKey.forEach((item) => {
                  setFieldValue(item.value, "");
                });
              }}
            />

            <Margin mb={10} />
            {stateValuesPix.map((keysPix) => (
              <MaskedTextInput
                key={keysPix.value}
                mask={MaskIndex[values.type]}
                id={keysPix.value}
                name={keysPix.value}
                placeholder={`Digite Sua Chave Pix ${keysPix.label}`}
                onChange={(e) => setFieldValue(keysPix.value, e.target.value)}
              />
            ))}
            <Margin mb={150} />
          </>
        )}

        {step === "hasKey" && !pagekeyPixError && (
          <>
            <div>
              <Margin mt={10} />
              <h4
                style={{
                  margin: "0 auto",
                  textAlign: "center",
                  fontWeight: "bolder",
                }}
              >
                Informaçoes da Chave
              </h4>
              <Margin mt={20} />
              <div style={{ display: "block" }}>
                <h4>
                  <b style={{ fontWeight: "bold" }}> Nome:</b>{" "}
                  <span>{isvalidPixkey.name}</span>
                </h4>
                <h4>
                  <b style={{ fontWeight: "bold" }}>CPF/CNPJ:</b>
                  <span> {formatCPFCNPJ(isvalidPixkey?.document_number)}</span>
                </h4>

                <h4>
                  <b style={{ fontWeight: "bold" }}> Banco: </b>
                  <span>
                    {isvalidPixkey.bank} - {isvalidPixkey.bank_name}
                  </span>
                </h4>
                <h4>
                  <b style={{ fontWeight: "bold" }}>Agencia: </b>{" "}
                  <span>{isvalidPixkey.agency}</span>
                </h4>
                <h4>
                  <b style={{ fontWeight: "bold" }}>Conta: </b>
                  <span>
                    {isvalidPixkey.account_number}-
                    {isvalidPixkey.account_number_digit}
                  </span>
                </h4>
              </div>
            </div>
          </>
        )}
        {step === "hasKey" && pagekeyPixError && (
          <div style={{ textAlign: "center" }}>
            <Margin mb={90} />
            <h3>Chave PIX não encontrada</h3>
            <Margin mb={100} />
          </div>
        )}
        <Margin mb={20} />
      </>
    </Modal >
  );
};
