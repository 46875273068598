import constate from "constate";
import { useState } from "react";
import { IAttendPayment } from "~/types";

type ITypePayment = {
  value: string;
  label: string;
};

const [PaymentProvider, usePayment] = constate(() => {
  const [typePayments, setTypePayments] = useState<ITypePayment[]>();
  const [payments, setPayments] = useState<IAttendPayment[]>([]);
  const [paymentsDetails, setPaymentsDetails] = useState<IAttendPayment>();

  return {
    typePayments,
    setTypePayments,
    payments,
    setPayments,
    paymentsDetails,
    setPaymentsDetails,
  };
});

export { PaymentProvider };

export default usePayment;
