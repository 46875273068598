import constate from "constate";
import { useState } from "react";
import { ITypeBanks } from "~/types";

const [BanksProvider, useBanks] = constate(() => {
  const [banks, setBanks] = useState<ITypeBanks[]>([]);
  return {
    setBanks,
    banks,
  };
});

export { BanksProvider };

export default useBanks;
