import { Modal } from "@carbon/react";

import Margin from "~/components/Margin";
import { useCallback } from "react";
import useLoading from "~/hooks/useLoading";
import { toast } from "react-toastify";
import useCompanyType from "~/hooks/useCompanyType";
import {
  deleteCompanyTypeAPI,
  getCompanyTypesAPI,
} from "~/services/api/company-types";

const notifySuccess = () =>
  toast.success("Tipo de empresa excluída com sucesso!");
const notifyError = () =>
  toast.error(
    "O tipo de empresa não pode ser excluído. Tente novamente mais tarde."
  );

const DeleteCompanyTypeModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();
  const { companyTypeDetails, setCompanyTypes, setOpenDeleteCompanyTypeModal } =
    useCompanyType();

  const fetchCompanyTypes = useCallback(async () => {
    try {
      const { data } = await getCompanyTypesAPI();
      setCompanyTypes(data.results.reverse());
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setCompanyTypes]);

  const fetchDeleteCompanyType = useCallback(async (id?: number) => {
    setLoading(true);
    try {
      await deleteCompanyTypeAPI(String(id));
      setOpenDeleteCompanyTypeModal(false);
      await fetchCompanyTypes();
      setLoading(false);
      notifySuccess();
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
      notifyError();
    }
  }, []);

  return (
    <Modal
      modalHeading="Excluir tipo de empresa"
      size="sm"
      open={open}
      onRequestClose={onClose}
      primaryButtonText="Sim, excluir"
      secondaryButtonText="Cancelar"
      onRequestSubmit={async () => {
        fetchDeleteCompanyType(companyTypeDetails?.id);
      }}
    >
      <Margin mb={24} />
      <h3>
        Deseja realmente excluir o tipo de empresa {companyTypeDetails?.name} ?
      </h3>
      <Margin mb={54} />
    </Modal>
  );
};

export default DeleteCompanyTypeModal;
