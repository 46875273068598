import { Modal, TextInput, Toggle } from "@carbon/react";
import { useFormik } from "formik";
import Margin from "~/components/Margin";
import {
  getAreasAPI,
  ISendNewAreaBody,
  patchAreaAPI,
} from "~/services/api/areas";
import * as Yup from "yup";
import { useCallback, useEffect, useMemo } from "react";
import useLoading from "~/hooks/useLoading";
import useArea from "~/hooks/useArea";
import { toast } from "react-toastify";

type Values = {
  name: string;
  active: boolean;
};

const initialValues: Values = {
  name: "",
  active: false,
};

const validationSchema = Yup.object({
  name: Yup.string().required("Nome da tarefa é obrigatório"),
});

const EditAreaModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();

  const { setAreas, areaDetails, setOpenEditAreaModal } = useArea();

  const notifySuccess = () =>
    toast.success("Nome da área atualizado com sucesso!");
  const notifyError = () =>
    toast.error(
      "O nome da área não pode ser atualizado. Tente novamente mais tarde."
    );

  const {
    handleSubmit,

    resetForm,

    values,
    handleBlur,
    setFieldValue,
    handleChange,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const handleToggle = () => {
    setFieldValue("active", !values.active);
  };

  const sendNewTaskPayload: ISendNewAreaBody = useMemo(() => {
    return {
      name: values.name,
      active: values.active,
    };
  }, [values]);

  const fetchAreas = useCallback(async () => {
    try {
      const { data } = await getAreasAPI();
      setAreas(data.results.reverse());
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setAreas]);

  const fetchSendNewArea = async (payload: ISendNewAreaBody) => {
    setLoading(true);

    try {
      if (areaDetails) {
        await patchAreaAPI(payload, String(areaDetails?.id));

        resetForm();
        await fetchAreas();
        setLoading(false);
        setOpenEditAreaModal(false);
        notifySuccess();
      }
    } catch (error) {
      console.error("Erro ao enviar Area:", error);
      setLoading(false);
      notifyError();
    }
  };

  useEffect(() => {
    if (areaDetails) {
      setFieldValue("name", areaDetails.name);
      setFieldValue("active", areaDetails.active);
    }
  }, [areaDetails, setFieldValue]);

  return (
    <form onSubmit={handleSubmit}>
      <Modal
        modalHeading={` ${areaDetails?.name} - Editar`}
        size="sm"
        open={open}
        primaryButtonDisabled={values.name === ""}
        onRequestClose={onClose}
        primaryButtonText="Concluir"
        secondaryButtonText="Cancelar"
        onRequestSubmit={async () => {
          fetchSendNewArea(sendNewTaskPayload);
          onClose();
        }}
      >
        <TextInput
          id="name"
          name="name"
          labelText="Nome da área"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
        />
        <Margin mb={13} />
        <Toggle
          id="toggle-1"
          labelText="Status?"
          onToggle={handleToggle}
          toggled={values.active}
          labelA="Inativa"
          labelB="Ativa"
        />

        <Margin mb={24} />

        <Margin mb={24} />
        <Margin mb={24} />
      </Modal>
    </form>
  );
};

export default EditAreaModal;
