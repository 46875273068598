import ApiService from "../api";

// const params = "key,type";

export type IkeypixType = {
  key: string;
  type: string;
};

export const postCheckpixAPI = (data: IkeypixType) => {
  return ApiService.HttpPost({
    route: "payment/check-pix/",
    token: true,
    body: data,
  });
};
