import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  Grid,
  SideNav,
  SideNavItems,
  SideNavLink,
  Stack,
} from "@carbon/react";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";
import Margin from "~/components/Margin";
import Padding from "~/components/Padding";
import useProfile from "~/hooks/useProfile";
import { permissions } from "~/services/permissions";

const fadeIn = keyframes`
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  `;

const Container = styled.div<{ isVisible: boolean }>`
  ${({ isVisible }) =>
    isVisible
      ? css`
          opacity: 1;
          animation: ${fadeIn} 0.3s ease-in-out;
        `
      : css`
          opacity: 0;
          height: 0;
          overflow: hidden;
        `}
`;

const TicketFormContainer = styled.div`
  position: relative;

  .form-sidenav {
    position: relative;
    background: #f4f4f4;
  }

  .form {
    background: #f4f4f4;
    min-height: 50vh;

    .action-button {
      width: 100%;
    }
  }
`;

const NewBeneficiaries = () => {
  const navigate = useNavigate();

  const { selectedItem, setSelectedItem } = useProfile();
  const canCreateProfiles = permissions.includes("can_create_profiles");

  useEffect(() => {
    setSelectedItem("");
  }, [setSelectedItem]);

  return (
    <TicketFormContainer>
      {canCreateProfiles ? (
        <Grid>
          <Column span={16}>
            <Padding y={24}>
              <Margin mt={30} />
              <Breadcrumb>
                <BreadcrumbItem onClick={() => navigate("/app/beneficiaries/")}>
                  Beneficiários
                </BreadcrumbItem>
                <BreadcrumbItem
                  onClick={() => navigate("/app/beneficiaries/new/general/")}
                >
                  Novo Beneficiário
                </BreadcrumbItem>
              </Breadcrumb>
            </Padding>
            <h1>Novo beneficiário</h1>
            <Margin mb={12} />
            <Grid className="form">
              <Column span={4}>
                <SideNav
                  style={{ zIndex: 0 }}
                  aria-label="Side navigation"
                  className="form-sidenav"
                >
                  <SideNavItems>
                    <SideNavLink href="#" isActive>
                      {"Geral"}
                    </SideNavLink>
                    <Margin mb={10} />
                    {selectedItem === "client" && (
                      <SideNavLink href="#" isActive>
                        Dados da Empresa
                      </SideNavLink>
                    )}
                  </SideNavItems>
                </SideNav>
              </Column>

              <Column span={12}>
                <Grid>
                  <Column span={12}>
                    <Padding y={12}></Padding>
                  </Column>
                  <Column span={6}>
                    <Stack gap={5}>
                      <Outlet />

                      <Container isVisible={false}>
                        <Column span={12}>
                          <Margin mb={64} />
                          <Grid>
                            <Column span={6}>
                              <Button
                                kind="tertiary"
                                className="action-button"
                                onClick={() => navigate("/app/beneficiaries/")}
                              >
                                Cancelar
                              </Button>
                            </Column>
                            <Column span={6}>
                              {/* <Button
                                  kind="primary"
                                  className="action-button"
                                  onClick={() => handleShowToast()}
                                >
                                  Avançar
                                </Button> */}
                            </Column>
                          </Grid>
                        </Column>
                      </Container>
                    </Stack>
                  </Column>
                  <Column span={6} />
                </Grid>
                <Margin mb={64} />
              </Column>
            </Grid>
            <Margin mb={64} />
          </Column>
        </Grid>
      ) : (
        <div
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {" "}
          <h4> Você não possui permissão para criar usuários</h4>
        </div>
      )}
    </TicketFormContainer>
  );
};

export default NewBeneficiaries;
