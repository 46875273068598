import React, { useState } from "react";
import {
  Modal,
  TextInput,
  Select,
  SelectItem,
  DatePicker,
  DatePickerInput,
  Checkbox,
} from "@carbon/react";
import Margin from "~/components/Margin";

const FilterModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [filters, setFilters] = useState({
    status: "",
    internals: "",
    areaManagers: "",
    areas: "",
    watchers: "",
    subject: "",
    message: "",
    createdOn: "",
    updatedOn: "",
  });

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = e && e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };
  const handleDateChange = (name: string, value: string) => {
    setFilters({
      ...filters,
      [name]: value,
    });
  };
  return (
    <Modal
      modalHeading="Filtros"
      size="sm"
      open={open}
      onRequestClose={onClose}
      primaryButtonText="Aplicar Filtros"
      secondaryButtonText="Cancelar"
      onRequestSubmit={() => console.log(filters)}
    >
      <TextInput
        id="subject"
        name="subject"
        labelText="Nome"
        onChange={handleInputChange}
      />
      <Margin mb={24} />
      <Select
        id="area"
        name="area"
        labelText="Área"
        onChange={handleInputChange}
      >
        <SelectItem text="Selecione" value="0" />
        <SelectItem text="Área 1" value="1" />
        <SelectItem text="Área 2" value="2" />
        <SelectItem text="Área 3" value="3" />
      </Select>
      <Margin mb={24} />
      <div style={{ display: "flex" }}>
        {" "}
        <DatePicker dateFormat="m/d/Y" datePickerType="single">
          <DatePickerInput
            id="createdOn"
            placeholder="mm/dd/yyyy"
            labelText="Data de criação"
            onChange={(e) => handleDateChange("createdOn", e.target.value)}
          />
        </DatePicker>
        <DatePicker dateFormat="m/d/Y" datePickerType="single">
          <DatePickerInput
            id="updatedOn"
            placeholder="mm/dd/yyyy"
            labelText="Data de atualização"
            onChange={(e) => handleDateChange("updatedOn", e.target.value)}
          />
        </DatePicker>
      </div>
      <Margin mb={24} />
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ width: "50%" }}>
          <Checkbox
            id="client"
            name="client"
            labelText="Solicitante"
            onChange={handleInputChange}
          />
          <Checkbox
            id="area_manager"
            name="area_manager"
            labelText="Gestor de Área"
            onChange={handleInputChange}
          />
          <Checkbox
            id="internal"
            name="internal"
            labelText="Atendente"
            onChange={handleInputChange}
          />
        </div>
        <Margin ml={24} />
      </div>
    </Modal>
  );
};

export default FilterModal;
