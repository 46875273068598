export const permissions: string[] = [
  "can_read_companys",
  "can_create_companys",
  "can_delete_companys",
  "can_edit_companys",
  // Permissões para attends
  "can_read_attends",
  "can_create_attends",
  "can_delete_attends",
  "can_edit_attends",
  // Permissões para tasks
  "can_read_tasks",
  "can_create_tasks",
  "can_delete_tasks",
  "can_edit_tasks",
  // Permissões para profiles
  "can_read_profiles",
  "can_create_profiles",
  "can_delete_profiles",
  "can_edit_profiles",
  // Permissões para areas
  "can_read_areas",
  "can_create_areas",
  "can_delete_areas",
  "can_edit_areas",
  // Permissões para funds
  "can_read_funds",
  "can_create_funds",
  "can_delete_funds",
  "can_edit_funds",
  // Permissões para attendTypes
  "can_read_attend_types",
  "can_create_attend_types",
  "can_delete_attend_types",
  "can_edit_attend_types",
  "can_read_history",
];

const permissionsToolManager = [
  "can_read_companys",
  "can_create_companys",
  "can_delete_companys",
  "can_edit_companys",
  // Permissões para attends
  "can_read_attends",
  "can_create_attends",
  "can_delete_attends",
  "can_edit_attends",
  // Permissões para tasks
  "can_read_tasks",
  "can_create_tasks",
  "can_delete_tasks",
  "can_edit_tasks",
  // Permissões para profiles
  "can_read_profiles",
  "can_create_profiles",
  "can_delete_profiles",
  "can_edit_profiles",
  // Permissões para areas
  "can_read_areas",
  "can_create_areas",
  "can_delete_areas",
  "can_edit_areas",
  // Permissões para funds
  "can_read_funds",
  "can_create_funds",
  "can_delete_funds",
  "can_edit_funds",
  // Permissões para attendTypes
  "can_read_attend_types",
  "can_create_attend_types",
  "can_delete_attend_types",
  "can_edit_attend_types",
  "can_read_history",
];

const permissionsAreaManager = [
  "can_read_companys",
  "can_read_attends",
  "can_create_attends",
  "can_read_tasks",
  "can_create_tasks",
  "can_delete_tasks",
  "can_edit_tasks",
  "can_read_profiles",
  "can_read_areas",
  "can_read_funds",
  "can_read_attend_types",
  "can_read_history",
];

const permissionsInternal = [
  "can_read_companys",
  "can_read_attends",
  "can_create_attends",
  "can_read_tasks",
  "can_create_tasks",
  "can_delete_tasks",
  "can_edit_tasks",
  "can_read_profiles",
  "can_read_areas",
  "can_read_funds",
  "can_read_attend_types",
  "can_read_history",
];

const permissionsClient: string[] = [
  "can_read_companys",
  "can_read_attends",
  "can_create_attends",
  "can_read_funds",
  "can_read_attend_types",
  "can_read_areas",
  "can_read_profiles",
];

console.log(permissionsClient);

const permissionsManager = [
  "can_read_companys",
  // Permissões para attends
  "can_read_attends",
  "can_create_attends",
  // Permissões para profiles
  "can_read_profiles",
  "can_create_profiles",
  // Permissões para areas
  "can_read_areas",
  // Permissões para funds
  "can_read_funds",
];

console.log(
  permissionsAreaManager,
  permissionsClient,
  permissionsInternal,
  permissionsManager,
  permissionsToolManager
);
