import { Modal } from "@carbon/react";

import Margin from "~/components/Margin";
import { useCallback } from "react";
import useLoading from "~/hooks/useLoading";
import { toast } from "react-toastify";
import { formatCPFCNPJ } from "~/services/utils";
import useFund from "~/hooks/useFund";
import { deleteFundsAPI, getFundsAPI } from "~/services/api/fund";

const DeleteFundModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();
  const { fundDetails, setOpenDeleteFundModal, setFunds } = useFund();

  const notifySuccess = () => toast.success("Fundo removido com sucesso!");
  const notifyError = () =>
    toast.error(
      "Não foi possível remover o fundo. Tente novamente mais tarde."
    );

  const fetchFunds = useCallback(async () => {
    try {
      const { data } = await getFundsAPI();
      setFunds(data.results.reverse());
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setFunds]);

  const fetchDeleteFund = useCallback(
    async (id: number) => {
      setLoading(true);
      try {
        await deleteFundsAPI(String(id));
        await fetchFunds();
        setOpenDeleteFundModal(false);
        setLoading(false);
        notifySuccess();
      } catch (err: unknown) {
        console.error(err);
        setLoading(false);
        notifyError();
      }
    },
    [setLoading, fetchFunds, setOpenDeleteFundModal]
  );

  return (
    <>
      <Modal
        style={{ zIndex: 6 }}
        modalHeading="Remover fundo"
        size="sm"
        open={open}
        onRequestClose={onClose}
        primaryButtonText="Sim, remover"
        secondaryButtonText="Cancelar"
        onRequestSubmit={async () => {
          fundDetails?.id && fetchDeleteFund(fundDetails?.id);
        }}
      >
        <Margin mt={20} />
        <h3 style={{ textAlign: "center" }}>
          Deseja realmente remover o fundo abaixo ?
        </h3>
        <Margin mb={40} />
        <h4>
          {" "}
          <span style={{ fontWeight: "bold" }}>Nome: </span> {fundDetails?.name}
        </h4>
        <h4>
          {" "}
          <span style={{ fontWeight: "bold" }}>Nome Abreviado: </span>{" "}
          {fundDetails?.raw_data?.short_name}
        </h4>
        <h4>
          {" "}
          <span style={{ fontWeight: "bold" }}>CNPJ: </span>{" "}
          {fundDetails?.raw_data?.document_number &&
            formatCPFCNPJ(fundDetails?.raw_data?.document_number)}
        </h4>

        <Margin mb={100} />
      </Modal>
    </>
  );
};

export default DeleteFundModal;
