import { HTMLProps, ReactNode } from "react";

import styled from "styled-components";

type SpacingProps = {
  mt?: number;
  mb?: number;
  ml?: number;
  mr?: number;
  x?: number;
  y?: number;
  all?: number;
  children?: ReactNode;
};

type MarginProps = HTMLProps<HTMLDivElement> & SpacingProps;

type Directions = "top" | "bottom" | "left" | "right";

const getMargin = (direction: Directions) => (props: SpacingProps) => {
  switch (direction) {
    case "top":
      if (props.all) return props.all;
      if (props.y) return props.y;

      return props.mt;
    case "bottom":
      if (props.all) return props.all;
      if (props.y) return props.y;
      return props.mb;
    case "left":
      if (props.all) return props.all;
      if (props.x) return props.x;

      return props.ml;
    case "right":
      if (props.all) return props.all;
      if (props.x) return props.x;

      return props.mr;
    default:
      return 0;
  }
};

const PaddingContainer = styled.div<MarginProps>`
  margin-top: ${getMargin("top")}px;
  margin-bottom: ${getMargin("bottom")}px;
  margin-left: ${getMargin("left")}px;
  margin-right: ${getMargin("right")}px;
`;

const Margin = ({ children, ...props }: MarginProps) => {
  return <PaddingContainer {...props}>{children}</PaddingContainer>;
};

export default Margin;
