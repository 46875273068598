import ApiService from "../api";

export type IBillettype = {
    amount: number;
    bank_code: string;
    bank_name: string;
    barcode: string;
    beneficiary_document_number: string;
    beneficiary_name: string;
    concessionaire_code: string | null;
    concessionaire_name: string | null;
    digitable_line: string;
    discount_value: number;
    due_date: string;
    fine_value: number;
    has_cip: boolean;
    interest_value: number;
    nominal_amount: number;
    original_amount: number;
    payer_document_number: string;
    payer_name: string;
    payment_date: string;
    payment_was_made: boolean;
    updated_amount: number;
};

export const postCheckBilletAPI = (data: { digitable_line: string }) => {
    return ApiService.HttpPost({
        route: "payment/check-billet/",
        token: true,
        body: data,
    });
};
