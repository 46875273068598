import { Modal, TextInput } from "@carbon/react";
import { useFormik } from "formik";
import Margin from "~/components/Margin";
import {
  getAreasAPI,
  ISendNewAreaBody,
  postAreaAPI,
} from "~/services/api/areas";
import * as Yup from "yup";
import { useCallback, useMemo } from "react";
import useLoading from "~/hooks/useLoading";
import useArea from "~/hooks/useArea";
import { toast } from "react-toastify";

const notifySuccess = () => toast.success(" Área criada com sucesso!");
const notifyError = () =>
  toast.error("A área não pode ser criada. Tente novamente mais tarde.");

type Values = {
  name: string;
};

const initialValues: Values = {
  name: "",
};

const validationSchema = Yup.object({
  name: Yup.string().required("Nome da tarefa é obrigatório"),
});

const NewAreaContentModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();

  const { setAreas } = useArea();

  const {
    handleSubmit,

    resetForm,

    values,
    handleBlur,
    handleChange,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const sendNewTaskPayload: ISendNewAreaBody = useMemo(() => {
    return {
      name: values.name,
    };
  }, [values]);

  const fetchAreas = useCallback(async () => {
    try {
      const { data } = await getAreasAPI();
      setAreas(data.results.reverse());
    } catch (err: unknown) {
      console.error(err);
    }
  }, []);

  const fetchSendNewArea = async (payload: ISendNewAreaBody) => {
    setLoading(true);
    try {
      await postAreaAPI(payload);

      resetForm();
      await fetchAreas();
      setLoading(false);
      notifySuccess();
    } catch (error) {
      console.error("Erro ao enviar Area:", error);
      setLoading(false);
      notifyError();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Modal
        modalHeading="Nova área"
        size="sm"
        open={open}
        onRequestClose={onClose}
        primaryButtonDisabled={values.name === ""}
        primaryButtonText="Concluir"
        secondaryButtonText="Cancelar"
        onRequestSubmit={async () => {
          fetchSendNewArea(sendNewTaskPayload);
          onClose();
        }}
      >
        <TextInput
          id="name"
          name="name"
          labelText="Nome da área"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
        />

        <Margin mb={24} />
      </Modal>
    </form>
  );
};

export default NewAreaContentModal;
