import { Modal, Toggle } from "@carbon/react";

import Margin from "~/components/Margin";
import { useCallback, useEffect, useState } from "react";
import useLoading from "~/hooks/useLoading";

import { getProfilesAPI, patchProfileAPI } from "~/services/api/profile";
import useProfile from "~/hooks/useProfile";
import { toast } from "react-toastify";
import { formatCPFCNPJ } from "~/services/utils";

const EditProfileModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();
  const { detailsProfile, setOpenEditProfileModal } = useProfile();
  const { setProfiles } = useProfile();
  const [toolManager, setToolManager] = useState(false);
  const [areaManager, setAreaManager] = useState(false);
  const [internal, setInternal] = useState(false);

  const notifySuccess = () => toast.success("Perfis atualizados com sucesso!");
  const notifyError = () =>
    toast.error(
      "Não foi possível atualizar os perfis. Tente novamente mais tarde."
    );

  const fetchProfiles = useCallback(async () => {
    try {
      const { data } = await getProfilesAPI({ mode: "list" });
      setProfiles(data.results.reverse());
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setProfiles]);

  const fetchDeleteProfile = useCallback(
    async (id: number) => {
      setLoading(true);
      const types = [];

      if (toolManager) {
        types.push("tool_manager");
      }

      if (areaManager) {
        types.push("area_manager");
      }
      if (internal) {
        types.push("internal");
      }

      try {
        await patchProfileAPI(
          {
            type: types,
          },
          String(id)
        );
        await fetchProfiles();
        setOpenEditProfileModal(false);
        setLoading(false);
        notifySuccess();
      } catch (err: unknown) {
        console.error(err);
        setLoading(false);
        notifyError();
      }
    },
    [
      setLoading,
      internal,
      areaManager,
      toolManager,
      fetchProfiles,
      setOpenEditProfileModal,
    ]
  );

  useEffect(() => {
    fetchProfiles();
  }, []);

  useEffect(() => {
    if (detailsProfile) {
      setToolManager(detailsProfile.type.includes("tool_manager"));
      setAreaManager(detailsProfile.type.includes("area_manager"));
      setInternal(detailsProfile.type.includes("internal"));
    }
  }, [detailsProfile]);

  return (
    <Modal
      style={{ zIndex: 6 }}
      modalHeading="Editar Perfis"
      size="sm"
      primaryButtonDisabled={!areaManager && !toolManager && !internal}
      open={open}
      onRequestClose={onClose}
      primaryButtonText="Salvar alterações"
      secondaryButtonText="Cancelar"
      onRequestSubmit={async () => {
        detailsProfile?.id && fetchDeleteProfile(detailsProfile?.id);
      }}
    >
      <Margin mt={20} />
      <h3 style={{ textAlign: "center" }}>Perfis do usuário</h3>
      <Margin mb={20} />
      <h4>
        {" "}
        <span style={{ fontWeight: "bold" }}>Nome: </span>{" "}
        {detailsProfile?.name}
      </h4>
      <h4>
        {" "}
        <span style={{ fontWeight: "bold" }}>CPF: </span>{" "}
        {detailsProfile?.document_number &&
          formatCPFCNPJ(detailsProfile?.document_number)}
      </h4>

      {/* Toggle for Gerente de ferramenta */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "50%",
          marginTop: "25px",
        }}
      >
        <p>Gestor de ferramenta</p>
        <Toggle
          id="toggle-1"
          labelA="Não"
          labelB="Sim"
          toggled={toolManager}
          onToggle={(value) => setToolManager(value)}
        />
      </div>

      {/* Toggle for Gerente de Área */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "50%",
          marginTop: "5px",
        }}
      >
        <p>Gestor de Área</p>
        <Toggle
          id="toggle-2"
          labelA="Não"
          labelB="Sim"
          toggled={areaManager}
          onToggle={(value) => setAreaManager(value)}
        />
      </div>

      {/* Toggle for Atendente */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "50%",
          marginTop: "5px",
        }}
      >
        <p>Atendente</p>
        <Toggle
          id="toggle-3"
          labelA="Não"
          labelB="Sim"
          toggled={internal}
          onToggle={(value) => setInternal(value)}
        />
      </div>

      <Margin mb={100} />
    </Modal>
  );
};

export default EditProfileModal;
