import { IAttendTask } from "~/types";
import ApiService from "../api";

type IResponseGetAttendTasks = {
  count: number;
  next: null;
  previous: null;
  results: IAttendTask[];
};

export const getAttendTaskAPI = () => {
  return ApiService.HttpGet<IResponseGetAttendTasks>({
    route: "attend/attend-task/",
    token: true,
    params: {
      expand: ["attend,status"],
    },
  });
};

export type ISendNewAttendTaskBody = {
  attend: number;
  task: number;
};

export const postAttendTaskAPI = (body: ISendNewAttendTaskBody) => {
  return ApiService.HttpPost<IAttendTask[]>({
    route: "attend/attend-task/",
    body: body,
    token: true,
  });
};
