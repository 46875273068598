import constate from "constate";
import { useState } from "react";
import { IPaymentType } from "~/types";

const [PaymentTypeProvider, usePaymentType] = constate(() => {
  const [paymentTypes, setPaymentTypes] = useState<IPaymentType[]>([]);

  const [openNewPaymentTypeModal, setOpenNewPaymentTypeModal] =
    useState<boolean>(false);

  const [openEditPaymentTypeModal, setOpenEditPaymentTypeModal] =
    useState<boolean>(false);

  const [openDeletePaymentTypeModal, setOpenDeletePaymentTypeModal] =
    useState<boolean>(false);

  const [paymentTypesDetails, setPaymentTypesDetails] =
    useState<IPaymentType>();

  return {
    paymentTypes,
    setPaymentTypes,
    openNewPaymentTypeModal,
    setOpenNewPaymentTypeModal,
    openDeletePaymentTypeModal,
    setOpenDeletePaymentTypeModal,
    paymentTypesDetails,
    setPaymentTypesDetails,
    openEditPaymentTypeModal,
    setOpenEditPaymentTypeModal,
  };
});

export { PaymentTypeProvider };

export default usePaymentType;
