import { Modal, PasswordInput } from "@carbon/react";
import Margin from "~/components/Margin";
import { useCallback, useMemo } from "react";
import useLoading from "~/hooks/useLoading";
import * as Yup from "yup";
import { useFormik } from "formik";
import ErrorIcon from "~/assets/Misuse.png";
import CheckIcon from "~/assets/Checkmark--filled.png";

import { toast } from "react-toastify";
import { ISendNewPassword, postNewPasswordAPI } from "~/services/api/password";
import useUser from "~/hooks/useUser";

const notifySuccess = () => toast.success("Senha alterada com sucesso!");
const notifyError = () =>
  toast.error(
    "Não foi possível alterar a senha. Verifique se o campo de senha atual está correto ou tente novamente mais tarde."
  );

type Values = {
  password: string;
  confirm_password: string;
  actual_password: string;
};

const initialValues: Values = {
  password: "",
  confirm_password: "",
  actual_password: "",
};

const validationSchema = Yup.object({});

const ChangePassword = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();

  const { setOpenChangePasswordModal } = useUser();

  const { resetForm, values, handleChange } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const minLength = values.password.length >= 8;
  const hasNumber = /[0-9]/.test(values.password);
  const hasUpperCase = /[A-Z]/.test(values.password);
  const hasLowerCase = /[a-z]/.test(values.password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(values.password);

  const payloadAssociateNewAttendAreaProfile = useMemo(() => {
    return {
      current_password: values.actual_password,
      new_password: values.password,
    };
  }, [values]);

  const fetchAssociateAttendAreaProfile = useCallback(
    async (payload: ISendNewPassword) => {
      setLoading(true);

      try {
        await postNewPasswordAPI(payload);
        setOpenChangePasswordModal(false);
        resetForm();
        setLoading(false);

        notifySuccess();
      } catch (err: unknown) {
        console.error(err);

        setLoading(false);
        notifyError();
      }
    },
    [setLoading, resetForm, setOpenChangePasswordModal]
  );

  return (
    <Modal
      style={{ zIndex: 6 }}
      modalHeading="Alterar senha"
      size="sm"
      open={open}
      onRequestClose={() => {
        onClose();
        resetForm();
      }}
      primaryButtonDisabled={
        values.actual_password === "" ||
        values.password === "" ||
        values.confirm_password === "" ||
        values.confirm_password !== values.password ||
        !minLength ||
        !hasLowerCase ||
        !hasNumber ||
        !hasSpecialChar ||
        !hasNumber ||
        !hasUpperCase
      }
      primaryButtonText="Concluir"
      secondaryButtonText="Cancelar"
      onRequestSubmit={async () => {
        fetchAssociateAttendAreaProfile(payloadAssociateNewAttendAreaProfile);
      }}
    >
      <div>
        <Margin mb={50} />
        <PasswordInput
          id="actual_password"
          labelText="Senha atual"
          value={values.actual_password}
          onChange={handleChange}
          placeholder="Digite a senha atual"
        />
        <Margin mb={20} />

        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <Margin mt={5} />
            <img src={minLength ? CheckIcon : ErrorIcon} alt="" />
          </div>
          <Margin ml={10} />
          <p>Mínimo 8 caracteres</p>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <Margin mt={5} />
            <img src={hasNumber ? CheckIcon : ErrorIcon} alt="" />
          </div>
          <Margin ml={10} />
          <p>Mínimo de 1 número</p>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <Margin mt={5} />
            <img
              src={hasLowerCase && hasUpperCase ? CheckIcon : ErrorIcon}
              alt=""
            />
          </div>
          <Margin ml={10} />
          <p>Letras maiúsculas e minúsculas</p>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <Margin mt={5} />
            <img src={hasSpecialChar ? CheckIcon : ErrorIcon} alt="" />
          </div>
          <Margin ml={10} />
          <p>Caracteres especiais</p>
        </div>
        <Margin mt={20} />

        <PasswordInput
          id="password"
          labelText="Nova senha"
          placeholder="Digite sua nova senha"
          value={values.password}
          onChange={handleChange}
        />
        <Margin mb={20} />
        <PasswordInput
          id="confirm_password"
          labelText="Confirmação de nova senha"
          placeholder="Confirme sua nova senha"
          value={values.confirm_password}
          onChange={handleChange}
        />
      </div>
      <Margin mb={50} />
    </Modal>
  );
};

export default ChangePassword;
