import {
  Table,
  TableBatchAction,
  TableBatchActions,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Grid,
  Column,
  Button,
  Pagination,
} from "@carbon/react";

import {
  Add,
  Save,
  Download,
  Edit,
  TrashCan,
  Renew,
} from "@carbon/icons-react";
import Margin from "~/components/Margin";
import { useCallback, useEffect, useMemo, useState } from "react";
import Padding from "~/components/Padding";

import { getAttendTypeAPI } from "~/services/api/type";
import useAttendType from "~/hooks/useType";
import NewAttendTypeContentModal from "./NewAttendTypeContentModal";
import DeleteAttendTypeContentModal from "./DeleteAttendTypeContentModal";
import { permissions } from "~/services/permissions";
import EditAttendTypeModal from "./EditAttendTypeModal";
import useLoading from "~/hooks/useLoading";

const tableHeadersArea: string[] = ["Nome", "SLA", ""];

const AttendTypes = () => {
  const {
    setAttendTypes,
    attendTypes,
    openNewAttendTypeModal,
    setOpenNewAttendTypeModal,
    openDeleteAttendTypeModal,
    setOpenDeleteAttendTypeModal,
    setAttendTypesDetails,
    setOpenEditAttendTypeModal,
    openEditAttendTypeModal,
  } = useAttendType();
  const { setLoading } = useLoading();

  const canReadAttendTypes = permissions.includes("can_read_attend_types");
  const canCreateAttendTypes = permissions.includes("can_create_attend_types");
  const canEditAttendTypes = permissions.includes("can_edit_attend_types");
  const canDeleteAttendTypes = permissions.includes("can_delete_attend_types");

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const paginatedAttendTypes = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return attendTypes
      ?.sort((a, b) =>
        a.name.localeCompare(b.name, "pt-BR", { sensitivity: "base" })
      )
      ?.slice(start, end);
  }, [currentPage, itemsPerPage, attendTypes]);

  const fetchAttendTypes = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getAttendTypeAPI();
      setAttendTypes(data.results.reverse());
      setLoading(false);
    } catch (err: unknown) {
      setLoading(false);
      console.error(err);
    }
  }, [setAttendTypes, setLoading]);

  useEffect(() => {
    fetchAttendTypes();
  }, [fetchAttendTypes]);

  return (
    <Grid>
      <Column span={16}>
        <Margin mt={64} /> <h1>Tipos de atendimento</h1>
        <Margin mt={34} /> <Margin mt={20} />
        <TableContainer>
          <Margin mb={20} />
          <TableToolbar>
            {canReadAttendTypes && (
              <Button
                tabIndex={0}
                onClick={() => {
                  fetchAttendTypes();
                }}
                kind="secondary"
                renderIcon={Renew}
              >
                Atualizar
              </Button>
            )}
            {!canReadAttendTypes && (
              <div
                style={{
                  height: "400px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <h4 style={{ position: "relative", left: "45%" }}>
                  Você não possui permissão para visualizar os tipos de
                  atendimento
                </h4>
              </div>
            )}
            <TableBatchActions onCancel={console.log} totalSelected={0}>
              <TableBatchAction
                tabIndex={0}
                renderIcon={TrashCan}
                onClick={console.log}
              >
                Delete
              </TableBatchAction>
              <TableBatchAction
                hasIconOnly
                iconDescription="Add"
                tabIndex={0}
                renderIcon={Add}
                onClick={console.log}
              >
                Delete
              </TableBatchAction>
              <TableBatchAction
                hasIconOnly
                iconDescription="Save"
                tabIndex={0}
                renderIcon={Save}
                onClick={console.log}
              >
                Save
              </TableBatchAction>
              <TableBatchAction
                tabIndex={0}
                renderIcon={Download}
                onClick={console.log}
              >
                Download
              </TableBatchAction>
            </TableBatchActions>
            <TableToolbarContent>
              {/* <TableToolbarSearch
                tabIndex={0}
                onChange={handleChange}
                value={values.search}
                id="search"
                placeholder="Pesquisar"
              /> */}
              {canCreateAttendTypes && (
                <Button
                  tabIndex={0}
                  onClick={() => {
                    setOpenNewAttendTypeModal(true);
                  }}
                  kind="primary"
                >
                  Novo tipo de atendimento
                </Button>
              )}
            </TableToolbarContent>
          </TableToolbar>
          {canReadAttendTypes && (
            <Table>
              <TableHead>
                <TableRow>
                  {/* <TableSelectAll
                  name={""}
                  id={""}
                  onSelect={console.log}
                  checked={false}
                  ariaLabel={""}
                /> */}
                  {tableHeadersArea.map((columnTitle, index) => {
                    return (
                      <TableHeader key={String(index)}>
                        {columnTitle}
                      </TableHeader>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedAttendTypes?.length === 0 ? (
                  <TableCell colSpan={tableHeadersArea.length + 1}>
                    <Padding pt={24} pb={24} pl={12}>
                      <div
                        style={{
                          height: "300px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <h4>Nenhum tipo de atendimento criado</h4>
                      </div>
                    </Padding>
                  </TableCell>
                ) : paginatedAttendTypes.length > 0 ? (
                  paginatedAttendTypes?.map((type, index) => (
                    <TableRow key={String(index)} style={{ cursor: "pointer" }}>
                      {/* <TableSelectRow
                      name={""}
                      id={""}
                      onSelect={console.log}
                      checked={false}
                    />{" "} */}
                      <TableCell>{type?.name}</TableCell>
                      <TableCell>{`${type?.sla} ${type.sla_type === "day" ? "Dia(s)" : type.sla_type === "hour" ? "Hora(s)" : "Minuto(s)"}`}</TableCell>

                      <TableCell>
                        {canEditAttendTypes && (
                          <Button
                            kind="ghost"
                            hasIconOnly
                            iconDescription="Editar"
                            renderIcon={Edit}
                            id="filter"
                            onClick={(e) => {
                              e.stopPropagation();
                              setAttendTypesDetails(type);
                              setOpenEditAttendTypeModal(true);
                            }}
                          />
                        )}

                        {canDeleteAttendTypes && (
                          <Button
                            kind="ghost"
                            hasIconOnly
                            iconDescription="Exluir"
                            renderIcon={TrashCan}
                            onClick={(e) => {
                              e.stopPropagation();
                              setAttendTypesDetails(type);
                              setOpenDeleteAttendTypeModal(true);
                            }}
                            id="filter"
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  paginatedAttendTypes?.map((type, index) => (
                    <TableRow
                      key={String(index)}
                      style={{ cursor: "pointer" }}
                      // onClick={() => alert(index)}
                    >
                      {/* <TableSelectRow
                      name={""}
                      id={""}
                      onSelect={console.log}
                      checked={false}
                    /> */}
                      <TableCell>{type?.name}</TableCell>
                      <TableCell>{type?.sla}h</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          )}
          <Pagination
            totalItems={attendTypes.length}
            pageSize={itemsPerPage}
            pageSizes={[5, 10, 20, 30, 40, 50]}
            onChange={({ page, pageSize }) => {
              setCurrentPage(page);
              setItemsPerPage(pageSize);
            }}
            page={currentPage}
            size="md"
          />
        </TableContainer>
      </Column>
      <NewAttendTypeContentModal
        onClose={() => setOpenNewAttendTypeModal(false)}
        open={openNewAttendTypeModal}
      />
      <EditAttendTypeModal
        onClose={() => setOpenEditAttendTypeModal(false)}
        open={openEditAttendTypeModal}
      />

      <DeleteAttendTypeContentModal
        onClose={() => setOpenDeleteAttendTypeModal(false)}
        open={openDeleteAttendTypeModal}
      />
    </Grid>
  );
};

export default AttendTypes;
