import styled from "styled-components";

const LabelText = styled.p`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.32px;
  box-sizing: border-box;
  margin-bottom: 5px;
  padding: 0;
  height: auto;
`;

const Label = (props: { text: string }) => {
  const { text } = props;

  return <LabelText>{text}</LabelText>;
};

export default Label;
