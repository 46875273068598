import { Modal } from "@carbon/react";
import Margin from "~/components/Margin";
import { useCallback } from "react";
import useLoading from "~/hooks/useLoading";
import { toast } from "react-toastify";
import usePaymentType from "~/hooks/useTypesPayment";
import {
  deletePaymentTypeAPI,
  getPaymentTypeAPI,
} from "~/services/api/paymentTypes";

const DeletePaymentTypeModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();

  const {
    paymentTypesDetails,
    setPaymentTypes,
    setOpenDeletePaymentTypeModal,
  } = usePaymentType();
  const notifySuccess = () =>
    toast.success("Tipo de despesa excluído com sucesso!");
  const notifyError = () =>
    toast.error(
      "Não foi possível excluir o tipo de despesa pois ele está associado a uma área."
    );

  const fetchPaymentTypes = useCallback(async () => {
    try {
      const { data } = await getPaymentTypeAPI();
      setPaymentTypes(data.results.reverse());
      console.log(data);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setPaymentTypes]);

  const fetchDeletePaymentType = async (id?: number) => {
    setLoading(true);
    try {
      await deletePaymentTypeAPI(String(id));

      await fetchPaymentTypes();

      setLoading(false);
      notifySuccess();
      setOpenDeletePaymentTypeModal(false);
    } catch (error) {
      console.error("Erro ao excluir tipo de despesa:", error);
      setLoading(false);
      notifyError();
    }
  };

  return (
    <Modal
      style={{ zIndex: 6 }}
      modalHeading="Excluir tipo de despesa"
      size="sm"
      open={open}
      onRequestClose={() => {
        onClose();
      }}
      primaryButtonText="Sim, excluir"
      secondaryButtonText="Cancelar"
      onRequestSubmit={async () => {
        fetchDeletePaymentType(paymentTypesDetails?.id);
      }}
    >
      <h3>
        Deseja realmente excluir o tipo de despesa {paymentTypesDetails?.name} ?
      </h3>
      <Margin mb={124} />
    </Modal>
  );
};

export default DeletePaymentTypeModal;
