import { IFund } from "~/types";
import ApiService from "../api";

type IResponseGetFunds = {
  count: number;
  next: null;
  previous: null;
  results: IFund[];
};

export type IBodyFund = {
  name: string;
  raw_data: {
    active?: boolean;
    short_name: string;
    document_number: string;
    authenticated?: boolean;
  };
};

export const getFundsAPI = () => {
  return ApiService.HttpGet<IResponseGetFunds>({
    route: "fund/",
    token: true,
  });
};

export const postFundsAPI = (body: IBodyFund) => {
  return ApiService.HttpPost<IFund>({
    route: "fund/",
    token: true,
    body,
  });
};

export const patchFundsAPI = (body: IBodyFund, id: string) => {
  return ApiService.HttpPatch<IFund>({
    route: `fund/${id}/`,
    token: true,
    body,
  });
};

export const deleteFundsAPI = (id: string) => {
  return ApiService.HttpDelete({
    route: `fund/${id}/`,
    token: true,
  });
};
