import { Modal } from "@carbon/react";

import Margin from "~/components/Margin";
import { deleteAreaAPI, getAreasAPI } from "~/services/api/areas";

import { useCallback } from "react";
import useLoading from "~/hooks/useLoading";
import useArea from "~/hooks/useArea";
import { toast } from "react-toastify";

const notifySuccess = () => toast.success(" Área excluída com sucesso!");
const notifyError = () =>
  toast.error("A área não pode ser excluída. Tente novamente mais tarde.");

const DeleteAreaModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();
  const { areaDetails, setAreas } = useArea();

  const fetchAreas = useCallback(async () => {
    try {
      const { data } = await getAreasAPI();
      setAreas(data.results.reverse());
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, []);

  const fatchDeleteArea = useCallback(async (id?: number) => {
    setLoading(true);
    try {
      await deleteAreaAPI(String(id));
      await fetchAreas();
      setLoading(false);
      notifySuccess();
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
      notifyError();
    }
  }, []);

  return (
    <Modal
      modalHeading="Excluir área"
      size="sm"
      open={open}
      onRequestClose={onClose}
      primaryButtonText="Sim, excluir"
      secondaryButtonText="Cancelar"
      onRequestSubmit={async () => {
        fatchDeleteArea(areaDetails?.id);
        onClose();
      }}
    >
      <h3>Deseja realmente excluir a área {areaDetails?.name} ?</h3>
      <Margin mb={24} />
    </Modal>
  );
};

export default DeleteAreaModal;
