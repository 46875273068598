import { IAttendAreaProfile } from "~/types";
import ApiService from "../api";

export type IResponseAttendAreaProfile = {
  count: number;
  next: null;
  previous: null;
  results: IAttendAreaProfile[];
};

const expand: string[] = ["profile"];

export const getAttendAreaProfilesAPI = (id?: number) => {
  return ApiService.HttpGet<IResponseAttendAreaProfile>({
    route: "attend/attend-area-profile/",
    token: true,
    params: {
      expand,
      area: id,
    },
  });
};

export type IAssociateAttendAreaProfile = {
  profile: number;
  area: number;
};

export const postAttendAreaProfileAPI = (body: IAssociateAttendAreaProfile) => {
  return ApiService.HttpPost<IAttendAreaProfile[]>({
    route: "attend/attend-area-profile/",
    body: body,
    token: true,
  });
};

export const deleteAttendAreaProfileAPI = (id: string) => {
  return ApiService.HttpDelete({
    route: `attend/attend-area-profile/${id}/`,
    token: true,
  });
};
