import constate from "constate";
import { useState } from "react";
import { ICompany, ICompanyFund } from "~/types";

const [CompanyProvider, useCompany] = constate(() => {
  const [companys, setCompanys] = useState<ICompany[]>([]);
  const [openNewCompanyModal, setOpenNewCompanyModal] =
    useState<boolean>(false);
  const [openEditCompanyModal, setOpenEditCompanyModal] =
    useState<boolean>(false);
  const [openDeleteCompanyModal, setOpenDeleteCompanyModal] =
    useState<boolean>(false);
  const [companyDetails, setCompanyDetails] = useState<ICompany | null>(null);
  const [companyFunds, setCompanyFunds] = useState<ICompanyFund[] | []>([]);
  const [openAddFundsCompanyModal, setOpenAddFundsCompanyModal] =
    useState<boolean>(false);
  const [openRemoveFundsCompanyModal, setOpenRemoveFundsCompanyModal] =
    useState<boolean>(false);
  const [openAddProfileCompanyModal, setOpenAddProfileCompanyModal] =
    useState<boolean>(false);
  const [fundCompanyDetails, setFundCompanyDetails] = useState<ICompanyFund>();

  return {
    companys,
    setCompanys,
    companyDetails,
    setCompanyDetails,
    openDeleteCompanyModal,
    setOpenDeleteCompanyModal,
    openEditCompanyModal,
    setOpenEditCompanyModal,
    openNewCompanyModal,
    setOpenNewCompanyModal,
    companyFunds,
    setCompanyFunds,
    openAddFundsCompanyModal,
    setOpenAddFundsCompanyModal,
    openAddProfileCompanyModal,
    setOpenAddProfileCompanyModal,
    openRemoveFundsCompanyModal,
    setOpenRemoveFundsCompanyModal,
    fundCompanyDetails,
    setFundCompanyDetails,
  };
});

export { CompanyProvider };

export default useCompany;
