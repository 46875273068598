import { Modal } from "@carbon/react";

import Margin from "~/components/Margin";
import { useCallback, useEffect } from "react";
import useLoading from "~/hooks/useLoading";
import { toast } from "react-toastify";
import { formatCPFCNPJ } from "~/services/utils";
import useCompany from "~/hooks/useCompany";
import {
  deleteCompanyFundAPI,
  getCompanyFundAPI,
} from "~/services/api/companyFund";

const RemoveFundCompany = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();
  const {
    companyDetails,

    setOpenRemoveFundsCompanyModal,
    fundCompanyDetails,
    setCompanyFunds,
  } = useCompany();

  const notifySuccess = () => toast.success("Fundo removido com sucesso!");
  const notifyError = () =>
    toast.error(
      "Não foi possível remover o fundo. Tente novamente mais tarde."
    );

  const fetchFundsCompany = useCallback(async () => {
    try {
      const { data } = await getCompanyFundAPI();
      setCompanyFunds(data.results.reverse());
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setCompanyFunds]);

  const fetchDeleteFundCompany = useCallback(
    async (id: number) => {
      setLoading(true);
      try {
        await deleteCompanyFundAPI(id);
        await fetchFundsCompany();
        setOpenRemoveFundsCompanyModal(false);
        setLoading(false);
        notifySuccess();
      } catch (err: unknown) {
        console.error(err);
        setLoading(false);
        notifyError();
      }
    },
    [setLoading, fetchFundsCompany, setOpenRemoveFundsCompanyModal]
  );

  useEffect(() => {
    fetchFundsCompany();
  }, [fetchFundsCompany]);

  return (
    <Modal
      style={{ zIndex: 6 }}
      modalHeading={`Remover fundo da empresa ${companyDetails?.raw_data?.brand_name}`}
      size="sm"
      open={open}
      onRequestClose={onClose}
      primaryButtonText="Sim, remover"
      secondaryButtonText="Cancelar"
      onRequestSubmit={async () => {
        fundCompanyDetails?.id &&
          fetchDeleteFundCompany(fundCompanyDetails?.id);
      }}
    >
      {/* {true && (
        <InlineNotification
          title="Erro!"
          // subtitle={error}
          subtitle="Não foi possivel atribuir o tipo de atendimento"
          hideCloseButton
          kind="error"
          lowContrast
        />
      )} */}
      <Margin mt={20} />
      <h3 style={{ textAlign: "center" }}>
        Deseja realmente remover o fundo abaixo ?
      </h3>
      <Margin mb={40} />
      <h4>
        {" "}
        <span style={{ fontWeight: "bold" }}>Nome (Abreviado): </span>{" "}
        {fundCompanyDetails?.fund.raw_data?.short_name}
      </h4>
      <h4>
        {" "}
        <span style={{ fontWeight: "bold" }}>CNPJ: </span>{" "}
        {fundCompanyDetails?.fund.raw_data?.document_number &&
          formatCPFCNPJ(fundCompanyDetails?.fund.raw_data?.document_number)}
      </h4>

      <h4>
        {" "}
        <span style={{ fontWeight: "bold" }}>Nome (Completo): </span>{" "}
        {fundCompanyDetails?.fund?.name}
      </h4>

      <Margin mb={100} />
    </Modal>
  );
};

export default RemoveFundCompany;
