import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Button,
  Pagination,
  // Pagination,
} from "@carbon/react";
import { TrashCan, Renew } from "@carbon/icons-react";
import Margin from "~/components/Margin";
// import { formatCPFCNPJ } from "~/services/utils";
import { Breadcrumb, Tooltip } from "prosperita-dumbo-react";
import "prosperita-dumbo-react/dist/style.css";
import { Tabs } from "antd";
import { useCallback, useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useBeneficiary from "~/services/../hooks/useBeneficiary";
import useLoading from "~/hooks/useLoading";
import NewAccountModal from "./AddNewAccountModal";
import AlertIcon from "../../../assets/alert.png";
import {
  getBeneficiaryAPI,
  getBeneficiaryFundWIDAPI,
} from "~/services/api/beneficiaries";
import DeleteAccountBeneficiary from "./DeleteAccoutBeneficiaryModal";
import { getBanks } from "~/services/api/banks";
import useBanks from "~/hooks/useBanks";
import EditBankAccountBeneficiaryModal from "./EditBankAccountBeneficiaryModal";
import Padding from "~/components/Padding";
import { AddModalPixkey } from "./AddModalPixkey";
import { DeleteModalKeyPix } from "./DeleteModalPixKey";
import ApproveBeneficiaryModal from "./ApproveBeneficiaryModal";
import ApproveModalAccountBeneficiary from "./ApproveModalAccountBeneficiary";
import Badge from "~/components/Badge";
import ReproveBeneficiaryModal from "./ReproveModalBeneficiary";
import { formatCPFCNPJ } from "~/services/utils";
import AddBeneficiaryFundModal from "./AddBeneficiaryFundModal";
import useUser from "~/hooks/useUser";
import ApproveFundBeneficiaryModal from "./ApproveReproveFunds/ApproveFundBeneficiaryModal";
import ReproveFundBeneficiaryModal from "./ApproveReproveFunds/ReproveBeneficiaryFundModal";
const tableInfoAccount: string[] = [
  "Instituição",
  "Conta",
  "Agencia",
  "Status",

  "",
  "",
];

const tableInfoFund: string[] = [
  "Nome (Abreviado)",
  "CNPJ",
  "Nome (Completo)",
  "Status (Fundo)",
  "Autenticação",
  "Status (Associação)",
  "",
  "",
];

const TablePixTypeAndKey: string[] = [
  "Instituição",
  "Nome",
  "Chave",
  "Tipo",
  "Status",
  "",
];

const renderStatusBankAccounts: { [key: string]: React.ReactNode } = {
  inactive: <Badge label="Inativa" color="#e3e3e3" textColor="#696868" />,

  waiting_approval: (
    <Badge color="#ffe9c9" textColor="#b46c00" label="Em aprovação" />
  ),
  approved: <Badge color="#CCEFDF" textColor="#00B360" label="Ativa" />,

  reproved: <Badge label="Reprovada" textColor="#ff0000" color="#f9c6c6" />,
};

const renderStatusFunds: { [key: string]: React.ReactNode } = {
  inactive: <Badge label="Inativo" color="#e3e3e3" textColor="#696868" />,

  waiting_approval: (
    <Badge color="#ffe9c9" textColor="#b46c00" label="Em aprovação" />
  ),
  approved: <Badge color="#CCEFDF" textColor="#00B360" label="Ativo" />,

  reproved: <Badge label="Reprovado" textColor="#ff0000" color="#f9c6c6" />,
};

const renderStatus: { [key: string]: React.ReactNode } = {
  inactive: <Badge label="Inativo" color="#e3e3e3" textColor="#696868" />,

  waiting_approval: (
    <Badge color="#ffe9c9" textColor="#b46c00" label="Aguardando aprovação" />
  ),
  approved: <Badge color="#CCEFDF" textColor="#00B360" label="Ativo" />,

  reproved: <Badge label="Reprovado" textColor="#ff0000" color="#f9c6c6" />,
};

const BeneficiaryDetails = () => {
  const { TabPane } = Tabs;
  const { id } = useParams();
  const {
    setBeneficiaryFundDetails,
    beneficiaryDetails,
    setBeneficiaryDetails,
    setEditModalBeneficiary,
    editModalBeneficiary,
    setAccountBeneficiary,
    setDeleteModalAccountBeneficiary,
    deleteAccountModalBeneficiary,
    seteditAccountModalBeneficiary,
    editAccountModalBeneficiary,
    setaddkeyPixModal,
    addkeyPixModal,
    setpixkeyUser,
    setdeleteModalKeyPix,
    deleteModalKeyPix,
    setOpenApproveBeneficiaryModal,
    openApproveBeneficiaryModal,
    setAppApproveAccountBeneficiaryModal,
    appApproveAccountBeneficiaryModal,
    setOpenReproveBeneficiaryModal,
    openReproveBeneficiaryModal,
    setAddBeneficiaryFundModal,
    addBeneficiaryFundModal,
    setApproveBeneficiaryFundModal,
    approveBeneficiaryFundModal,
    setFundsBeneficiaryRender,
    fundsBeneficiaryRender,
    reproveBeneficiaryFundModal,
    setReproveBeneficiaryFundModal,
  } = useBeneficiary();

  const navigate = useNavigate();

  const { banks, setBanks } = useBanks();
  const { userProfile } = useUser();

  const { setLoading } = useLoading();

  fundsBeneficiaryRender;
  const fetchFundsBeneficiary = useCallback(async () => {
    try {
      const { data } = await getBeneficiaryFundWIDAPI(Number(id));
      setFundsBeneficiaryRender(data?.results.reverse());
    } catch {
      console.warn("erro");
    }
  }, [id, setFundsBeneficiaryRender]);

  const fetchBeneficiary = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getBeneficiaryAPI(Number(id));
      setBeneficiaryDetails(data);
      setLoading(false);
    } catch {
      console.log("erro ao fazer requisição");
    }
  }, [setBeneficiaryDetails, id, setLoading]);

  const fetchBanks = useCallback(async () => {
    try {
      const { data } = await getBanks();
      setBanks(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, []);

  const BanksObject = useMemo(() => {
    return banks.reduce(
      (acc, bank) => {
        acc[bank.id] = {
          id: bank.id,
          name: bank.name,
          code: bank.code,
          description: bank.description,
        };
        return acc;
      },
      {} as Record<
        number,
        { id: number; name: string; code: string; description: string }
      >
    );
  }, [banks]);

  const isBeneficiaryApprover = useMemo(() => {
    return userProfile?.beneficiary_approver;
  }, [userProfile]);

  const [currentPageBankAccount, setCurrentPageBankAccount] = useState(1);
  const [itemsPerPageBankAccount, setItemsPerPageBankAccount] = useState(20);

  const [currentPageFunds, setCurrentPageFunds] = useState(1);
  const [itemsPerPageFunds, setItemsPerPageFunds] = useState(20);

  const paginatedBankAccounts = useMemo(() => {
    const start = (currentPageBankAccount - 1) * itemsPerPageBankAccount;
    const end = start + itemsPerPageBankAccount;
    return (
      beneficiaryDetails?.bank_account &&
      beneficiaryDetails?.bank_account?.slice(start, end)
    );
  }, [
    beneficiaryDetails?.bank_account,
    currentPageBankAccount,
    itemsPerPageBankAccount,
  ]);

  const paginatedFunds = useMemo(() => {
    // Filtra e ordena os fundos com status "waiting_approval"
    const waitingApprovalFunds =
      fundsBeneficiaryRender
        ?.filter((fund) => fund.raw_data.status === "waiting_approval")
        .sort((a, b) => a.fund.name.localeCompare(b.fund.name)) || [];

    // Filtra e ordena os outros fundos
    const otherFunds =
      fundsBeneficiaryRender
        ?.filter((fund) => fund.raw_data.status !== "waiting_approval")
        .sort((a, b) => a.fund.name.localeCompare(b.fund.name)) || [];

    // Combina os dois arrays, com os fundos "waiting_approval" primeiro
    const combinedFunds = [...waitingApprovalFunds, ...otherFunds];

    // Paginação
    const start = (currentPageFunds - 1) * itemsPerPageFunds;
    const end = start + itemsPerPageFunds;

    // Retorna a fatia dos fundos paginados
    return combinedFunds.slice(start, end);
  }, [fundsBeneficiaryRender, currentPageFunds, itemsPerPageFunds]);

  const OptionsKey = useMemo(() => {
    return [
      { label: "CNPJ", value: "cnpj" },
      { label: "CPF", value: "cpf" },
      { label: "Celular", value: "celphone" },
      { label: "Email", value: "email" },
      { label: "Aleatória", value: "evp" },
    ] as const;
  }, []);

  const [TakekeyvalueTab, setTakekeyvalueTab] = useState<number>(() => {
    return localStorage.getItem("key_tab")
      ? Number(localStorage.getItem("key_tab"))
      : 1;
  });
  const setStoregeTabkey = useCallback((key_tab: number) => {
    localStorage.setItem("key_tab", JSON.stringify(key_tab));
    setTakekeyvalueTab(key_tab);
  }, []);

  useEffect(() => {
    fetchBeneficiary();
    fetchBanks();
    fetchFundsBeneficiary();
  }, []);

  return (
    <>
      <Margin mt={150} />
      <div style={{ width: "95%", margin: "0 auto" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            fontWeight: 100,
            alignItems: "center",
            margin: 0,
            padding: 0,
          }}
        >
          <Breadcrumb
            crumbsOptions={[
              {
                crumb: "Beneficiários",
                onClick: () => navigate("/app/beneficiaries"),
              },
              {
                crumb: beneficiaryDetails?.name || "",
                onClick: () => "",
              },
            ]}
            endHiddenIndex={0}
            startHiddenIndex={5}
          />
        </div>
        <Margin mt={40} />
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          {" "}
          <div>
            <h2> {beneficiaryDetails?.name}</h2>

            <h4>{beneficiaryDetails?.document_number}</h4>
            <Margin mt={5} />

            {beneficiaryDetails?.raw_data?.status &&
              renderStatus[beneficiaryDetails?.raw_data?.status]}
          </div>
          {beneficiaryDetails?.raw_data?.status === "waiting_approval" && (
            <div
              style={{
                display: "flex",
              }}
            >
              <div>
                <Button
                  onClick={() => {
                    setOpenReproveBeneficiaryModal(true);
                  }}
                  kind="danger--tertiary"
                >
                  Reprovar
                </Button>
              </div>
              <Margin ml={10} />
              <div>
                <Button onClick={() => setOpenApproveBeneficiaryModal(true)}>
                  Aprovar
                </Button>
              </div>
            </div>
          )}
        </div>

        <Margin mt={-2} />
        <Tabs
          defaultActiveKey={TakekeyvalueTab.toString()}
          tabBarStyle={{
            margin: 0,
            borderBottom: "none",
            color: "red",
          }}
          onChange={(key_tab) => setStoregeTabkey(Number(key_tab))}
        >
          <TabPane
            tab={
              isBeneficiaryApprover ? (
                <div style={{ display: "flex" }}>
                  Fundos Associados
                  <Margin ml={3} />
                  {fundsBeneficiaryRender &&
                    fundsBeneficiaryRender?.some(
                      (fundBeneficiary) =>
                        fundBeneficiary.raw_data.status === "waiting_approval"
                    ) && (
                      <div style={{ display: "flex" }}>
                        <p>{`(${
                          fundsBeneficiaryRender.filter(
                            (fundBeneficiary) =>
                              fundBeneficiary.raw_data.status ===
                              "waiting_approval"
                          )?.length
                        })`}</p>
                        <Margin ml={2} />
                        <Tooltip
                          align="right"
                          size="medium"
                          label="Aprovações pendentes"
                        >
                          {" "}
                          <img
                            style={{
                              width: "22px",
                            }}
                            src={AlertIcon}
                            alt=""
                          />{" "}
                        </Tooltip>
                      </div>
                    )}
                </div>
              ) : (
                "Fundos Associados"
              )
            }
            key="1"
          >
            <Margin mt={34} /> <Margin mt={20} />
            <TableContainer title="Fundos Associados">
              <Margin mb={-20} />
              <Margin mt={20} />{" "}
              <TableToolbar>
                <Button
                  renderIcon={Renew}
                  tabIndex={0}
                  onClick={() => {
                    fetchBeneficiary();
                  }}
                  kind="secondary"
                >
                  Atualizar
                </Button>
                <TableToolbarContent>
                  <Button
                    tabIndex={0}
                    onClick={() => setAddBeneficiaryFundModal(true)}
                    kind="primary"
                    disabled={
                      beneficiaryDetails?.raw_data?.status !== "approved"
                    }
                  >
                    Associar fundos
                  </Button>
                </TableToolbarContent>
              </TableToolbar>
              <Table>
                <TableHead>
                  <TableRow>
                    {tableInfoFund.map((columnTitle, index) => {
                      return (
                        <TableHeader key={String(index)}>
                          {columnTitle}
                        </TableHeader>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedFunds && paginatedFunds?.length > 0 ? (
                    paginatedFunds?.map((beneficiaryFund, index) => (
                      <TableRow key={index}>
                        <>
                          <TableCell>
                            {beneficiaryFund?.fund?.raw_data?.short_name}
                          </TableCell>
                          <TableCell>
                            {formatCPFCNPJ(
                              beneficiaryFund?.fund?.raw_data?.document_number
                            )}
                          </TableCell>
                          <TableCell style={{ maxWidth: "400px" }}>
                            {beneficiaryFund?.fund?.name}
                          </TableCell>
                        </>
                        <TableCell>
                          {beneficiaryFund.fund.raw_data.active ? (
                            <Badge
                              color="#CCEFDF"
                              textColor="#00B360"
                              label="Ativo"
                            />
                          ) : (
                            <Badge
                              label="Inativo"
                              color="#e3e3e3"
                              textColor="#696868"
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          <div style={{ width: "100px" }}>
                            {" "}
                            {beneficiaryFund?.fund?.raw_data?.authenticated ? (
                              <Badge
                                color="#bacbff"
                                textColor="#1906ec"
                                label="Autenticado"
                              />
                            ) : (
                              <Badge
                                label="Não autenticado"
                                color="#e3e3e3"
                                textColor="#696868"
                              />
                            )}
                          </div>
                        </TableCell>
                        <TableCell>
                          {" "}
                          <div style={{ width: "100px" }}>
                            {
                              renderStatusFunds[
                                beneficiaryFund?.raw_data.status
                              ]
                            }
                          </div>
                        </TableCell>
                        <TableCell>
                          {beneficiaryFund?.raw_data?.status !==
                            "waiting_approval" &&
                            isBeneficiaryApprover && (
                              <Button
                                kind="ghost"
                                hasIconOnly
                                iconDescription={"Remover"}
                                renderIcon={TrashCan}
                                id="filter"
                                onClick={() => {
                                  // setAccountBeneficiary(beneficiaryFund);
                                  // setDeleteModalAccountBeneficiary(true);
                                }}
                              />
                            )}
                        </TableCell>

                        <TableCell>
                          {beneficiaryFund.raw_data.status ===
                            "waiting_approval" &&
                            isBeneficiaryApprover && (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <div>
                                      <Button
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setBeneficiaryFundDetails(
                                            beneficiaryFund
                                          );
                                          setReproveBeneficiaryFundModal(true);
                                        }}
                                        kind="danger--ghost"
                                      >
                                        Reprovar
                                      </Button>
                                    </div>
                                    <Margin ml={10} />
                                    <div>
                                      <Button
                                        kind="ghost"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setBeneficiaryFundDetails(
                                            beneficiaryFund
                                          );
                                          setApproveBeneficiaryFundModal(true);
                                        }}
                                      >
                                        Aprovar
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableCell colSpan={tableInfoAccount.length}>
                      <Padding pt={24} pb={24} pl={12}>
                        <div
                          style={{
                            height: "300px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <h4>Nenhuma Conta Cadastrada</h4>
                        </div>
                      </Padding>
                    </TableCell>
                  )}
                </TableBody>
              </Table>
              <Pagination
                totalItems={paginatedFunds?.length}
                pageSize={itemsPerPageFunds}
                pageSizes={[5, 10, 20, 30, 40, 50]}
                onChange={({ page, pageSize }) => {
                  setCurrentPageFunds(page);
                  setItemsPerPageFunds(pageSize);
                }}
                page={currentPageBankAccount}
                size="md"
              />
            </TableContainer>
            <Margin mt={50} />
          </TabPane>
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*Conta Beneficiario  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          <TabPane
            tab={
              isBeneficiaryApprover ? (
                <div style={{ display: "flex" }}>
                  <p>Contas Bancárias</p>
                  <Margin ml={2} />
                  {beneficiaryDetails?.bank_account?.some(
                    (account) => account.status === "waiting_approval"
                  ) && (
                    <div style={{ display: "flex" }}>
                      <p>{`(${
                        beneficiaryDetails?.bank_account?.filter(
                          (account) => account.status === "waiting_approval"
                        )?.length
                      })`}</p>
                      <Margin ml={2} />
                      <Tooltip
                        align="right"
                        size="medium"
                        label="Aprovações pendentes"
                      >
                        {" "}
                        <img
                          style={{
                            width: "22px",
                          }}
                          src={AlertIcon}
                          alt=""
                        />{" "}
                      </Tooltip>
                    </div>
                  )}
                </div>
              ) : (
                "Contas Bancárias"
              )
            }
            key="2"
          >
            <Margin mt={34} /> <Margin mt={20} />
            <TableContainer title="Contas Bancárias">
              <Margin mb={-20} />
              <Margin mt={20} />{" "}
              <TableToolbar>
                <Button
                  renderIcon={Renew}
                  tabIndex={0}
                  onClick={() => {
                    fetchBeneficiary();
                  }}
                  kind="secondary"
                >
                  Atualizar
                </Button>
                <TableToolbarContent>
                  <Button
                    tabIndex={0}
                    onClick={() => setEditModalBeneficiary(true)}
                    kind="primary"
                    disabled={
                      beneficiaryDetails?.raw_data?.status !== "approved"
                    }
                  >
                    Cadastrar Nova Conta Bancária
                  </Button>
                </TableToolbarContent>
              </TableToolbar>
              <Table>
                <TableHead>
                  <TableRow>
                    {tableInfoAccount.map((columnTitle, index) => {
                      return (
                        <TableHeader key={String(index)}>
                          {columnTitle}
                        </TableHeader>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {beneficiaryDetails &&
                  beneficiaryDetails?.bank_account.length > 0 ? (
                    beneficiaryDetails?.bank_account.map((account, index) => (
                      <TableRow key={index}>
                        <>
                          <TableCell>
                            {BanksObject[Number(account.bank)]?.code +
                              " - " +
                              BanksObject[Number(account.bank)]?.description ||
                              "Banco não encontrado"}
                          </TableCell>
                          <TableCell>
                            {account.account_number}-
                            {account.account_number_digit}{" "}
                          </TableCell>
                          <TableCell>{account.agency}</TableCell>
                        </>
                        <TableCell>
                          {account?.status &&
                            renderStatusBankAccounts[account?.status]}
                        </TableCell>
                        <TableCell>
                          {account.status !== "waiting_approval" && (
                            <Button
                              kind="ghost"
                              hasIconOnly
                              iconDescription={"Remover"}
                              renderIcon={TrashCan}
                              id="filter"
                              onClick={() => {
                                setAccountBeneficiary(account);
                                setDeleteModalAccountBeneficiary(true);
                              }}
                            />
                          )}
                        </TableCell>

                        <TableCell>
                          {account.status === "waiting_approval" &&
                            isBeneficiaryApprover && (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <div>
                                      <Button
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          // setBeneficiaryDetails(beneficiaries);
                                          // setOpenReproveBenefociaryModal(true);
                                        }}
                                        kind="danger--ghost"
                                      >
                                        Reprovar
                                      </Button>
                                    </div>
                                    <Margin ml={10} />
                                    <div>
                                      <Button
                                        kind="ghost"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          // setBeneficiaryDetails(beneficiaries);
                                          // setOpenApproveBeneficiaryModal(true);
                                        }}
                                      >
                                        Aprovar
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableCell colSpan={tableInfoAccount.length}>
                      <Padding pt={24} pb={24} pl={12}>
                        <div
                          style={{
                            height: "300px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <h4>Nenhuma Conta Cadastrada</h4>
                        </div>
                      </Padding>
                    </TableCell>
                  )}
                </TableBody>
              </Table>
              <Pagination
                totalItems={paginatedBankAccounts?.length}
                pageSize={itemsPerPageBankAccount}
                pageSizes={[5, 10, 20, 30, 40, 50]}
                onChange={({ page, pageSize }) => {
                  setCurrentPageBankAccount(page);
                  setItemsPerPageBankAccount(pageSize);
                }}
                page={currentPageBankAccount}
                size="md"
              />
            </TableContainer>
            <Margin mt={50} />
          </TabPane>
          <TabPane tab="Chaves Pix" key="3">
            <Margin mt={34} /> <Margin mt={20} />
            <TableContainer title="Chaves Pix">
              <Margin mb={-20} />
              <Margin mt={20} />{" "}
              <TableToolbar>
                <Button
                  renderIcon={Renew}
                  tabIndex={0}
                  onClick={() => {
                    fetchBeneficiary();
                  }}
                  kind="secondary"
                >
                  Atualizar
                </Button>
                <TableToolbarContent>
                  <Button
                    tabIndex={0}
                    onClick={() => setaddkeyPixModal(true)}
                    kind="primary"
                    disabled={
                      beneficiaryDetails?.raw_data?.status !== "approved"
                    }
                  >
                    Cadastrar Nova Chave
                  </Button>
                </TableToolbarContent>
              </TableToolbar>
              <Table>
                <TableHead>
                  <TableRow>
                    {TablePixTypeAndKey.map((columnTitle, index) => {
                      return (
                        <TableHeader key={String(index)}>
                          {columnTitle}
                        </TableHeader>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {beneficiaryDetails &&
                  beneficiaryDetails?.pix_keys.length > 0 ? (
                    <>
                      {beneficiaryDetails?.pix_keys.map((key_pix, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {key_pix?.bank} - {key_pix?.bank_name}
                          </TableCell>
                          <TableCell>{key_pix?.name}</TableCell>
                          <TableCell>{key_pix.key}</TableCell>
                          <TableCell>
                            {OptionsKey.map((items) => (
                              <>
                                {items.value === key_pix.type
                                  ? items.label
                                  : ""}
                              </>
                            ))}
                          </TableCell>
                          <TableCell>
                            <Button
                              kind="ghost"
                              hasIconOnly
                              iconDescription={"remover"}
                              renderIcon={TrashCan}
                              id="filter"
                              onClick={() => {
                                setpixkeyUser(key_pix);
                                setdeleteModalKeyPix(true);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  ) : (
                    <TableCell colSpan={TablePixTypeAndKey.length}>
                      <Padding pt={24} pb={24} pl={12}>
                        <div
                          style={{
                            height: "300px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <h4>Nenhuma Chave Cadastrada</h4>
                        </div>
                      </Padding>
                    </TableCell>
                  )}
                </TableBody>
              </Table>
              <Margin mb={400} />
              {/* <Pagination
                    totalItems={paginatedFunds?.length}
                    pageSize={itemsPerPageAreaProfile}
                    pageSizes={[5, 10, 20, 30, 40, 50]}
                    onChange={({ page, pageSize }) => {
                      setCurrentPageAreaProfile(page);
                      setItemsPerPageProfile(pageSize);
                    }}
                    page={currentPageProfile}
                    size="md"
                  /> */}
            </TableContainer>
            <Margin mt={50} />
          </TabPane>
        </Tabs>
      </div>
      <NewAccountModal
        open={editModalBeneficiary}
        onClose={() => setEditModalBeneficiary(false)}
      ></NewAccountModal>
      <DeleteAccountBeneficiary
        onClose={() => setDeleteModalAccountBeneficiary(false)}
        open={deleteAccountModalBeneficiary}
      ></DeleteAccountBeneficiary>
      <EditBankAccountBeneficiaryModal
        open={editAccountModalBeneficiary}
        onClose={() => seteditAccountModalBeneficiary(false)}
      ></EditBankAccountBeneficiaryModal>
      <AddModalPixkey
        open={addkeyPixModal}
        onClose={() => setaddkeyPixModal(false)}
      ></AddModalPixkey>
      <DeleteModalKeyPix
        open={deleteModalKeyPix}
        onClose={() => setdeleteModalKeyPix(false)}
      ></DeleteModalKeyPix>
      <ApproveBeneficiaryModal
        open={openApproveBeneficiaryModal}
        onClose={() => setOpenApproveBeneficiaryModal(false)}
      />
      <ApproveModalAccountBeneficiary
        open={appApproveAccountBeneficiaryModal}
        onClose={() => setAppApproveAccountBeneficiaryModal(false)}
      ></ApproveModalAccountBeneficiary>
      <ReproveBeneficiaryModal
        open={openReproveBeneficiaryModal}
        onClose={() => setOpenReproveBeneficiaryModal(false)}
      />
      <AddBeneficiaryFundModal
        open={addBeneficiaryFundModal}
        onClose={() => setAddBeneficiaryFundModal(false)}
      />
      <ApproveFundBeneficiaryModal
        open={approveBeneficiaryFundModal}
        onClose={() => setApproveBeneficiaryFundModal(false)}
      />
      <ReproveFundBeneficiaryModal
        open={reproveBeneficiaryFundModal}
        onClose={() => setReproveBeneficiaryFundModal(false)}
      />
    </>
  );
};

export default BeneficiaryDetails;
