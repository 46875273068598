import ApiService from "../api";

type IGetTokenBody = {
  username: string;
  password: string;
};

type IGetRefreshTokenBody = {
  refresh: string | undefined;
};

export const getTokenAPI = (data: IGetTokenBody) => {
  return ApiService.HttpPost<TokenResponse>({
    route: "token/",
    body: data,
  });
};

export const getRefreshTokenAPI = (data: IGetRefreshTokenBody) => {
  return ApiService.HttpPost<TokenRefreshResponse>({
    route: "token/refresh/",
    body: data,
  });
};
