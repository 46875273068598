import { Modal } from "@carbon/react";

import Margin from "~/components/Margin";

import { useCallback } from "react";
import useLoading from "~/hooks/useLoading";

import { deleteAttendTypeAPI, getAttendTypeAPI } from "~/services/api/type";
import useAttendType from "~/hooks/useType";
import { toast } from "react-toastify";

const DeleteAttendTypeContentModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();
  const { setAttendTypes, attendTypesDetails, setOpenDeleteAttendTypeModal } =
    useAttendType();

  const notifySuccess = () =>
    toast.success("Tipo de atendimento excluído com sucesso!");
  const notifyError = () =>
    toast.error(
      "Não foi possível excluir o tipo de atendimento pois ele está associado a uma área."
    );

  const fetchAttendTypes = useCallback(async () => {
    try {
      const { data } = await getAttendTypeAPI();
      setAttendTypes(data.results);
      console.log(data);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setAttendTypes]);

  const fetchDeleteAttendType = async (id?: number) => {
    setLoading(true);
    try {
      await deleteAttendTypeAPI(String(id));

      await fetchAttendTypes();

      setLoading(false);
      notifySuccess();
      setOpenDeleteAttendTypeModal(false);
    } catch (error) {
      console.error("Erro ao excluir tipo de atendimento:", error);
      setLoading(false);
      notifyError();
    }
  };

  return (
    <Modal
      style={{  zIndex: 6 }}
      modalHeading="Excluir tipo de atendimento"
      size="sm"
      open={open}
      onRequestClose={() => {
        onClose();
      }}
      primaryButtonText="Sim, excluir"
      secondaryButtonText="Cancelar"
      onRequestSubmit={async () => {
        fetchDeleteAttendType(attendTypesDetails?.id);
      }}
    >
      <h3>
        Deseja realmente excluir o tipo de atendimento{" "}
        {attendTypesDetails?.name} ?
      </h3>
      <Margin mb={124} />
    </Modal>
  );
};

export default DeleteAttendTypeContentModal;
