import { useCallback, useEffect, useMemo, useState } from "react";
import { ComboBox, InlineNotification, Modal } from "@carbon/react";
import useLoading from "~/hooks/useLoading";
import useTask from "~/hooks/useTask";
import { getTasksAPI, patchTaskAPI } from "~/services/api/task";
import * as Yup from "yup";
import { useFormik } from "formik";
import { IArea, IAttendAreaProfile } from "~/types";
import useArea from "~/hooks/useArea";
import { getAttendAreaProfilesAPI } from "~/services/api/attendAreaProfile";
import { getAreasAPI } from "~/services/api/areas";

type Values = {
  area: string;
  owner: string;
};

const validationSchema = Yup.object({
  area: Yup.string(),
  owner: Yup.string(),
});

const typeDict: { [key: string]: string } = {
  area_manager: "Gestor de área",
  internal: "Atendente",
};

const UpdateOwnerTaskModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const {
    taskDetails,
    setOpenEditTaskModal,
    setTasks,
    setOpenUpdateOwnerTaskModal,
  } = useTask();
  const [error, setError] = useState<boolean>(false);
  const { setLoading } = useLoading();
  const { areas, setAreas, setAttendAreaProfiles, attendAreaProfiles } =
    useArea();

  const initialValues: Values = {
    area: "",
    owner: "",
  };

  const { values, setFieldValue } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {},
  });

  const handleSelectArea = (data: {
    selectedItem?: { id: string; text: string } | null;
  }) => {
    if (data.selectedItem) {
      setFieldValue("area", data.selectedItem.id);
    }
  };

  const handleSelectOwner = (data: {
    selectedItem?: { id: string; text: string } | null;
  }) => {
    if (data.selectedItem) {
      setFieldValue("owner", data.selectedItem.id);
    }
  };

  const fetchAreas = async () => {
    try {
      const { data } = await getAreasAPI();
      setAreas(data.results);
    } catch (e: unknown) {
      console.log(e);
    }
  };

  const areaOptions = useMemo(() => {
    return areas?.map((area: IArea) => ({
      id: String(area.id),
      text: area.name,
    }));
  }, [areas]);

  const fetchAttendAreaProfiles = useCallback(async () => {
    try {
      const { data } = await getAttendAreaProfilesAPI();
      setAttendAreaProfiles(data.results);
    } catch (e) {
      console.log(e);
    }
  }, [setAttendAreaProfiles]);

  const filteredAreaProfiles = useMemo(
    () =>
      attendAreaProfiles.filter((attendAreaProfile: IAttendAreaProfile) => {
        return attendAreaProfile.area === Number(values.area);
      }),
    [attendAreaProfiles, values.area]
  );

  const ownerOptions = useMemo(() => {
    return filteredAreaProfiles?.map((areaProfile: IAttendAreaProfile) => ({
      id: String(areaProfile.profile.id),
      text: `${areaProfile.profile.name} - ${typeDict[areaProfile.profile.type]}`,
    }));
  }, [filteredAreaProfiles]);

  const fetchTasks = useCallback(async () => {
    try {
      const { data } = await getTasksAPI();
      setTasks(data.results.reverse().sort((a, b) => b.id - a.id));
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setTasks]);

  const fetchEditTask = useCallback(async () => {
    setLoading(true);
    try {
      if (taskDetails) {
        await patchTaskAPI(taskDetails.id, { owner: Number(values.owner) });
        await fetchTasks();
        setOpenUpdateOwnerTaskModal(false);
        setLoading(false);
        setError(false);
        // setTimeout(() => {
        //   setSuccessDeleteFile(false);
        // }, 5000);
      }
    } catch (e) {
      console.log(e);
      setError(true);
      setLoading(false);
    }
  }, [setLoading, setOpenEditTaskModal, taskDetails, fetchTasks, values.owner]);

  useEffect(() => {
    fetchAttendAreaProfiles();
    fetchAreas();
  }, []);
  return (
    <Modal
      modalHeading={`Atribuir responsável - ${taskDetails?.name}`}
      size="sm"
      open={open}
      primaryButtonDisabled={values.owner === ""}
      onRequestClose={() => {
        onClose();
        setError(false);
      }}
      primaryButtonText="Concluir"
      secondaryButtonText="Cancelar"
      onRequestSubmit={() => fetchEditTask()}
      style={{ zIndex: 2 }}
    >
      <div style={{ height: "300px", padding: "20px" }}>
        {error && (
          <InlineNotification
            title="Erro!"
            // subtitle={error}
            subtitle="Não foi possivel atribuir o usuário à tarefa"
            hideCloseButton
            kind="error"
            lowContrast
          />
        )}
        <ComboBox
          id="area"
          titleText="Selecione a àrea do responsável"
          placeholder="Pesquisar ou selecionar a àrea do responsável..."
          items={areaOptions}
          itemToString={(item) => (item ? item.text : "")}
          onChange={handleSelectArea}
        />
        <ComboBox
          id="owner"
          titleText="Selecione o responsável pela tarefa"
          placeholder="Pesquisar ou selecionar um responsável..."
          items={ownerOptions}
          itemToString={(item) => (item ? item.text : "")}
          onChange={handleSelectOwner}
        />
      </div>
    </Modal>
  );
};

export default UpdateOwnerTaskModal;
