import constate from "constate";
import { useState } from "react";
import { IBeneficiaryFund } from "~/services/api/beneficiaries";
import { BankAccount, IBeneficiary, PixKey } from "~/types";

const [BeneficiaryProvider, useBeneficiary] = constate(() => {
  const [Beneficiaries, setBeneficiaries] = useState<IBeneficiary[]>([]);
  const [openModalDeleteBeneficiary, setOpenModalDeleteBeneficiary] =
    useState<boolean>(false);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState<IBeneficiary>();
  const [editModalBeneficiary, setEditModalBeneficiary] =
    useState<boolean>(false);

  const [editStatusBeneficiaryModal, setEditStatusBeneficiaryModal] =
    useState<boolean>(false);

  const [addBeneficiaryFundModal, setAddBeneficiaryFundModal] =
    useState<boolean>(false);

  const [approveBeneficiaryFundModal, setApproveBeneficiaryFundModal] =
    useState<boolean>(false);

  const [reproveBeneficiaryFundModal, setReproveBeneficiaryFundModal] =
    useState<boolean>(false);

  const [beneficiaryFundDetails, setBeneficiaryFundDetails] =
    useState<IBeneficiaryFund>();

  const [fundsBeneficiaryRender, setFundsBeneficiaryRender] =
    useState<IBeneficiaryFund[]>();

  // Contas

  const [accountBeneficiary, setAccountBeneficiary] = useState<BankAccount>();
  const [deleteAccountModalBeneficiary, setDeleteModalAccountBeneficiary] =
    useState<boolean>(false);
  const [editAccountModalBeneficiary, seteditAccountModalBeneficiary] =
    useState<boolean>(false);

  //chaves pix

  const [addkeyPixModal, setaddkeyPixModal] = useState<boolean>(false);
  const [pixkeyUser, setpixkeyUser] = useState<PixKey>();
  const [deleteModalKeyPix, setdeleteModalKeyPix] = useState<boolean>(false);
  const [editModalKeyPix, seteditModalKeyPix] = useState<boolean>(false);

  //aprovar beneficario // reprovar

  const [openApproveBeneficiaryModal, setOpenApproveBeneficiaryModal] =
    useState<boolean>(false);

  const [openReproveBeneficiaryModal, setOpenReproveBeneficiaryModal] =
    useState<boolean>(false);

  const [
    appApproveAccountBeneficiaryModal,
    setAppApproveAccountBeneficiaryModal,
  ] = useState<boolean>(false);

  return {
    reproveBeneficiaryFundModal,
    setReproveBeneficiaryFundModal,
    fundsBeneficiaryRender,
    setFundsBeneficiaryRender,
    beneficiaryFundDetails,
    setBeneficiaryFundDetails,
    approveBeneficiaryFundModal,
    setApproveBeneficiaryFundModal,
    addBeneficiaryFundModal,
    setAddBeneficiaryFundModal,
    editStatusBeneficiaryModal,
    setEditStatusBeneficiaryModal,
    Beneficiaries,
    setBeneficiaries,
    openModalDeleteBeneficiary,
    setOpenModalDeleteBeneficiary,
    setBeneficiaryDetails,
    beneficiaryDetails,
    setEditModalBeneficiary,
    editModalBeneficiary,
    setDeleteModalAccountBeneficiary,
    deleteAccountModalBeneficiary,
    seteditAccountModalBeneficiary,
    editAccountModalBeneficiary,
    setAccountBeneficiary,
    accountBeneficiary,
    setaddkeyPixModal,
    addkeyPixModal,
    setpixkeyUser,
    pixkeyUser,
    setdeleteModalKeyPix,
    deleteModalKeyPix,
    seteditModalKeyPix,
    editModalKeyPix,
    openApproveBeneficiaryModal,
    setOpenApproveBeneficiaryModal,
    openReproveBeneficiaryModal,
    setOpenReproveBeneficiaryModal,
    setAppApproveAccountBeneficiaryModal,
    appApproveAccountBeneficiaryModal,
  };
});

export { BeneficiaryProvider };

export default useBeneficiary;
