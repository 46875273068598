import constate from "constate";
import { useState } from "react";
import { IActualUser, IProfiles } from "~/types";

const [UserProvider, useUser] = constate(() => {
  const [user, setUser] = useState<IActualUser>();
  const [typeUserProfile, setTypeUserProfile] =
    useState<string>("tool_manager");

  const [userProfile, setUserProfile] = useState<IProfiles>();
  const [pass, setPass] = useState<string>("");

  const [openChangePasswordModal, setOpenChangePasswordModal] =
    useState<boolean>(false);

  const [isFirstLogin, setIsFirstLogin] = useState<boolean>(false);

  return {
    user,
    setUser,
    userProfile,
    setUserProfile,
    setTypeUserProfile,
    typeUserProfile,
    openChangePasswordModal,
    setOpenChangePasswordModal,
    pass,
    setPass,
    isFirstLogin,
    setIsFirstLogin,
  };
});

export { UserProvider };

export default useUser;
