import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Grid,
  Column,
  Button,
  Pagination,
} from "@carbon/react";

import { TrashCan, Renew, Security } from "@carbon/icons-react";
import Margin from "~/components/Margin";

import { useCallback, useEffect, useMemo, useState } from "react";
import useLoading from "~/hooks/useLoading";

// import { useFormik } from "formik";
import Padding from "~/components/Padding";
import useArea from "~/hooks/useArea";
import { useNavigate, useParams } from "react-router-dom";
import { permissions } from "~/services/permissions";

import { formatCPFCNPJ } from "~/services/utils";
import { Breadcrumb } from "prosperita-dumbo-react";
import "prosperita-dumbo-react/dist/style.css";
import useCompany from "~/hooks/useCompany";
import { getCompanyAPI } from "~/services/api/companys";
import useUser from "~/hooks/useUser";
import { Tabs } from "antd";
import { getProfilesAPI } from "~/services/api/profile";
import useProfile from "~/hooks/useProfile";
import { getCompanyFundAPI } from "~/services/api/companyFund";
import AddFundsInCompany from "./AddFundsInCompany";
import AddProfilesInCompany from "./AddProfilesInCompany";
import Badge from "~/components/Badge";
import RemoveFundCompany from "./RemoveFundCompany";

const tableHeaderFunds: string[] = [
  "Nome (Abreviado)",
  "CNPJ",
  "Nome (Completo)",
  "Status",
  "Autenticação",
  "",
  "",
];

const tableHeaderProfiles: string[] = ["Nome", "CPF", "Tipo", "Status", ""];

const CompanyDetails = () => {
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const { id } = useParams();
  const { setAreaDetails } = useArea();

  const { setProfilesInCompany, profilesInCompany } = useProfile();

  const {
    setCompanyDetails,
    companyDetails,
    companyFunds,
    setCompanyFunds,
    setOpenAddFundsCompanyModal,
    openAddFundsCompanyModal,
    setOpenAddProfileCompanyModal,
    openAddProfileCompanyModal,
    openRemoveFundsCompanyModal,
    setOpenRemoveFundsCompanyModal,
    setFundCompanyDetails,
  } = useCompany();
  const { userProfile } = useUser();
  const { TabPane } = Tabs;

  const canReadAreas = permissions.includes("can_read_areas");

  const [currentPageProfiles, setCurrentPageProfiles] = useState(1);
  const [itemsPerPageProfiles, setItemsPerPageProfiles] = useState(20);

  const [currentPageProfile, setCurrentPageAreaProfile] = useState(1);
  const [itemsPerPageAreaProfile, setItemsPerPageProfile] = useState(20);

  // const { values } = useFormik({
  //   initialValues: {
  //     search: "",
  //   },
  //   onSubmit: (values: { search: string }) => {
  //     console.log(values);
  //   },
  // });

  const fetchProfilesInCompany = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getProfilesAPI({
        mode: "list",
        company: Number(id),
      });
      setProfilesInCompany(data.results.reverse());

      setLoading(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, []);

  const fetchCompanyFunds = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getCompanyFundAPI(Number(id));
      setCompanyFunds(data.results.reverse());

      setLoading(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, []);

  const fetchCompany = useCallback(async () => {
    try {
      const { data } = await getCompanyAPI(Number(id));
      setCompanyDetails(data);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [id, setCompanyDetails]);

  const paginatedProfiles = useMemo(() => {
    const start = (currentPageProfiles - 1) * itemsPerPageProfiles;
    const end = start + itemsPerPageProfiles;
    return profilesInCompany
      ?.sort((a, b) =>
        a.name.localeCompare(b.name, "pt-BR", { sensitivity: "base" })
      )
      ?.slice(start, end);
  }, [currentPageProfiles, itemsPerPageProfiles, profilesInCompany]);

  const paginatedFunds = useMemo(() => {
    const start = (currentPageProfile - 1) * itemsPerPageAreaProfile;
    const end = start + itemsPerPageAreaProfile;
    return (
      companyFunds &&
      companyFunds
        ?.sort((a, b) =>
          a.fund.name.localeCompare(b.fund.name, "pt-BR", {
            sensitivity: "base",
          })
        )
        ?.slice(start, end)
    );
  }, [companyFunds, currentPageProfile, itemsPerPageAreaProfile]);

  const allFetches = useCallback(async () => {
    setLoading(true);
    await fetchCompany();
    await fetchCompanyFunds();
    await fetchProfilesInCompany();

    setLoading(false);
  }, [fetchCompany, fetchProfilesInCompany, setLoading, fetchCompanyFunds]);

  useEffect(() => {
    setCurrentPageProfiles(1);
    setItemsPerPageProfiles(5);

    allFetches();
    return () => {
      setAreaDetails(undefined);
    };
  }, [allFetches, setAreaDetails]);

  return (
    <Grid>
      {canReadAreas ? (
        <Column span={16}>
          <Padding y={24}>
            <Margin mt={20} />
            {/* <Breadcrumb>
                <BreadcrumbItem onClick={() => navigate("/app/areas/")}>
                  Áreas
                </BreadcrumbItem>
                <BreadcrumbItem onClick={() => navigate(`/app/areas/${id}`)}>
                  {areaDetails?.name ?? "Detalhes"}
                </BreadcrumbItem>
              </Breadcrumb> */}
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                fontWeight: 100,
                alignItems: "center",
                margin: 0,
                padding: 0,
              }}
            >
              <Breadcrumb
                crumbsOptions={[
                  {
                    crumb: "Empresas",
                    onClick: () => navigate("/app/companys/"),
                  },
                  {
                    crumb: companyDetails?.raw_data?.brand_name ?? "Detalhes",
                    onClick: () => navigate(`/app/companys/${id}`),
                  },
                ]}
                endHiddenIndex={0}
                startHiddenIndex={5}
              />
            </div>
          </Padding>
          <h2>{companyDetails?.raw_data?.brand_name}</h2>
          <h4>
            {companyDetails?.raw_data?.document_number &&
              formatCPFCNPJ(companyDetails?.raw_data?.document_number)}
          </h4>
          <h4>{companyDetails?.raw_data?.company_type?.name}</h4>
          <Tabs
            defaultActiveKey="1"
            tabBarStyle={{
              margin: 0,
              borderBottom: "none",
              color: "red",
            }}
          >
            <TabPane tab="Fundos" key="1">
              <Margin mt={34} /> <Margin mt={20} />
              <TableContainer title="Fundos">
                <Margin mb={-20} />
                <Margin mt={20} />{" "}
                <TableToolbar>
                  <Button
                    tabIndex={0}
                    onClick={() => {
                      fetchCompanyFunds();
                    }}
                    kind="secondary"
                    renderIcon={Renew}
                  >
                    Atualizar
                  </Button>

                  {/* {!canReadAreas && (
                <div
                  style={{
                    height: "400px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <h4 style={{ position: "relative", left: "45%" }}>
                    Você não possui permissão para visualizar as áreas
                  </h4>
                </div>
              )} */}

                  <TableToolbarContent>
                    {/* <TableToolbarSearch
                    tabIndex={0}
                    onChange={handleChange}
                    value={values.search}
                    id="search"
                    placeholder="Pesquisar"
                  /> */}
                    {userProfile?.type === "tool_manager" && (
                      <Button
                        tabIndex={0}
                        onClick={() => {
                          setOpenAddFundsCompanyModal(true);
                        }}
                        kind="primary"
                      >
                        Atribuir fundo
                      </Button>
                    )}
                  </TableToolbarContent>
                </TableToolbar>
                <Table>
                  <TableHead>
                    <TableRow>
                      {/* <TableSelectAll
                      name={""}
                      id={""}
                      onSelect={console.log}
                      checked={false}
                      ariaLabel={""}
                    /> */}
                      {tableHeaderFunds.map((columnTitle, index) => {
                        return (
                          <TableHeader key={String(index)}>
                            {columnTitle}
                          </TableHeader>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedFunds?.length === 0 ? (
                      <TableCell colSpan={tableHeaderFunds.length + 1}>
                        <Padding pt={24} pb={24} pl={12}>
                          <div
                            style={{
                              height: "300px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <h4>
                              Esta empresa não possui nenhum fundo atribuído
                            </h4>
                          </div>
                        </Padding>
                      </TableCell>
                    ) : paginatedFunds && paginatedFunds?.length > 0 ? (
                      paginatedFunds?.map((companyFund, index) => (
                        <TableRow
                          key={String(index)}
                          style={{ cursor: "pointer" }}
                        >
                          {/* <TableSelectRow
                          name={""}
                          id={""}
                          onSelect={console.log}
                          checked={false}
                        /> */}
                          <TableCell>
                            {companyFund?.fund?.raw_data?.short_name}
                          </TableCell>

                          <TableCell>
                            {formatCPFCNPJ(
                              companyFund?.fund?.raw_data.document_number
                            )}
                          </TableCell>
                          <TableCell>{companyFund.fund.name}</TableCell>
                          <TableCell style={{ minWidth: "120px" }}>
                            {companyFund.fund?.raw_data?.active ? (
                              <Badge
                                color="#CCEFDF"
                                textColor="#00B360"
                                label="Ativo"
                              />
                            ) : (
                              <Badge
                                label="Inativo"
                                color="#e3e3e3"
                                textColor="#696868"
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            {companyFund.fund.raw_data.authenticated ? (
                              <Badge
                                color="#bacbff"
                                textColor="#1906ec"
                                label="Autenticado"
                              />
                            ) : (
                              <Badge
                                label="Não autenticado"
                                color="#e3e3e3"
                                textColor="#696868"
                              />
                            )}
                          </TableCell>

                          <TableCell>
                            <Button
                              kind="ghost"
                              hasIconOnly
                              iconDescription={
                                companyFund.fund.raw_data.authenticated
                                  ? "Re-autenticar"
                                  : "Autenticar"
                              }
                              renderIcon={Security}
                              id="filter"
                              onClick={(e) => {
                                e.stopPropagation();
                                // setOpenEditFundModal(true);
                                // setFundDetails(fund);
                              }}
                            />
                          </TableCell>

                          <TableCell>
                            {/* <Button
                            kind="ghost"
                            hasIconOnly
                            iconDescription="Detalhes"
                            renderIcon={View}
                            id="filter"
                          /> */}
                            {userProfile?.type.includes("tool_manager") && (
                              <Button
                                kind="ghost"
                                hasIconOnly
                                iconDescription="Remover"
                                renderIcon={TrashCan}
                                id="filter"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setFundCompanyDetails(companyFund);
                                  setOpenRemoveFundsCompanyModal(true);
                                }}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      paginatedFunds?.map((companyFund, index) => (
                        <TableRow
                          key={String(index)}
                          style={{ cursor: "pointer" }}
                        >
                          {/* <TableSelectRow
                          name={""}
                          id={""}
                          onSelect={console.log}
                          checked={false}
                        /> */}
                          <TableCell>{companyFund?.fund?.name}</TableCell>
                          <TableCell>
                            {companyFund?.fund?.raw_data?.document_number}
                          </TableCell>
                          <TableCell>
                            {companyFund?.fund?.raw_data?.short_name}
                          </TableCell>

                          <TableCell>
                            {" "}
                            <Button
                              kind="ghost"
                              hasIconOnly
                              renderIcon={TrashCan}
                              onClick={(e) => {
                                e.stopPropagation();
                                setFundCompanyDetails(companyFund);
                                setOpenRemoveFundsCompanyModal(true);
                              }}
                              id="filter"
                            ></Button>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
                <Pagination
                  totalItems={paginatedFunds?.length}
                  pageSize={itemsPerPageAreaProfile}
                  pageSizes={[5, 10, 20, 30, 40, 50]}
                  onChange={({ page, pageSize }) => {
                    setCurrentPageAreaProfile(page);
                    setItemsPerPageProfile(pageSize);
                  }}
                  page={currentPageProfile}
                  size="md"
                />
              </TableContainer>
              <Margin mt={50} />
            </TabPane>
            {(userProfile?.type === "tool_manager" ||
              userProfile?.type === "manager") && (
              <TabPane tab="Usuários" key="2">
                {" "}
                <Margin mt={34} /> <Margin mt={20} />
                <TableContainer title="Usuários">
                  <Margin mb={-20} />
                  <Margin mt={20} />{" "}
                  <TableToolbar>
                    <Button
                      tabIndex={0}
                      onClick={() => {
                        fetchProfilesInCompany();
                      }}
                      kind="secondary"
                      renderIcon={Renew}
                    >
                      Atualizar
                    </Button>

                    {/* {!canReadAreas && (
                <div
                  style={{
                    height: "400px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <h4 style={{ position: "relative", left: "45%" }}>
                    Você não possui permissão para visualizar as áreas
                  </h4>
                </div>
              )} */}

                    <TableToolbarContent>
                      {/* <TableToolbarSearch
                    tabIndex={0}
                    onChange={handleChange}
                    value={values.search}
                    id="search"
                    placeholder="Pesquisar"
                  /> */}

                      <Button
                        tabIndex={0}
                        onClick={() => {
                          setOpenAddProfileCompanyModal(true);
                        }}
                        kind="primary"
                      >
                        Novo usuário
                      </Button>
                    </TableToolbarContent>
                  </TableToolbar>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {/* <TableSelectAll
                      name={""}
                      id={""}
                      onSelect={console.log}
                      checked={false}
                      ariaLabel={""}
                    /> */}
                        {tableHeaderProfiles.map((columnTitle, index) => {
                          return (
                            <TableHeader key={String(index)}>
                              {columnTitle}
                            </TableHeader>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedProfiles?.length === 0 ? (
                        <TableCell colSpan={tableHeaderFunds.length + 1}>
                          <Padding pt={24} pb={24} pl={12}>
                            <div
                              style={{
                                height: "300px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <h4>
                                Esta empresa não possui nenhum usuário atribuído
                              </h4>
                            </div>
                          </Padding>
                        </TableCell>
                      ) : paginatedProfiles && paginatedProfiles?.length > 0 ? (
                        paginatedProfiles?.map((companyProfiles, index) => (
                          <TableRow
                            key={String(index)}
                            style={{ cursor: "pointer" }}
                          >
                            {/* <TableSelectRow
                          name={""}
                          id={""}
                          onSelect={console.log}
                          checked={false}
                        /> */}

                            <TableCell>{companyProfiles.name}</TableCell>
                            <TableCell>
                              {formatCPFCNPJ(companyProfiles?.document_number)}
                            </TableCell>
                            <TableCell>
                              {companyProfiles.type === "manager"
                                ? "Master"
                                : "Comum"}
                            </TableCell>
                            <TableCell>
                              <div style={{ maxWidth: "100px" }}>
                                {" "}
                                {companyProfiles.raw_data?.first_login ? (
                                  <Badge
                                    color="#bacbff"
                                    textColor="#1906ec"
                                    label="Convite enviado"
                                  />
                                ) : (
                                  <Badge
                                    color="#CCEFDF"
                                    textColor="#00B360"
                                    label="Ativo"
                                  />
                                )}
                              </div>
                            </TableCell>
                            <TableCell>
                              {/* <Button
                            kind="ghost"
                            hasIconOnly
                            iconDescription="Detalhes"
                            renderIcon={View}
                            id="filter"
                          /> */}

                              {/* {userProfile?.type === "tool_manager" && (
                                <Button
                                  kind="ghost"
                                  hasIconOnly
                                  iconDescription="Remover"
                                  renderIcon={TrashCan}
                                  id="filter"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    // // setAttendAreaProfileDetails(companyFund);
                                    // setOpenDeleteAttendAreaProfileModal(true);
                                  }}
                                />
                              )} */}
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        paginatedProfiles?.map((companyProfile, index) => (
                          <TableRow
                            key={String(index)}
                            style={{ cursor: "pointer" }}
                          >
                            {/* <TableSelectRow
                          name={""}
                          id={""}
                          onSelect={console.log}
                          checked={false}
                        /> */}
                            <TableCell>{companyProfile?.name}</TableCell>
                            <TableCell>
                              {companyProfile?.document_number}
                            </TableCell>
                            <TableCell>{companyProfile.type}</TableCell>

                            <TableCell>
                              {" "}
                              <Button
                                kind="ghost"
                                hasIconOnly
                                renderIcon={TrashCan}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  // // setAttendAreaProfileDetails(companyFund);
                                  // setOpenDeleteAttendAreaProfileModal(true);
                                  console.log(companyProfile);
                                }}
                                id="filter"
                              ></Button>
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                  <Pagination
                    totalItems={paginatedFunds?.length}
                    pageSize={itemsPerPageAreaProfile}
                    pageSizes={[5, 10, 20, 30, 40, 50]}
                    onChange={({ page, pageSize }) => {
                      setCurrentPageAreaProfile(page);
                      setItemsPerPageProfile(pageSize);
                    }}
                    page={currentPageProfile}
                    size="md"
                  />
                </TableContainer>
                <Margin mt={50} />
              </TabPane>
            )}
          </Tabs>
        </Column>
      ) : (
        <div
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "500px",
            position: "relative",
            left: "520%",
            bottom: "10%",
          }}
        >
          {" "}
          <h4> Você não possui permissão para visualizar empresas</h4>
        </div>
      )}
      <AddFundsInCompany
        open={openAddFundsCompanyModal}
        onClose={() => setOpenAddFundsCompanyModal(false)}
      />

      <AddProfilesInCompany
        open={openAddProfileCompanyModal}
        onClose={() => setOpenAddProfileCompanyModal(false)}
      />
      <RemoveFundCompany
        open={openRemoveFundsCompanyModal}
        onClose={() => setOpenRemoveFundsCompanyModal(false)}
      />
    </Grid>
  );
};

export default CompanyDetails;
