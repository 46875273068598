import { Modal } from "@carbon/react";
import Margin from "~/components/Margin";
import { useCallback, useEffect } from "react";
import useLoading from "~/hooks/useLoading";
import useBeneficiary from "~/hooks/useBeneficiary";
import { toast } from "react-toastify";
import useUser from "~/hooks/useUser";

import {
  getBeneficiaryAPI,
  IBodyBeneficiary,
  patchBeneficiaryAPI,
} from "~/services/api/beneficiaries";

import { useParams } from "react-router-dom";
import { getBanks } from "~/services/api/banks";
import useBanks from "~/hooks/useBanks";
import { BankAccount } from "~/types";

// const clientTypeDisplay: { [key: string]: string } = {
//   client: "Cliente",
//   internal: "Atendente",
//   area_manager: "Gerente de àrea",
//   tool_manager: "Gerente de ferramenta",
// };

const DeleteAccountBeneficiary = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();
  const { banks, setBanks } = useBanks();
  const {
    setBeneficiaryDetails,
    beneficiaryDetails,
    setDeleteModalAccountBeneficiary,
    accountBeneficiary,
  } = useBeneficiary();
  banks;
  const notifySuccess = () =>
    toast.success("conta bancária removida com sucesso!");
  const notifyError = () =>
    toast.error("erro ao remover conta bancária. Tente novamente mais tarde");

  const { id } = useParams();
  const { userProfile } = useUser();

  const fetchBeneficiary = useCallback(async () => {
    setLoading(true);
    try {
      const data = await getBeneficiaryAPI(Number(id));
      setBeneficiaryDetails(data.data);

      setLoading(false);
    } catch {
      console.log("erro ao fazer requisição");
    }
  }, [setBeneficiaryDetails]);

  useEffect(() => {
    fetchBeneficiary();
  }, [fetchBeneficiary, setBeneficiaryDetails]);

  const fetchBanks = useCallback(async () => {
    try {
      const { data } = await getBanks();
      setBanks(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    fetchBanks();
  }, []);

  // const BanksFilter = banks.reduce(
  //   (acc, items_) => {
  //     if (acc) {
  //       acc[items_.id] = items_.description;
  //     }
  //     return acc;
  //   },
  //   {} as { [key: string]: string }
  // );

  const UpdateBeneficiary = (IDaccount: number) => {
    if (beneficiaryDetails) {
      const updatedBankAccounts: BankAccount[] = (
        beneficiaryDetails?.bank_account || []
      )
        .flatMap((account) => (Array.isArray(account) ? account : [account]))
        .filter((account) => account.id !== IDaccount);
      fetchDeleteAccount(updatedBankAccounts);
    }
  };

  const fetchDeleteAccount = useCallback(
    async (updatedBankAccounts: BankAccount[]) => {
      const updatedPayload: IBodyBeneficiary = {
        name: beneficiaryDetails?.name || "",
        document_number: beneficiaryDetails?.document_number || "",
        bank_account: updatedBankAccounts,
        profile: userProfile?.id || 0,
        pix_keys: beneficiaryDetails?.pix_keys,
      };

      setLoading(true);
      try {
        await patchBeneficiaryAPI(Number(id), updatedPayload);
        setDeleteModalAccountBeneficiary(false);
        await fetchBeneficiary();
        notifySuccess();
        setLoading(false);
        console.warn("Requisição Realizada");
      } catch {
        notifyError();
        setLoading(false);
        console.log("Erro Ao Deletar");
      }
    },
    [beneficiaryDetails, userProfile, id]
  );

  return (
    <Modal
      modalHeading="Remover conta bancária"
      style={{ zIndex: 6 }}
      size="md"
      open={open}
      onRequestClose={onClose}
      primaryButtonText="Sim, remover"
      secondaryButtonText="Cancelar"
      onRequestSubmit={async () => {
        UpdateBeneficiary(Number(accountBeneficiary?.id));
      }}
    >
      <>
        <Margin mt={20} />
        <h3 style={{ textAlign: "center" }}>
          Deseja realmente remover a conta bancária abaixo ?
        </h3>
        <Margin mt={30} />
        <h4>
          <span style={{ fontWeight: "bold" }}>Banco: </span>{" "}
          {accountBeneficiary?.bank_name}
        </h4>
        <h4>
          {" "}
          <span style={{ fontWeight: "bold" }}>Agencia: </span>{" "}
          {accountBeneficiary?.agency}
        </h4>
        <h4>
          {" "}
          <span style={{ fontWeight: "bold" }}>Conta: </span>{" "}
          {accountBeneficiary?.account_number}-{" "}
          {accountBeneficiary?.account_number_digit}
        </h4>
      </>

      <Margin mb={100} />
    </Modal>
  );
};

export default DeleteAccountBeneficiary;
