import { IProfiles } from "~/types";
import ApiService from "../api";

type IPostProfileBody = {
  name: string;
  document_number: string;
  type: string;
  info?: string;
  user: number;
};

type IResponseGetProfiles = {
  count: number;
  next: null;
  previous: null;
  results: IProfiles[];
};

export const getProfilesForAttendAPI = (search: string) => {
  return ApiService.HttpGet<IResponseGetProfiles>({
    route: "profile_for_attend/",
    token: true,
    params: {
      search: search,
    },
  });
};

export const getProfileForAttendAPI = (id: number) => {
  return ApiService.HttpGet<IProfiles>({
    route: `profile_for_attend/${id}/`,
    token: true,
  });
};

export const PostProfileAPI = (data: IPostProfileBody) => {
  return ApiService.HttpPost({
    route: "profile/",
    body: data,
    token: true,
  });
};
