import constate from "constate";
import { useState } from "react";

const [LoadingProvider, useLoading] = constate(() => {
  const [loading, setLoading] = useState<boolean>(false);

  return {
    loading,
    setLoading,
  };
});

export { LoadingProvider };

export default useLoading;
