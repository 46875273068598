import constate from "constate";
import { useState } from "react";
import { ICompanyFund, IFund } from "~/types";

const [FundProvider, useFund] = constate(() => {
  const [funds, setFunds] = useState<IFund[]>([]);

  const [fundsCompany, setFundsCompany] = useState<ICompanyFund[]>([]);
  const [openNewFundModal, setOpenNewFundModal] = useState<boolean>(false);
  const [openDeleteFundModal, setOpenDeleteFundModal] =
    useState<boolean>(false);
  const [openEditFundModal, setOpenEditFundModal] = useState<boolean>(false);
  const [fundDetails, setFundDetails] = useState<IFund>();

  return {
    funds,
    setFunds,
    openNewFundModal,
    setOpenNewFundModal,
    setFundDetails,
    fundDetails,
    setOpenDeleteFundModal,
    openDeleteFundModal,
    setOpenEditFundModal,
    openEditFundModal,
    fundsCompany,
    setFundsCompany,
  };
});

export { FundProvider };

export default useFund;
