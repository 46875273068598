import { Modal } from "@carbon/react";
import Margin from "~/components/Margin";
import { useCallback, useEffect, useMemo } from "react";
import useLoading from "~/hooks/useLoading";
import useBeneficiary from "~/hooks/useBeneficiary";
import { toast } from "react-toastify";
// import useUser from "~/hooks/useUser";

import { useFormik } from "formik";
import {
  getBeneficiariesAPI,
  getBeneficiaryAPI,
  IBodyBeneficiary,
  patchBeneficiaryAPI,
} from "~/services/api/beneficiaries";
import { getBanks } from "~/services/api/banks";
import useBanks from "~/hooks/useBanks";
import { useNavigate, useParams } from "react-router-dom";
// import styled from "styled-components";
import * as Yup from "yup";

// const clientTypeDisplay: { [key: string]: string } = {
//   client: "Cliente",
//   internal: "Atendente",
//   area_manager: "Gerente de àrea",
//   tool_manager: "Gerente de ferramenta",
// };

type IAccount = {
  account_number: string;
  account_number_digit: string;
  agency: string;
  bank: string;
  bank_name: string;
};

type ImescleOptionsAccountPix = IAccount;

// const StyledTextInput = styled(TextInput)`
//   & input[type="number"]::-webkit-inner-spin-button,
//   & input[type="number"]::-webkit-outer-spin-button {
//     -webkit-appearance: none;
//     margin: 0;
//   }

//   & input[type="number"] {
//     -moz-appearance: textfield;
//   }
// `;

const ApproveBeneficiaryModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();
  // const { userProfile } = useUser();
  const { setBanks } = useBanks();
  const { id } = useParams();

  const {
    setBeneficiaries,
    setBeneficiaryDetails,
    beneficiaryDetails,
    setEditModalBeneficiary,
    setOpenApproveBeneficiaryModal,
  } = useBeneficiary();

  const navigate = useNavigate();

  const notifySuccess = () =>
    toast.success("Beneficiário aprovado com sucesso!");
  const notifyError = () =>
    toast.error(
      "não foi possivel aprovar o beneficiário. Tente novamente mais tarde."
    );

  const fetchBanks = useCallback(async () => {
    try {
      const { data } = await getBanks();
      setBanks(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, []);

  const validationSchema = Yup.object({
    bank: Yup.string().required("O banco é obrigatório"),
    agency: Yup.string()
      .matches(/^\d+$/, "A agência deve conter apenas números")
      .required("A agência é obrigatória"),
    account_number: Yup.string()
      .matches(/^\d+$/, "A conta deve conter apenas números")
      .required("O número da conta é obrigatório"),
  });
  const InitialValues: Omit<ImescleOptionsAccountPix, "account_number_digit"> =
    {
      account_number: "",
      agency: "",
      bank: "",
      bank_name: "",
    };
  const { resetForm } = useFormik({
    initialValues: InitialValues,
    validationSchema,
    onSubmit: () => console.log(""),
  });

  const fetchBeneficiaries = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getBeneficiariesAPI();
      console.log(data);
      setBeneficiaries(data.results);
      setLoading(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, []);

  const payload: IBodyBeneficiary = useMemo(() => {
    return {
      name: beneficiaryDetails?.name || "",
      document_number: beneficiaryDetails?.document_number || "",
      bank_account: beneficiaryDetails?.bank_account || [],
      pix_keys: beneficiaryDetails?.pix_keys || [],
      raw_data: {
        status: "approved",
        pending_funds_approvations:
          beneficiaryDetails?.raw_data?.pending_funds_approvations,
      },
    };
  }, [beneficiaryDetails]);

  const fetchBeneficiary = useCallback(async () => {
    try {
      const { data } = await getBeneficiaryAPI(Number(id));
      setBeneficiaryDetails(data);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setBeneficiaries]);

  const fetchPatchBeneficiary = useCallback(
    async (id: number) => {
      setLoading(true);
      try {
        await patchBeneficiaryAPI(id, payload);
        navigate("/app/beneficiaries");
        setOpenApproveBeneficiaryModal(false);
        await fetchBeneficiary();
        await fetchBeneficiaries();
        setEditModalBeneficiary(false);
        setLoading(false);
        notifySuccess();
        resetForm();
      } catch (err: unknown) {
        console.error(err);
        setLoading(false);
        notifyError();
      }
    },
    [payload, fetchBeneficiary]
  );

  useEffect(() => {
    fetchBeneficiaries();
    fetchBeneficiary();
    fetchBanks();
  }, []);

  return (
    <Modal
      style={{ zIndex: 6 }}
      modalHeading="Aprovar Beneficiário"
      size="sm"
      open={open}
      onRequestClose={() => {
        onClose();
        resetForm();
      }}
      primaryButtonText="Sim, aprovar"
      secondaryButtonText="Cancelar"
      onRequestSubmit={async () => {
        fetchPatchBeneficiary(Number(beneficiaryDetails?.id));
      }}
    >
      <Margin mb={10} />
      <h3 style={{ textAlign: "center" }}>
        Deseja realmente aprovar o beneficiário abaixo?
      </h3>
      <Margin mb={10} />

      <Margin mb={40} />
      <h4 style={{ fontWeight: "bold" }}>
        Nome/Razão Social:{" "}
        <span style={{ fontWeight: "normal" }}>{beneficiaryDetails?.name}</span>
      </h4>
      <h4 style={{ fontWeight: "bold" }}>
        CPF/CNPJ:
        <span style={{ fontWeight: "normal" }}>
          {" "}
          {beneficiaryDetails?.document_number || "Não cadastrado"}
        </span>
      </h4>
      <Margin mb={150} />
    </Modal>
  );
};

export default ApproveBeneficiaryModal;
