import { useCallback, useState } from "react";
import { InlineNotification, Modal } from "@carbon/react";
import useLoading from "~/hooks/useLoading";
import useTask from "~/hooks/useTask";
import { deleteTaskAPI, getTasksAPI } from "~/services/api/task";

const DeleteTaskModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { taskDetails, setOpenDeleteTaskModal, setTasks } = useTask();
  const [error, setError] = useState<boolean>(false);
  const { setLoading } = useLoading();

  const fetchTasks = useCallback(async () => {
    try {
      const { data } = await getTasksAPI();
      setTasks(data.results.reverse().sort((a, b) => b.id - a.id));
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setTasks]);

  const fetchDeleteTask = useCallback(async () => {
    setLoading(true);
    try {
      if (taskDetails) {
        await deleteTaskAPI(taskDetails.id);
        await fetchTasks();
        setOpenDeleteTaskModal(false);
        setLoading(false);
        setError(false);
        // setTimeout(() => {
        //   setSuccessDeleteFile(false);
        // }, 5000);
      }
    } catch (e) {
      console.log(e);
      setError(true);
      setLoading(false);
    }
  }, [setLoading, setOpenDeleteTaskModal, taskDetails, fetchTasks]);

  return (
    <Modal
      modalHeading={`Excluir tarefa`}
      size="sm"
      open={open}
      onRequestClose={() => {
        onClose();
        setError(false);
      }}
      primaryButtonText="Sim, excluir"
      secondaryButtonText="Cancelar"
      onRequestSubmit={() => fetchDeleteTask()}
      style={{ zIndex: 2 }}
    >
      <div style={{ height: "300px", padding: "20px" }}>
        {error && (
          <InlineNotification
            title="Erro!"
            // subtitle={error}
            subtitle="Não foi possivel excluir a tarefa"
            hideCloseButton
            kind="error"
            lowContrast
          />
        )}
        <h3 style={{ textAlign: "justify" }}>
          Deseja realmente excluir a tarefa {taskDetails?.name} ?
        </h3>
      </div>
    </Modal>
  );
};

export default DeleteTaskModal;
