import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Grid,
  Column,
  Button,
  Pagination,
} from "@carbon/react";

import { TrashCan } from "@carbon/icons-react";
import Margin from "~/components/Margin";

import { useCallback, useEffect, useMemo, useState } from "react";
import useLoading from "~/hooks/useLoading";

// import { useFormik } from "formik";
import Padding from "~/components/Padding";

import { getAreaAPI } from "~/services/api/areas";
import useArea from "~/hooks/useArea";

import { useNavigate, useParams } from "react-router-dom";
import { getAreaTypesAPI } from "~/services/api/areaTypes";
import useAreaType from "~/hooks/useAreaType";
import { IAreaType, IAttendAreaProfile } from "~/types";
import DeleteAreaTypeModal from "./DeleteAreaTypeModal";
import AssociateAreaTypeModal from "./AssociateAreaTypeModal";
import { permissions } from "~/services/permissions";
import { getAttendAreaProfilesAPI } from "~/services/api/attendAreaProfile";
import AssociateAttendAreaProfileModal from "./AssociateAttendAreaProfileModal";
import DeleteAttendAreaProfileModal from "./DeleteAttendAreaProfileModal";
import { formatCPFCNPJ } from "~/services/utils";
import { Breadcrumb } from "prosperita-dumbo-react";
import "prosperita-dumbo-react/dist/style.css";

const tableHeadersArea: string[] = ["Nome", "SLA", ""];
const tableHeaderProfile: string[] = ["Nome", "CPF", "Tipo", ""];

const typeDict: { [key: string]: string } = {
  internal: "Atendente",
  area_manager: "Gestor de àrea",
};

const AreaDetails = () => {
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    setAreaDetails,
    areaDetails,
    setOpenDeleteAreaTypeModal,
    setAreaTypeDetails,
    openDeleteAreaTypeModal,
    setOpenAssociateAreaTypeModal,
    openAssociateAreaTypeModal,
    setAttendAreaProfiles,
    attendAreaProfiles,
    setAttendAreaProfileDetails,

    setOpenAssociateAttendAreaProfileModal,
    openAssociateAttendAreaProfileModal,
    setOpenDeleteAttendAreaProfileModal,
    openDeleteAttendAreaProfileModal,
  } = useArea();
  const { areaTypes, setAreaTypes } = useAreaType();

  const canReadAreas = permissions.includes("can_read_areas");
  const canEditAreas = permissions.includes("can_edit_areas");

  const [currentPageAreaType, setCurrentPageAreaType] = useState(1);
  const [itemsPerPageAreaTypes, setItemsPerPageAreaTypes] = useState(20);

  const [currentPageAreaProfile, setCurrentPageAreaProfile] = useState(1);
  const [itemsPerPageAreaProfile, setItemsPerPageAreaProfile] = useState(20);

  // const { values } = useFormik({
  //   initialValues: {
  //     search: "",
  //   },
  //   onSubmit: (values: { search: string }) => {
  //     console.log(values);
  //   },
  // });

  const fetchArea = useCallback(async () => {
    try {
      const { data } = await getAreaAPI(String(id));
      setAreaDetails(data);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [id, setAreaDetails]);

  const fetchAreaTypes = useCallback(async () => {
    try {
      const { data } = await getAreaTypesAPI();

      setAreaTypes(data.results.reverse());
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setAreaTypes]);

  const filteredAreaTypes = useMemo(
    () =>
      areaTypes.filter((areaType: IAreaType) => {
        return areaType.area.id === Number(id);
      }),
    [areaTypes, id]
  );

  const fetchAttendAreaProfiles = useCallback(async () => {
    try {
      const { data } = await getAttendAreaProfilesAPI(Number(id));
      setAttendAreaProfiles(data.results.reverse());
    } catch (e) {
      console.log(e);
    }
  }, [setAttendAreaProfiles, id]);

  const filteredAreaProfiles = useMemo(
    () =>
      attendAreaProfiles.filter((attendAreaProfile: IAttendAreaProfile) => {
        return attendAreaProfile.area === Number(id);
      }),
    [attendAreaProfiles, id]
  );

  const paginatedAreaTypes = useMemo(() => {
    const start = (currentPageAreaType - 1) * itemsPerPageAreaTypes;
    const end = start + itemsPerPageAreaTypes;
    return filteredAreaTypes
      ?.sort((a, b) =>
        a.type.name.localeCompare(b.type.name, "pt-BR", {
          sensitivity: "base",
        })
      )
      ?.slice(start, end);
  }, [currentPageAreaType, itemsPerPageAreaTypes, filteredAreaTypes]);

  const paginatedAreaProfiles = useMemo(() => {
    const start = (currentPageAreaProfile - 1) * itemsPerPageAreaProfile;
    const end = start + itemsPerPageAreaProfile;
    return filteredAreaProfiles
      ?.sort((a, b) =>
        a.profile.name.localeCompare(b.profile.name, "pt-BR", {
          sensitivity: "base",
        })
      )
      ?.slice(start, end);
  }, [filteredAreaProfiles, currentPageAreaProfile, itemsPerPageAreaProfile]);

  const allFetches = useCallback(async () => {
    setLoading(true);
    await fetchArea();
    await fetchAreaTypes();
    await fetchAttendAreaProfiles();
    setLoading(false);
  }, [fetchArea, fetchAreaTypes, fetchAttendAreaProfiles, setLoading]);

  useEffect(() => {
    allFetches();
    return () => {
      setAreaDetails(undefined);
    };
  }, [allFetches, setAreaDetails]);

  return (
    <Grid>
      {canReadAreas ? (
        <Column span={16}>
          <Padding y={24}>
            <Margin mt={20} />
            {/* <Breadcrumb>
              <BreadcrumbItem onClick={() => navigate("/app/areas/")}>
                Áreas
              </BreadcrumbItem>
              <BreadcrumbItem onClick={() => navigate(`/app/areas/${id}`)}>
                {areaDetails?.name ?? "Detalhes"}
              </BreadcrumbItem>
            </Breadcrumb> */}
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                fontWeight: 100,
                alignItems: "center",
                margin: 0,
                padding: 0,
              }}
            >
              <Breadcrumb
                crumbsOptions={[
                  {
                    crumb: "Áreas",
                    onClick: () => navigate("/app/areas/"),
                  },
                  {
                    crumb: areaDetails?.name ?? "Detalhes",
                    onClick: () => navigate(`/app/areas/${id}`),
                  },
                ]}
                endHiddenIndex={0}
                startHiddenIndex={5}
              />
            </div>
          </Padding>
          <h1>{areaDetails?.name}</h1>
          <Margin mt={34} /> <Margin mt={20} />
          <TableContainer title="Usuários atribuídos">
            <Margin mb={-20} />
            <TableToolbar>
              {/* {!canReadAreas && (
              <div
                style={{
                  height: "400px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <h4 style={{ position: "relative", left: "45%" }}>
                  Você não possui permissão para visualizar as áreas
                </h4>
              </div>
            )} */}

              <TableToolbarContent>
                {/* <TableToolbarSearch
                  tabIndex={0}
                  onChange={handleChange}
                  value={values.search}
                  id="search"
                  placeholder="Pesquisar"
                /> */}
                {canEditAreas && (
                  <Button
                    tabIndex={0}
                    onClick={() => {
                      setOpenAssociateAttendAreaProfileModal(true);
                    }}
                    kind="primary"
                  >
                    Atribuir usuário
                  </Button>
                )}
              </TableToolbarContent>
            </TableToolbar>

            <Table>
              <TableHead>
                <TableRow>
                  {/* <TableSelectAll
                    name={""}
                    id={""}
                    onSelect={console.log}
                    checked={false}
                    ariaLabel={""}
                  /> */}
                  {tableHeaderProfile.map((columnTitle, index) => {
                    return (
                      <TableHeader key={String(index)}>
                        {columnTitle}
                      </TableHeader>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedAreaProfiles?.length === 0 ? (
                  <TableCell colSpan={tableHeaderProfile.length + 1}>
                    <Padding pt={24} pb={24} pl={12}>
                      <div
                        style={{
                          height: "300px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <h4>Esta área não possui nenhum usuário atribuído</h4>
                      </div>
                    </Padding>
                  </TableCell>
                ) : paginatedAreaProfiles.length > 0 ? (
                  paginatedAreaProfiles?.map((profile, index) => (
                    <TableRow key={String(index)} style={{ cursor: "pointer" }}>
                      {/* <TableSelectRow
                        name={""}
                        id={""}
                        onSelect={console.log}
                        checked={false}
                      /> */}

                      <TableCell>{profile?.profile?.name}</TableCell>
                      <TableCell>
                        {formatCPFCNPJ(profile?.profile?.document_number)}
                      </TableCell>
                      <TableCell>{typeDict[profile?.profile?.type]}</TableCell>

                      <TableCell>
                        {/* <Button
                          kind="ghost"
                          hasIconOnly
                          iconDescription="Detalhes"
                          renderIcon={View}
                          id="filter"
                        /> */}
                        {canEditAreas && (
                          <Button
                            kind="ghost"
                            hasIconOnly
                            iconDescription="Remover"
                            renderIcon={TrashCan}
                            id="filter"
                            onClick={(e) => {
                              e.stopPropagation();
                              setAttendAreaProfileDetails(profile);
                              setOpenDeleteAttendAreaProfileModal(true);
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  paginatedAreaProfiles?.map((profile, index) => (
                    <TableRow key={String(index)} style={{ cursor: "pointer" }}>
                      {/* <TableSelectRow
                        name={""}
                        id={""}
                        onSelect={console.log}
                        checked={false}
                      /> */}
                      <TableCell>{profile?.profile?.name}</TableCell>
                      <TableCell>{profile?.profile?.document_number}</TableCell>
                      <TableCell>{typeDict[profile?.profile?.type]}</TableCell>
                      <TableCell>
                        {" "}
                        <Button
                          kind="ghost"
                          hasIconOnly
                          renderIcon={TrashCan}
                          onClick={(e) => {
                            e.stopPropagation();
                            setAttendAreaProfileDetails(profile);
                            setOpenDeleteAttendAreaProfileModal(true);
                          }}
                          id="filter"
                        ></Button>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
            <Pagination
              totalItems={paginatedAreaProfiles.length}
              pageSize={itemsPerPageAreaProfile}
              pageSizes={[5, 10, 20, 30, 40, 50]}
              onChange={({ page, pageSize }) => {
                setCurrentPageAreaProfile(page);
                setItemsPerPageAreaProfile(pageSize);
              }}
              page={currentPageAreaProfile}
              size="md"
            />
          </TableContainer>
          <Margin mt={50} />
          <TableContainer title="Tipos de atendimento atribuídos">
            <Margin mb={-20} />
            <TableToolbar>
              {/* {!canReadAreas && (
              <div
                style={{
                  height: "400px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <h4 style={{ position: "relative", left: "45%" }}>
                  Você não possui permissão para visualizar as áreas
                </h4>
              </div>
            )} */}

              <TableToolbarContent>
                {/* <TableToolbarSearch
                  tabIndex={0}
                  onChange={handleChange}
                  value={values.search}
                  id="search"
                  placeholder="Pesquisar"
                /> */}
                {canEditAreas && (
                  <Button
                    tabIndex={0}
                    onClick={() => {
                      setOpenAssociateAreaTypeModal(true);
                    }}
                    kind="primary"
                  >
                    Atribuir tipo de atendimento
                  </Button>
                )}
              </TableToolbarContent>
            </TableToolbar>

            <Table>
              <TableHead>
                <TableRow>
                  {/* <TableSelectAll
                    name={""}
                    id={""}
                    onSelect={console.log}
                    checked={false}
                    ariaLabel={""}
                  /> */}
                  {tableHeadersArea.map((columnTitle, index) => {
                    return (
                      <TableHeader key={String(index)}>
                        {columnTitle}
                      </TableHeader>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedAreaTypes?.length === 0 ? (
                  <TableCell colSpan={tableHeadersArea.length + 1}>
                    <Padding pt={24} pb={24} pl={12}>
                      <div
                        style={{
                          height: "300px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <h4>
                          Esta área não possui nenhum tipo de atendimento
                          atribuído
                        </h4>
                      </div>
                    </Padding>
                  </TableCell>
                ) : paginatedAreaTypes.length > 0 ? (
                  paginatedAreaTypes?.map((areaType, index) => (
                    <TableRow key={String(index)} style={{ cursor: "pointer" }}>
                      {/* <TableSelectRow
                        name={""}
                        id={""}
                        onSelect={console.log}
                        checked={false}
                      /> */}

                      <TableCell>{areaType?.type?.name}</TableCell>
                      <TableCell>
                        {areaType.type?.sla}{" "}
                        {areaType.type?.sla_type === "day"
                          ? "Dia(s)"
                          : areaType.type?.sla_type === "hour"
                            ? "Hora(s)"
                            : "Minuto(s)"}
                      </TableCell>
                      <TableCell>
                        {/* <Button
                          kind="ghost"
                          hasIconOnly
                          iconDescription="Detalhes"
                          renderIcon={View}
                          id="filter"
                        /> */}
                        {canEditAreas && (
                          <Button
                            kind="ghost"
                            hasIconOnly
                            iconDescription="Remover"
                            renderIcon={TrashCan}
                            id="filter"
                            onClick={(e) => {
                              e.stopPropagation();
                              setAreaTypeDetails(areaType);
                              setOpenDeleteAreaTypeModal(true);
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  paginatedAreaTypes?.map((areaType, index) => (
                    <TableRow key={String(index)} style={{ cursor: "pointer" }}>
                      {/* <TableSelectRow
                        name={""}
                        id={""}
                        onSelect={console.log}
                        checked={false}
                      /> */}
                      <TableCell>{areaType?.type?.name}</TableCell>
                      <TableCell>{areaType.type?.sla}h</TableCell>
                      <TableCell>
                        {" "}
                        <Button
                          kind="ghost"
                          hasIconOnly
                          renderIcon={TrashCan}
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenDeleteAreaTypeModal(true);
                          }}
                          id="filter"
                        ></Button>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
            <Pagination
              totalItems={paginatedAreaTypes.length}
              pageSize={itemsPerPageAreaTypes}
              pageSizes={[5, 10, 20, 30, 40, 50]}
              onChange={({ page, pageSize }) => {
                setCurrentPageAreaType(page);
                setItemsPerPageAreaTypes(pageSize);
              }}
              page={currentPageAreaType}
              size="md"
            />
          </TableContainer>
          <Margin mb={50} />
        </Column>
      ) : (
        <div
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "500px",
            position: "relative",
            left: "520%",
            bottom: "10%",
          }}
        >
          {" "}
          <h4> Você não possui permissão para visualizar áreas</h4>
        </div>
      )}
      <DeleteAreaTypeModal
        onClose={() => setOpenDeleteAreaTypeModal(false)}
        open={openDeleteAreaTypeModal}
      />
      <AssociateAreaTypeModal
        onClose={() => setOpenAssociateAreaTypeModal(false)}
        open={openAssociateAreaTypeModal}
      />
      <AssociateAttendAreaProfileModal
        onClose={() => setOpenAssociateAttendAreaProfileModal(false)}
        open={openAssociateAttendAreaProfileModal}
      />

      <DeleteAttendAreaProfileModal
        onClose={() => setOpenDeleteAttendAreaProfileModal(false)}
        open={openDeleteAttendAreaProfileModal}
      />
    </Grid>
  );
};

export default AreaDetails;
