import {
  Table,
  TableBatchAction,
  TableBatchActions,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Grid,
  Column,
  Button,
  Pagination,
} from "@carbon/react";

import {
  TrashCan,
  Add,
  Save,
  Download,
  Edit,
  Renew,
} from "@carbon/icons-react";
import Margin from "~/components/Margin";

import { useCallback, useEffect, useMemo, useState } from "react";
import useLoading from "~/hooks/useLoading";

// import { useFormik } from "formik";
import Padding from "~/components/Padding";

import { getAreasAPI } from "~/services/api/areas";
import useArea from "~/hooks/useArea";

import NewAreaContentModal from "./NewAreaContentModal";
import { permissions } from "~/services/permissions";
import DeleteAreaModal from "./DeleteAreaModal";
import { useNavigate } from "react-router-dom";
import { getAttendAreaProfilesAPI } from "~/services/api/attendAreaProfile";

import { getAreaTypesAPI } from "~/services/api/areaTypes";
import useAreaType from "~/hooks/useAreaType";
import EditAreaModal from "./EditAreaModal";
import Badge from "~/components/Badge";

const tableHeadersArea: string[] = ["Nome", "Status", ""];

const Areas = () => {
  const { setLoading } = useLoading();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const {
    setAreas,
    areas,
    setOpenDeleteAreaModal,
    openDeleteAreaModal,
    setAreaDetails,
    setAttendAreaProfiles,

    setOpenEditAreaModal,
    openEditAreaModal,
  } = useArea();

  const paginatedAreas = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return areas
      ?.sort((a, b) =>
        a.name.localeCompare(b.name, "pt-BR", { sensitivity: "base" })
      )
      ?.slice(start, end);
  }, [currentPage, itemsPerPage, areas]);

  const { setAreaTypes } = useAreaType();

  const navigate = useNavigate();

  const [openNewAreaModal, setOpenNewAreaModal] = useState<boolean>(false);

  const canReadAreas = permissions.includes("can_read_areas");
  const canCreateAreas = permissions.includes("can_create_areas");
  const canEditAreas = permissions.includes("can_edit_areas");
  const canDeleteAreas = permissions.includes("can_delete_areas");

  // const { values } = useFormik({
  //   initialValues: {
  //     search: "",
  //   },
  //   onSubmit: (values: { search: string }) => {
  //     console.log(values);
  //   },
  // });

  const fetchAreas = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getAreasAPI();
      setAreas(data.results.reverse());
      setLoading(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, [setAreas, setLoading]);

  const fetchAttendAreaProfiles = useCallback(async () => {
    try {
      const { data } = await getAttendAreaProfilesAPI();
      setAttendAreaProfiles(data.results.reverse());
    } catch (e) {
      console.log(e);
    }
  }, [setAttendAreaProfiles]);

  const fetchAreaTypes = useCallback(async () => {
    try {
      const { data } = await getAreaTypesAPI();

      setAreaTypes(data.results.reverse());
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setAreaTypes]);

  useEffect(() => {
    fetchAreas();
    fetchAttendAreaProfiles();
    fetchAreaTypes();
  }, [fetchAreas, fetchAreaTypes, fetchAttendAreaProfiles]);

  return (
    <Grid>
      <Column span={16}>
        <Margin mt={64} /> <h1>Áreas</h1>
        <Margin mt={34} /> <Margin mt={20} />
        <TableContainer>
          <Margin mb={20} />
          <TableToolbar>
            {canReadAreas && (
              <Button
                tabIndex={0}
                onClick={() => {
                  fetchAreas();
                }}
                kind="secondary"
                renderIcon={Renew}
              >
                Atualizar
              </Button>
            )}
            {!canReadAreas && (
              <div
                style={{
                  height: "400px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <h4 style={{ position: "relative", left: "45%" }}>
                  Você não possui permissão para visualizar as áreas
                </h4>
              </div>
            )}
            <TableBatchActions onCancel={console.log} totalSelected={0}>
              <TableBatchAction
                tabIndex={0}
                renderIcon={TrashCan}
                onClick={console.log}
              >
                Delete
              </TableBatchAction>
              <TableBatchAction
                hasIconOnly
                iconDescription="Add"
                tabIndex={0}
                renderIcon={Add}
                onClick={console.log}
              >
                Delete
              </TableBatchAction>
              <TableBatchAction
                hasIconOnly
                iconDescription="Save"
                tabIndex={0}
                renderIcon={Save}
                onClick={console.log}
              >
                Save
              </TableBatchAction>
              <TableBatchAction
                tabIndex={0}
                renderIcon={Download}
                onClick={console.log}
              >
                Download
              </TableBatchAction>
            </TableBatchActions>
            <TableToolbarContent>
              {/* <TableToolbarSearch
                tabIndex={0}
                onChange={handleChange}
                value={values.search}
                id="search"
                placeholder="Pesquisar"
              /> */}
              {canCreateAreas && (
                <Button
                  tabIndex={0}
                  onClick={() => {
                    setOpenNewAreaModal(true);
                  }}
                  kind="primary"
                >
                  Nova área
                </Button>
              )}
            </TableToolbarContent>
          </TableToolbar>
          {canReadAreas && (
            <Table>
              <TableHead>
                <TableRow>
                  {/* <TableSelectAll
                  name={""}
                  id={""}
                  onSelect={console.log}
                  checked={false}
                  ariaLabel={""}
                /> */}
                  {tableHeadersArea.map((columnTitle, index) => {
                    return (
                      <TableHeader key={String(index)}>
                        {columnTitle}
                      </TableHeader>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedAreas?.length === 0 ? (
                  <TableCell colSpan={tableHeadersArea.length + 1}>
                    <Padding pt={24} pb={24} pl={12}>
                      <div
                        style={{
                          height: "300px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <h4>Nenhum àrea criada até o momento</h4>
                      </div>
                    </Padding>
                  </TableCell>
                ) : paginatedAreas.length > 0 ? (
                  paginatedAreas?.map((area, index) => (
                    <TableRow
                      key={String(index)}
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(`/app/areas/${area.id}/`)}
                    >
                      {/* <TableSelectRow
                      name={""}
                      id={""}
                      onSelect={console.log}
                      checked={false}
                    /> */}
                      <TableCell>{area.name}</TableCell>
                      <TableCell>
                        {area.active ? (
                          <Badge
                            color="#CCEFDF"
                            textColor="#00B360"
                            label="Ativa"
                          />
                        ) : (
                          <Badge
                            label="Inativa"
                            color="#e3e3e3"
                            textColor="#696868"
                          />
                        )}
                      </TableCell>

                      <TableCell>
                        {/* <Button
                        kind="ghost"
                        hasIconOnly
                        iconDescription="Detalhes"
                        renderIcon={View}
                        id="filter"
                      /> */}
                        {canEditAreas && (
                          <Button
                            kind="ghost"
                            hasIconOnly
                            iconDescription="Editar"
                            renderIcon={Edit}
                            id="filter"
                            onClick={(e) => {
                              e.stopPropagation();
                              setAreaDetails(area);
                              setOpenEditAreaModal(true);
                            }}
                          />
                        )}
                        {canDeleteAreas && (
                          <Button
                            kind="ghost"
                            hasIconOnly
                            iconDescription="Excluir"
                            renderIcon={TrashCan}
                            id="filter"
                            onClick={(e) => {
                              e.stopPropagation();
                              setAreaDetails(area);
                              setOpenDeleteAreaModal(true);
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  paginatedAreas?.map((area, index) => (
                    <TableRow
                      key={String(index)}
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(`/app/areas/${area.id}`)}
                    >
                      {/* <TableSelectRow
                      name={""}
                      id={""}
                      onSelect={console.log}
                      checked={false}
                    /> */}
                      <TableCell>{area.name}</TableCell>
                      <TableCell>{area.active ? "Sim" : "Não"}</TableCell>
                      <TableCell>{area.active ? "Sim" : "Não"}</TableCell>

                      <TableCell>
                        {/* <Button
                        kind="ghost"
                        hasIconOnly
                        iconDescription="Detalhes"
                        renderIcon={View}
                        id="filter"
                      /> */}
                        {canEditAreas && (
                          <Button
                            kind="ghost"
                            hasIconOnly
                            iconDescription="Editar"
                            renderIcon={Edit}
                            id="filter"
                            onClick={(e) => {
                              e.stopPropagation();
                              setAreaDetails(area);
                              setOpenEditAreaModal(true);
                            }}
                          />
                        )}
                        {canDeleteAreas && (
                          <Button
                            kind="ghost"
                            hasIconOnly
                            iconDescription="Excluir"
                            renderIcon={TrashCan}
                            id="filter"
                            onClick={(e) => {
                              e.stopPropagation();
                              setAreaDetails(area);
                              setOpenDeleteAreaModal(true);
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          )}
          <Pagination
            totalItems={areas.length}
            pageSize={itemsPerPage}
            pageSizes={[5, 10, 20, 30, 40, 50]}
            onChange={({ page, pageSize }) => {
              setCurrentPage(page);
              setItemsPerPage(pageSize);
            }}
            page={currentPage}
            size="md"
          />
        </TableContainer>
      </Column>
      <NewAreaContentModal
        onClose={() => setOpenNewAreaModal(false)}
        open={openNewAreaModal}
      />
      <EditAreaModal
        onClose={() => setOpenEditAreaModal(false)}
        open={openEditAreaModal}
      />
      <DeleteAreaModal
        onClose={() => setOpenDeleteAreaModal(false)}
        open={openDeleteAreaModal}
      />
    </Grid>
  );
};

export default Areas;
