import { IProfiles } from "~/types";
import ApiService from "../api";

export type IPostProfileBody = {
  name: string;
  document_number: string;
  email: string;
  password?: string;
  type: string[];
  info?: string;
  user?: number;
  beneficiary_approver?: boolean;
  payment_company_approver?: boolean;
  payment_approver?: boolean;
  company?: number;
  raw_data?: {
    first_login?: boolean;
  };
};

export type IPatchProfileBody = {
  name?: string;
  document_number?: string;
  email?: string;
  password?: string;
  type?: string[];
  info?: string;
  user?: number;
  beneficiary_approver?: boolean;
  payment_company_approver?: boolean;
  payment_approver?: boolean;

  company?: number;
  raw_data?: {
    first_login?: boolean;
  };
};

type IResponseGetProfiles = {
  count: number;
  next: null;
  previous: null;
  results: IProfiles[];
};

export const getProfilesAPI = (params?: { mode: string; company?: number }) => {
  return ApiService.HttpGet<IResponseGetProfiles>({
    route: "profile/",
    token: true,
    params,
  });
};

export const getInternalApprovers = () => {
  return ApiService.HttpGet<IResponseGetProfiles>({
    route: "profile/",
    token: true,
    params: {
      mode: "list",
      // type: "internal,area_manager,tool_manager",
      // payment_approver: true,
    },
  });
};

export const getProfileAPI = (id: string) => {
  return ApiService.HttpGet<IProfiles>({
    route: `profile/${id}/`,
    token: true,
  });
};

export const PostProfileAPI = (data: IPostProfileBody) => {
  return ApiService.HttpPost<IProfiles>({
    route: "profile/",
    body: data,
    token: true,
  });
};

export const deleteProfileAPI = (id: number) => {
  return ApiService.HttpDelete({
    route: `profile/${id}/`,
    token: true,
  });
};

export const patchProfileAPI = (data: IPatchProfileBody, id: string) => {
  return ApiService.HttpPatch({
    route: `profile/${id}/`,
    token: true,
    body: data,
  });
};

export const putProfileAPI = (data: unknown, id: string) => {
  return ApiService.HttpPut({
    route: `profile/${id}/`,
    token: true,
    body: data,
  });
};
