import { InlineNotification, Modal } from "@carbon/react";
import { useFormik } from "formik";
import Margin from "~/components/Margin";

import * as Yup from "yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import useLoading from "~/hooks/useLoading";
import Select from "react-select";
import useAttend from "~/hooks/useAttend";
import { getStatusAPI } from "~/services/api/status";
import { IStatus } from "~/types";
import { useParams } from "react-router-dom";
import {
  getAttendDetailsAPI,
  getAttendHistoryAPI,
  patchAttendDetailsAPI,
} from "~/services/api/attend";
import Label from "~/components/Label";

type Values = {
  status: string;
};

const initialValues: Values = {
  status: "",
};

const validationSchema = Yup.object({
  status: Yup.string(),
});

const statusDict: { [key: string]: string } = {
  opened: "Em aberto",
  done: "Finalizado",
  return_to_client: "Retor. ao solicitante",
  canceled: "Cancelado",
  created: "Criado",
  in_progress: "Em andamento",
};

const UpdateStatusAttendModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();
  const {
    attendDetails,
    setStatus,
    status,
    setAttendDetails,
    setOpenUpdatedAttendStatusModal,
  } = useAttend();
  const { setHistory } = useAttend();

  const { id } = useParams();
  const [error, setError] = useState<boolean>(false);

  const fetchStatus = async () => {
    try {
      const { data } = await getStatusAPI();
      setStatus(data.results);
    } catch (e: unknown) {
      console.log(e);
    }
  };

  const { handleSubmit, setFieldValue, values } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const statusOptions = useMemo(() => {
    return status?.map((status: IStatus) => ({
      value: String(status.id),
      label: `${statusDict[status.slug]}`,
    }));
  }, [status]);

  type SelectOption = {
    value: string;
    label: string;
  };
  const handleSelectStatus = (selectedOption: SelectOption | null) => {
    if (selectedOption) {
      setFieldValue("status", selectedOption.value);
    }
  };

  const fetchAttends = useCallback(async () => {
    if (id) {
      try {
        const { data } = await getAttendDetailsAPI(Number(id));

        setAttendDetails(data);

        setLoading(false);
      } catch (err: unknown) {
        console.error(err);
        setLoading(false);
      }
    }
  }, [id, setAttendDetails, setLoading]);

  const fetchHistory = useCallback(async () => {
    try {
      const { data } = await getAttendHistoryAPI(Number(id));
      setHistory(data);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setHistory, id]);

  const fetchSendNewStatus = useCallback(async () => {
    setLoading(true);
    try {
      await patchAttendDetailsAPI(Number(id), {
        status: Number(values.status),
      });

      fetchHistory();
      fetchAttends();
      setOpenUpdatedAttendStatusModal(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, [
    values.status,
    id,
    fetchHistory,
    fetchAttends,
    setOpenUpdatedAttendStatusModal,
    setLoading,
  ]);

  useEffect(() => {
    fetchStatus();
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Modal
        style={{ zIndex: 3 }}
        modalHeading={`Atualizar status - #${attendDetails?.id} ${attendDetails?.type.name}`}
        size="sm"
        open={open}
        onRequestClose={() => {
          onClose();
          setError(false);
        }}
        primaryButtonText="Concluir"
        secondaryButtonText="Cancelar"
        primaryButtonDisabled={
          values.status === "" || values.status === undefined
        }
        onRequestSubmit={async () => {
          fetchSendNewStatus();
        }}
      >
        {error && (
          <InlineNotification
            title="Erro!"
            // subtitle={error}
            subtitle="Não foi possivel criar o tipo de atendimento."
            hideCloseButton
            kind="error"
            lowContrast
          />
        )}
        <Margin mt={30} />
        <Label text="Selecione um status" />
        <Select
          options={statusOptions}
          onChange={handleSelectStatus}
          placeholder="Pesquisar ou selecionar um status..."
        />{" "}
        <Margin mb={204} />
      </Modal>
    </form>
  );
};

export default UpdateStatusAttendModal;
