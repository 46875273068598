import { Modal, TextInput, Toggle } from "@carbon/react";
import { useFormik } from "formik";
import Margin from "~/components/Margin";

import * as Yup from "yup";
import { useCallback, useEffect, useMemo } from "react";
import useLoading from "~/hooks/useLoading";

import { toast } from "react-toastify";
import useFund from "~/hooks/useFund";
import { onlyNumbers } from "~/services/utils";
import { getFundsAPI, IBodyFund, patchFundsAPI } from "~/services/api/fund";
import MaskedTextInput from "~/components/InputMask";

type Values = {
  name: string;
  short_name: string;
  document_number: string;
  active: boolean;
};

const validationSchema = Yup.object({
  name: Yup.string().required("Nome da tarefa é obrigatório"),
  sla: Yup.number().required("Nome da tarefa é obrigatório"),
});

const EditFundModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();

  const { fundDetails, setOpenEditFundModal, setFunds } = useFund();

  const notifySuccess = () => toast.success("Fundo atualizado com sucesso!");
  const notifyError = () =>
    toast.error(
      "Não foi possível atualizar o fundo. Tente novamente mais tarde."
    );

  const initialValues: Values = {
    name: fundDetails?.name || "",
    document_number: fundDetails?.raw_data?.document_number ?? "",
    short_name: fundDetails?.raw_data?.short_name ?? "",
    active: fundDetails?.raw_data?.active ?? false,
  };

  // Adicionando log para depuração
  const handleToggle = () => {
    setFieldValue("active", !values.active);
  };

  const { handleSubmit, values, handleBlur, handleChange, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: (values) => {
        console.log(values);
      },
    });

  const sendNewFundEdited: IBodyFund = useMemo(() => {
    return {
      name: values.name,
      raw_data: {
        short_name: values.short_name,
        document_number: onlyNumbers(values.document_number),
        active: values.active,
      },
    };
  }, [values]);

  const fetchFunds = useCallback(async () => {
    try {
      const { data } = await getFundsAPI();
      setFunds(data.results.reverse());
      console.log(data);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setFunds]);

  const fetchSendNewFundPatch = async (payload: IBodyFund) => {
    setLoading(true);
    try {
      if (fundDetails) {
        await patchFundsAPI(payload, String(fundDetails?.id));
        setOpenEditFundModal(false);

        await fetchFunds();

        setLoading(false);
        notifySuccess();
      }
    } catch (error) {
      console.error("Erro ao excluir", error);
      setLoading(false);
      notifyError();
    }
  };

  useEffect(() => {
    if (fundDetails) {
      setFieldValue("name", fundDetails?.name);
      setFieldValue("short_name", fundDetails?.raw_data?.short_name);
      setFieldValue("document_number", fundDetails?.raw_data?.document_number);
      setFieldValue("active", fundDetails?.raw_data?.active);
    }
  }, [fundDetails, setFieldValue]);

  return (
    <form onSubmit={handleSubmit}>
      <Modal
        style={{ zIndex: 6 }}
        modalHeading={`Editar fundo - ${fundDetails?.raw_data.short_name}`}
        size="sm"
        open={open}
        primaryButtonDisabled={
          values.name === "" ||
          values.document_number === "" ||
          values.short_name === ""
        }
        onRequestClose={() => {
          onClose();
        }}
        primaryButtonText="Concluir"
        secondaryButtonText="Cancelar"
        onRequestSubmit={async () => {
          fetchSendNewFundPatch(sendNewFundEdited);
        }}
      >
        <TextInput
          id="name"
          name="name"
          labelText="Nome"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
        />
        <Margin mb={20} />
        <TextInput
          id="short_name"
          name="short_name"
          labelText="Nome abreviado"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.short_name}
        />
        <Margin mb={20} />
        <p style={{ fontSize: "12px", color: "black", fontWeight: "300" }}>
          {" "}
          CNPJ
        </p>
        <Margin mb={5} />
        <MaskedTextInput
          mask="99.999.999/9999-99"
          id="document_number"
          onChange={handleChange}
          name="document_number"
          placeholder="Digite o CNPJ do fundo"
          value={values.document_number}
        />

        <Margin mb={13} />
        <Toggle
          id="toggle-2"
          labelText="Status"
          onToggle={handleToggle}
          toggled={values.active}
          labelA="Inativo"
          labelB="Ativo"
        />

        <Margin mb={24} />

        <Margin mb={24} />
      </Modal>
    </form>
  );
};

export default EditFundModal;
