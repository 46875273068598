import ApiService from "../api";
import { BankAccount, IFund, PixKey } from "~/types";

export type IBodyBeneficiary = {
  id?: number;
  name: string;
  document_number: string;
  bank_account: {
    id?: string | number;
    account_number: string;
    account_number_digit: string;
    agency: string;
    bank: string;
    status?: string;
  }[];
  pix_keys?: {
    type: string;
    key: string;
    status?: string;
  }[];
  active?: boolean;
  profile?: number;
  raw_data?: {
    status: string;
    pending_funds_approvations?: boolean;
  };
};

export type IBodyPatchBeneficiary = {
  id?: number;
  name?: string;
  document_number?: string;
  bank_account?: {
    id?: string | number;
    account_number?: string;
    account_number_digit?: string;
    agency?: string;
    bank?: string;
    status?: string;
  }[];
  pix_keys?: {
    type?: string;
    key?: string;
    status?: string;
  }[];
  active?: boolean;
  profile?: number;
  raw_data?: {
    status?: string;
    pending_funds_approvations?: boolean;
  };
};

export type IBeneficiariesReponse = {
  results: IBeneficiary[];
};

export type IBeneficiaryFundBody = {
  id?: number;
  beneficiary: number;
  fund: IFund | number;
  raw_data: {
    status: string;
  };
};

export type IBeneficiaryFundPatch = {
  beneficiary?: number;
  fund?: IFund;
  raw_data: {
    status: string;
  };
};

export const postBeneficiaryAPI = (data: IBodyBeneficiary) => {
  return ApiService.HttpPost<IBodyBeneficiary>({
    route: "payment/payment-beneficiary/",
    body: data,
    token: true,
  });
};

export const getBeneficiariesAPI = () => {
  return ApiService.HttpGet<IBeneficiariesReponse>({
    route: "payment/payment-beneficiary/",
    token: true,
  });
};

export const deleteBeneficiaryAPI = (id: number) => {
  return ApiService.HttpDelete({
    route: `payment/payment-beneficiary/${id}/`,
    token: true,
  });
};

export const getBeneficiaryAPI = (id: number) => {
  return ApiService.HttpGet<IBeneficiary>({
    route: `payment/payment-beneficiary/${id}/`,
    token: true,
  });
};

export const patchBeneficiaryAPI = (
  id: number,
  data: IBodyPatchBeneficiary
) => {
  return ApiService.HttpPatch<IBodyBeneficiary>({
    route: `payment/payment-beneficiary/${id}/`,
    token: true,
    body: data,
  });
};

export const postBeneficiaryFundAPI = (data: IBeneficiaryFundBody) => {
  return ApiService.HttpPost({
    route: `payment/beneficiary-fund/`,
    token: true,
    body: data,
  });
};

export type IBeneficiaryReponse = {
  id: number;
  deleted: Date | null;
  deleted_by_cascade: boolean;
  active: boolean;
  created_on: Date;
  updated_on: Date;
  beneficiary: number;
  fund: IFund | number;
  raw_data: {
    status: string;
  };
};

interface IBeneficiary {
  id: number;
  deleted: string;
  deleted_by_cascade: boolean;
  name: string;
  document_number: string;
  bank_account: BankAccount[];
  pix_keys: PixKey[];

  active: boolean;
  created_on: string;
  updated_on: string;
  profile: number;
  raw_data?: {
    status?: string;
    pending_funds_approvations?: boolean;
  };
}

export type IFundBeneficiary = {
  id: number;
  deleted: Date | null;
  deleted_by_cascade: boolean;
  active: boolean;
  created_on: Date;
  updated_on: Date;
  beneficiary: IBeneficiary;
  fund: IFund;
  raw_data: {
    status: string;
  };
};

export type IBeneficiaryFund = {
  id: number;
  deleted: Date | null;
  deleted_by_cascade: boolean;
  active: boolean;
  created_on: Date;
  updated_on: Date;
  beneficiary: IBeneficiary;
  fund: IFund;
  raw_data: {
    status: string;
  };
};

type IbeneficiarisFundResults = {
  results: IBeneficiaryReponse[];
};
export const getBeneficiaryFundAPI = () => {
  return ApiService.HttpGet<IbeneficiarisFundResults>({
    route: `payment/beneficiary-fund/`,
    token: true,
    params: {},
  });
};

export const getBeneficiaryFundWIDAPI = (id: number) => {
  return ApiService.HttpGet<{ results: IBeneficiaryFund[] }>({
    route: `payment/beneficiary-fund/`,
    token: true,
    params: {
      beneficiary: id,
      expand: "fund",
    },
  });
};

export const getBeneficiaryFundFilteredPerFundAPI = (id: number) => {
  return ApiService.HttpGet<{ results: IBeneficiaryFund[] }>({
    route: `payment/beneficiary-fund/`,
    token: true,
    params: {
      fund: id,
      expand: "beneficiary",
    },
  });
};

export const patchBeneficiaryFundAPI = (
  id: number,
  data: IBeneficiaryFundPatch
) => {
  return ApiService.HttpPatch<IbeneficiarisFundResults>({
    route: `payment/beneficiary-fund/${id}/`,
    body: data,
    token: true,
  });
};
