import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  Grid,
  Loading,
  Modal,
  Search,
  SideNav,
  SideNavItems,
  SideNavLink,
  Stack,
  TextArea,
  Toggle,
} from "@carbon/react";
import { TrashCan, View } from "@carbon/icons-react";
import { useDropzone } from "react-dropzone";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Margin from "~/components/Margin";
import Padding from "~/components/Padding";
import useLoading from "~/hooks/useLoading";
import { postAttendAPI, ISendNewAttendBody } from "~/services/api/attend";
import { ICompanyFund, IFund, IProfiles } from "~/types";
import { getFundsAPI } from "~/services/api/fund";
import useFund from "~/hooks/useFund";
import useProfile from "~/hooks/useProfile";
import Select from "react-select";
import { getAreaTypesAPI } from "~/services/api/areaTypes";
import useAreaType from "~/hooks/useAreaType";
import { permissions } from "~/services/permissions";
// import { postAttendLogAPI } from "~/services/api/attendLog";
import useUser from "~/hooks/useUser";
import { formatCPFCNPJ, getFirstLetters } from "~/services/utils";
import { getProfilesForAttendAPI } from "~/services/api/profileForAttend";
import Label from "~/components/Label";
import { toast } from "react-toastify";
import { postAttendTaskFileAPI } from "~/services/api/attendTaskFile";
import { postAttendLogAPI } from "~/services/api/attendLog";
import { getCompanyFundAPI } from "~/services/api/companyFund";

interface PreviewFile extends File {
  preview: string;
}

const TicketFormContainer = styled.div`
  position: relative;

  .form-sidenav {
    position: relative;

    background: #f4f4f4;
  }

  .form {
    background: #f4f4f4;
    min-height: 50vh;

    .action-button {
      width: 100%;
    }
  }
`;

const ContainerFile = styled.div`
  padding: 10px 30px;
  border-radius: 4px;
  border: 1px solid rgba(38, 0, 255, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  margin-bottom: 5px;
`;

const ContainerProfiles = styled.div`
  display: flex;
  align-items: center;
  height: 70px;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &:hover {
    background-color: #e9edff;
  }
`;

type Values = {
  message: string;
  search: string;
  client: string;
  fund: string;

  area_type: string;
};

const initialValues: Values = {
  message: "",
  search: "",
  client: "",
  fund: "",
  area_type: "",
};

const validationSchema = Yup.object({
  message: Yup.string().required("Descrição é obrigatória"),
  client: Yup.string(),
  fund: Yup.string().required("Fundo é obrigatório"),
  area_type: Yup.string().required("Tipo é obrigatório"),
});

const NewAttend = () => {
  const navigate = useNavigate();
  const { profiles, setProfiles } = useProfile();
  const { setFunds, funds, fundsCompany, setFundsCompany } = useFund();
  const { userProfile } = useUser();
  const { setAreaTypes, areaTypes } = useAreaType();
  const { setLoading, loading } = useLoading();
  const [otherClient, setOtherClient] = useState<boolean>(false);
  const [loadingSearch, setLoadingSearch] = useState<boolean>(true);
  const [selectedIndex, setSelectedIndex] = useState<null | number>(null);
  const canCreateAttends = permissions.includes("can_create_attends");
  const canReadProfiles = permissions.includes("can_read_profiles");

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<PreviewFile | null>(null);

  const openModal = (file: PreviewFile) => {
    setSelectedFile(file);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedFile(null);
    setModalIsOpen(false);
  };

  const notifySuccess = () => toast.success("Atendimento criado com sucesso!");
  const notifyError = () => toast.error("O atendimento não pode ser criado");

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm,
    isValid,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const sendNewAttendPayload: ISendNewAttendBody = {
        message: values.message,
        client:
          otherClient && values.client !== ""
            ? Number(values.client)
            : userProfile?.id ?? 0,
        fund: Number(values.fund),

        type: Number(values.area_type),
      };

      fetchSendNewAttend(sendNewAttendPayload);
    },
  });

  const [files, setFiles] = useState<PreviewFile[]>([]);

  const handleDelete = (fileToDelete: File) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const previewFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ) as PreviewFile[];
      setFiles((prevFiles) => [...prevFiles, ...previewFiles]);
    },
  });

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const fundsOptions = useMemo(() => {
    return funds
      ?.map((fund: IFund) => ({
        value: String(fund.id),
        label: `${fund?.raw_data?.short_name?.trim()} - ${formatCPFCNPJ(fund?.raw_data?.document_number).trim()}`,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [funds]);

  const fetchCompanyFunds = useCallback(async () => {
    try {
      if (userProfile?.company?.id) {
        const { data } = await getCompanyFundAPI(
          Number(userProfile?.company?.id)
        );
        setFundsCompany(data.results.reverse());
      }
      //   setAreaTypes(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [userProfile, setFundsCompany]);

  const companyFundsOptions = useMemo(() => {
    return fundsCompany
      ?.map((fundsCompany: ICompanyFund) => ({
        value: String(fundsCompany.fund.id),
        label: `${fundsCompany?.fund.raw_data?.short_name?.trim()} - ${formatCPFCNPJ(fundsCompany?.fund.raw_data?.document_number).trim()}`,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [fundsCompany]);

  const areaTypesOptions = useMemo(() => {
    return areaTypes
      .map((areaType) => ({
        value: String(areaType.type.id),
        label: `${areaType.type.name}`,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [areaTypes]);

  const areaID = useMemo(() => {
    return areaTypes.filter(
      (areaType) => areaType.type.id === Number(values.area_type)
    )[0]?.area?.id;
  }, [areaTypes, values.area_type]);

  type SelectOption = {
    value: string;
    label: string;
  };

  const handleToggle = () => {
    setOtherClient(!otherClient);
    setFieldValue("search", "");
    setSelectedIndex(null);
    setFieldValue("client", "");
  };

  const handleSelectFund = (selectedOption: SelectOption | null) => {
    if (selectedOption) {
      setFieldValue("fund", selectedOption.value);
    }
  };

  const handleSelectAreaType = (
    selectedOption: SelectOption | null,
    actionMeta: { action: string }
  ) => {
    if (selectedOption) {
      setFieldValue("area_type", selectedOption.value);
    } else if (actionMeta.action === "clear") {
      setFieldValue("area_type", "");
    }
  };

  const profilesFiltered = useMemo(() => {
    return profiles;
  }, [profiles]);

  const sendFile = async (file: PreviewFile, dataId: string) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("sender", String(userProfile?.id || ""));
    formData.append("attend", String(dataId));
    formData.append("description", `${file?.name}`);

    await postAttendTaskFileAPI(formData); // Envia um único arquivo
  };

  const fetchSendNewAttend = async (payload: ISendNewAttendBody) => {
    setLoading(true);
    try {
      const { data } = await postAttendAPI(payload);

      await postAttendLogAPI({
        attend: data.id,
        actual_area: areaID,
      });

      if (data && files.length > 0) {
        const filePromises = files.map((file: PreviewFile) =>
          sendFile(file, String(data.id))
        );

        await Promise.all(filePromises);

        setLoading(false);
        navigate("/app/attends/");
        resetForm();
        notifySuccess();

        return;
      }

      setLoading(false);
      navigate("/app/attends/");
      resetForm();
      notifySuccess();
    } catch (e) {
      console.error(e);
      setLoading(false);
      notifyError();
    }
  };

  const fetchProfiles = useCallback(async () => {
    setLoadingSearch(true);
    setSelectedIndex(null);

    try {
      const { data } = await getProfilesForAttendAPI(values.search);

      setProfiles(data.results);
      setLoadingSearch(false);
    } catch (err: unknown) {
      setLoadingSearch(false);
      console.error(err);
    }
  }, [values.search, setProfiles]);

  const fetchFunds = useCallback(async () => {
    try {
      const { data } = await getFundsAPI();
      setFunds(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setFunds]);

  const fetchAreaTypes = useCallback(async () => {
    try {
      const { data } = await getAreaTypesAPI();
      setAreaTypes(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setAreaTypes]);

  console.log(areaTypes);

  // const sendArea = useCallback(async () => {
  //   try {
  //     await postAreaAPI({
  //       name: "Formalização",
  //     });
  //   } catch (err: unknown) {
  //     console.error(err);
  //   }
  // }, []);

  // const sendAttendType = useCallback(async () => {
  //   try {
  //     const { data } = await postAreaTypeAPI({
  //       area: 2,
  //       type: 10,
  //     });
  //     console.log(data);
  //   } catch (err: unknown) {
  //     console.error(err);
  //   }
  // }, []);

  useEffect(() => {
    fetchCompanyFunds();
    fetchFunds();

    fetchAreaTypes();
  }, [fetchProfiles, fetchFunds, fetchAreaTypes, fetchCompanyFunds]);

  useEffect(() => {
    fetchProfiles();
  }, [values.search, fetchProfiles]);

  return (
    <TicketFormContainer>
      {canCreateAttends ? (
        <form onSubmit={handleSubmit}>
          <Grid>
            <Column span={16}>
              <Padding y={24}>
                <Margin mb={30} />
                <Breadcrumb>
                  <BreadcrumbItem onClick={() => navigate("/app/attends/")}>
                    Atendimentos
                  </BreadcrumbItem>
                  <BreadcrumbItem onClick={() => navigate("/app/attends/new")}>
                    Novo atendimento
                  </BreadcrumbItem>
                </Breadcrumb>
              </Padding>
              <h1>Novo atendimento</h1>
              <Margin mb={22} />
              <Grid className="form">
                <Column span={4}>
                  <SideNav
                    style={{ zIndex: 0 }}
                    aria-label="Side navigation"
                    className="form-sidenav"
                  >
                    <SideNavItems>
                      <SideNavLink href="#" isActive>
                        Geral
                      </SideNavLink>
                    </SideNavItems>
                  </SideNav>
                </Column>

                <Column span={12}>
                  <Grid>
                    <Column span={12}>
                      <Padding y={12}></Padding>
                    </Column>
                    <Column span={6}>
                      <h2>Geral</h2>
                      <Margin mb={50} />{" "}
                      <Stack gap={5}>
                        <Margin mb={5} />
                        {canReadProfiles &&
                          userProfile?.type !== "client" &&
                          userProfile?.type !== "manager" && (
                            <Toggle
                              id="toggle-1"
                              labelText="Selecionar um cliente"
                              onToggle={handleToggle}
                              toggled={otherClient}
                              labelA=""
                              labelB=""
                            />
                          )}
                        {otherClient && (
                          <div style={{ zIndex: 0 }}>
                            {" "}
                            <Search
                              id="search"
                              labelText="Pesquisar"
                              placeholder="Digite o CPF ou nome do usuário"
                              value={values.search}
                              onChange={handleChange}
                            />
                            <Margin mb={30} />
                            {profilesFiltered.length > 0 && !loadingSearch ? (
                              <div
                                style={{
                                  height: "200px",
                                  overflow: "auto",
                                }}
                              >
                                <p>Clique para selecionar um usuário</p>
                                <Margin mb={10} />
                                {profilesFiltered?.map(
                                  (profile: IProfiles, index: number) => {
                                    return (
                                      <ContainerProfiles
                                        onClick={() => {
                                          if (selectedIndex === index) {
                                            setSelectedIndex(null);
                                          } else {
                                            setSelectedIndex(index);
                                            setFieldValue("client", profile.id);
                                          }
                                        }}
                                        key={String(index)}
                                        style={{
                                          background:
                                            selectedIndex === index
                                              ? "#6ea0fc"
                                              : "",
                                        }}
                                      >
                                        <Margin ml={5} />
                                        <div
                                          style={{
                                            background:
                                              selectedIndex === index
                                                ? "#85b0ff"
                                                : "#b8b8b8",
                                            borderRadius: "50%",
                                            width: "50px",
                                            height: "50px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <h4
                                            style={{
                                              color:
                                                selectedIndex === index
                                                  ? "#0f62fe"
                                                  : "",
                                            }}
                                          >
                                            {getFirstLetters(profile.name)}
                                          </h4>
                                        </div>
                                        <Margin ml={15} />
                                        <div>
                                          <p style={{ fontWeight: "bold" }}>
                                            {profile.name}
                                          </p>
                                          <p>
                                            {formatCPFCNPJ(
                                              profile.document_number
                                            )}
                                          </p>
                                        </div>
                                      </ContainerProfiles>
                                    );
                                  }
                                )}
                              </div>
                            ) : loadingSearch ? (
                              <div
                                style={{
                                  height: "200px",
                                  overflow: "auto",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Loading withOverlay={false} />
                              </div>
                            ) : !loadingSearch && values.search !== "" ? (
                              <div
                                style={{
                                  height: "200px",
                                  overflow: "auto",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <h4>Nenhum resultado encontrado</h4>
                              </div>
                            ) : (
                              <div
                                style={{
                                  height: "20px",
                                  overflow: "auto",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              ></div>
                            )}
                          </div>
                        )}
                        <Margin mb={5} />
                        <Label text="Selecione o fundo" />
                        <Select
                          options={
                            userProfile?.type !== "client" &&
                            userProfile?.type !== "manager"
                              ? fundsOptions
                              : companyFundsOptions
                          }
                          onChange={handleSelectFund}
                          placeholder="Pesquisar ou selecionar fundo..."
                        />{" "}
                        <Margin mb={5} />
                        <Label text="Selecione o tipo de atendimento" />
                        <Select
                          isClearable={true}
                          options={areaTypesOptions}
                          onChange={(selectedOption, actionMeta) =>
                            handleSelectAreaType(selectedOption, actionMeta)
                          }
                          placeholder="Pesquisar ou selecionar Tipo de atendimento..."
                        />
                        <Margin mb={5} />
                        <Label text="Selecione seus documentos para enviar" />
                        <div
                          {...getRootProps()}
                          style={{
                            border: "2px dashed #cccccc",
                            padding: "20px",
                            textAlign: "center",
                          }}
                        >
                          <input {...getInputProps()} />

                          <p>
                            Arraste e solte alguns arquivos aqui, ou clique para
                            selecionar os arquivos
                          </p>
                        </div>
                        <div>
                          <Margin mb={10} />
                          <div>
                            <Modal
                              open={modalIsOpen}
                              onRequestClose={closeModal}
                              primaryButtonText="Fechar visualização"
                              onRequestSubmit={closeModal}
                            >
                              <>
                                {selectedFile && (
                                  <div>
                                    <h2>{selectedFile.name}</h2>
                                    {selectedFile.type.startsWith("image/") ? (
                                      <img
                                        src={selectedFile.preview}
                                        alt={selectedFile.name}
                                        style={{ width: "100%" }}
                                      />
                                    ) : selectedFile.type ===
                                      "application/pdf" ? (
                                      <iframe
                                        src={selectedFile.preview}
                                        title={`Preview of ${selectedFile.name}`}
                                        style={{
                                          width: "100%",
                                          height: "500px",
                                        }}
                                      />
                                    ) : (
                                      <div
                                        style={{
                                          height: "400px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <h4>
                                          Tipo de arquivo não suportado para
                                          visualização.{" "}
                                          <a
                                            href={selectedFile.preview}
                                            download={selectedFile.name}
                                          >
                                            Clique aqui para baixar
                                          </a>
                                        </h4>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </>
                            </Modal>
                            {files.map((file, index) => (
                              <ContainerFile key={String(index)}>
                                <p>{file.name}</p>
                                <div
                                  className="d-flex"
                                  style={{ display: "flex" }}
                                >
                                  <Button
                                    hasIconOnly
                                    renderIcon={View}
                                    iconDescription="Visualizar"
                                    size="sm"
                                    kind="ghost"
                                    onClick={() => openModal(file)}
                                  />
                                  {/* <Margin ml={10} />
                            <Button
                              hasIconOnly
                              renderIcon={Download}
                              iconDescription="Download"
                              size="sm"
                              kind="ghost"
                              onClick={() =>
                                downloadFile(file.file, file.description)
                              }
                            /> */}
                                  <Margin ml={10} />
                                  <Button
                                    hasIconOnly
                                    renderIcon={TrashCan}
                                    size="sm"
                                    kind="ghost"
                                    onClick={() => {
                                      handleDelete(file);
                                    }}
                                    iconDescription="Excluir"
                                  />
                                </div>
                              </ContainerFile>
                            ))}
                          </div>
                        </div>
                        <Margin mb={5} />
                        <TextArea
                          id="message"
                          name="message"
                          labelText="Mensagem"
                          required
                          placeholder="Digite a mensagem"
                          value={values.message}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ resize: "none" }}
                        />
                      </Stack>
                    </Column>

                    <Column span={12}>
                      <Margin mb={64} />
                      <Grid>
                        <Column span={3}>
                          <Button
                            kind="tertiary"
                            className="action-button"
                            onClick={() => navigate("/app/attends/")}
                          >
                            Cancelar
                          </Button>
                        </Column>
                        <Column span={3}>
                          <Button
                            kind="primary"
                            className="action-button"
                            type="submit"
                            disabled={
                              !isValid ||
                              loading ||
                              (otherClient && values.client === "")
                            }
                          >
                            Enviar
                          </Button>
                        </Column>
                      </Grid>
                    </Column>

                    <Column span={6} />
                  </Grid>

                  <Margin mb={64} />
                </Column>
              </Grid>

              <Margin mb={64} />
            </Column>
          </Grid>
        </form>
      ) : (
        <div
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {" "}
          <h4> Você não possui permissão para criar atendimentos</h4>
        </div>
      )}
    </TicketFormContainer>
  );
};

export default NewAttend;
