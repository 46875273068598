import {
  Table,
  TableBatchAction,
  TableBatchActions,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Grid,
  Column,
  Button,
  Pagination,
} from "@carbon/react";

import {
  TrashCan,
  Add,
  Save,
  Download,
  Edit,
  Renew,
} from "@carbon/icons-react";
import Margin from "~/components/Margin";

import { useCallback, useEffect, useMemo, useState } from "react";
import useLoading from "~/hooks/useLoading";

// import { useFormik } from "formik";
import Padding from "~/components/Padding";

import useArea from "~/hooks/useArea";

import { permissions } from "~/services/permissions";

import { useNavigate } from "react-router-dom";
import { getAttendAreaProfilesAPI } from "~/services/api/attendAreaProfile";

import { getAreaTypesAPI } from "~/services/api/areaTypes";
import useAreaType from "~/hooks/useAreaType";
import { getCompanyTypesAPI } from "~/services/api/company-types";
import useCompanyType from "~/hooks/useCompanyType";
//import { Breadcrumb } from "antd";
import NewCompanyTypeModal from "./NewCompanyTypeModal";
import DeleteCompanyTypeModal from "./DeleteCompanyType";
import EditCompanyTypeModal from "./EditCompanyTypeModal";
import { Breadcrumb } from "prosperita-dumbo-react";
const tableHeadersArea: string[] = ["Nome", ""];

const CompanyTypes = () => {
  const { setLoading } = useLoading();
  const {
    companyTypes,
    setCompanyTypes,
    setOpenNewCompanyTypeModal,
    openNewCompanyTypeModal,
    setOpenDeleteCompanyTypeModal,
    openEditCompanyTypeModal,
    setOpenEditCompanyTypeModal,
    openDeleteCompanyTypeModal,
    setCompanyTypeDetails,
  } = useCompanyType();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const paginatedCompanyTypes = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return companyTypes
      ?.sort((a, b) =>
        a.name.localeCompare(b.name, "pt-BR", { sensitivity: "base" })
      )
      ?.slice(start, end);
  }, [currentPage, itemsPerPage, companyTypes]);

  const {
    // areas,
    // setOpenDeleteAreaModal,

    // setAreaDetails,
    setAttendAreaProfiles,

    // setOpenEditAreaModal,
  } = useArea();

  const { setAreaTypes } = useAreaType();

  const navigate = useNavigate();

  //   const [
  //     // openNewAreaModal,
  //     setOpenNewAreaModal,
  //   ] = useState<boolean>(false);

  const canReadAreas = permissions.includes("can_read_areas");
  const canCreateAreas = permissions.includes("can_create_areas");
  const canEditAreas = permissions.includes("can_edit_areas");
  const canDeleteAreas = permissions.includes("can_delete_areas");

  // const { values } = useFormik({
  //   initialValues: {
  //     search: "",
  //   },
  //   onSubmit: (values: { search: string }) => {
  //     console.log(values);
  //   },
  // });

  const fetchCompanyTypes = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getCompanyTypesAPI();
      setCompanyTypes(data.results.reverse());
      setLoading(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, [setCompanyTypes, setLoading]);

  const fetchAttendAreaProfiles = useCallback(async () => {
    try {
      const { data } = await getAttendAreaProfilesAPI();
      setAttendAreaProfiles(data.results);
    } catch (e) {
      console.log(e);
    }
  }, [setAttendAreaProfiles]);

  const fetchAreaTypes = useCallback(async () => {
    try {
      const { data } = await getAreaTypesAPI();

      setAreaTypes(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setAreaTypes]);

  useEffect(() => {
    fetchCompanyTypes();
    fetchAttendAreaProfiles();
    fetchAreaTypes();
  }, [fetchCompanyTypes, fetchAreaTypes, fetchAttendAreaProfiles]);

  return (
    <Grid>
      <Column span={16}>
        <Padding y={16}>
          <Margin mt={20} />
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              fontWeight: 100,
              alignItems: "center",
              margin: 0,
              padding: 0,
            }}
          >
            <Breadcrumb
              crumbsOptions={[
                {
                  crumb: "Empresas",
                  onClick: () => navigate("/app/companys/"),
                },
                {
                  crumb: "Tipos de empresa",
                  onClick: () => navigate(`/app/company-types`),
                },
              ]}
              endHiddenIndex={0}
              startHiddenIndex={5}
            />
          </div>
        </Padding>
        <h1>Tipos de empresa</h1>
        <Margin mt={24} />
        <TableContainer>
          <TableToolbar>
            {canReadAreas && (
              <Button
                tabIndex={0}
                onClick={() => {
                  fetchCompanyTypes();
                }}
                kind="secondary"
                renderIcon={Renew}
              >
                Atualizar
              </Button>
            )}
            {!canReadAreas && (
              <div
                style={{
                  height: "400px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <h4 style={{ position: "relative", left: "45%" }}>
                  Você não possui permissão para visualizar os tipos de empresa
                </h4>
              </div>
            )}
            <TableBatchActions onCancel={console.log} totalSelected={0}>
              <TableBatchAction
                tabIndex={0}
                renderIcon={TrashCan}
                onClick={console.log}
              >
                Delete
              </TableBatchAction>
              <TableBatchAction
                hasIconOnly
                iconDescription="Add"
                tabIndex={0}
                renderIcon={Add}
                onClick={console.log}
              >
                Delete
              </TableBatchAction>
              <TableBatchAction
                hasIconOnly
                iconDescription="Save"
                tabIndex={0}
                renderIcon={Save}
                onClick={console.log}
              >
                Save
              </TableBatchAction>
              <TableBatchAction
                tabIndex={0}
                renderIcon={Download}
                onClick={console.log}
              >
                Download
              </TableBatchAction>
            </TableBatchActions>
            <TableToolbarContent>
              {/* <TableToolbarSearch
                  tabIndex={0}
                  onChange={handleChange}
                  value={values.search}
                  id="search"
                  placeholder="Pesquisar"
                /> */}
              {canCreateAreas && (
                <Button
                  tabIndex={0}
                  onClick={() => {
                    setOpenNewCompanyTypeModal(true);
                  }}
                  kind="primary"
                >
                  Novo Tipo de empresa
                </Button>
              )}
            </TableToolbarContent>
          </TableToolbar>
          {canReadAreas && (
            <Table>
              <TableHead>
                <TableRow>
                  {/* <TableSelectAll
                    name={""}
                    id={""}
                    onSelect={console.log}
                    checked={false}
                    ariaLabel={""}
                  /> */}
                  {tableHeadersArea.map((columnTitle, index) => {
                    return (
                      <TableHeader key={String(index)}>
                        {columnTitle}
                      </TableHeader>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedCompanyTypes?.length === 0 ? (
                  <TableCell colSpan={tableHeadersArea.length + 1}>
                    <Padding pt={24} pb={24} pl={12}>
                      <div
                        style={{
                          height: "300px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <h4>Nenhum tipo de empresa criado até o momento</h4>
                      </div>
                    </Padding>
                  </TableCell>
                ) : paginatedCompanyTypes.length > 0 ? (
                  paginatedCompanyTypes?.map((area, index) => (
                    <TableRow
                      key={String(index)}
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(`/app/areas/${area.id}/`)}
                    >
                      {/* <TableSelectRow
                        name={""}
                        id={""}
                        onSelect={console.log}
                        checked={false}
                      /> */}
                      <TableCell>{area.name}</TableCell>

                      <TableCell>
                        {/* <Button
                          kind="ghost"
                          hasIconOnly
                          iconDescription="Detalhes"
                          renderIcon={View}
                          id="filter"
                        /> */}
                        {canEditAreas && (
                          <Button
                            kind="ghost"
                            hasIconOnly
                            iconDescription="Editar"
                            renderIcon={Edit}
                            id="filter"
                            onClick={(e) => {
                              e.stopPropagation();
                              setCompanyTypeDetails(area);
                              setOpenEditCompanyTypeModal(true);
                            }}
                          />
                        )}
                        {canDeleteAreas && (
                          <Button
                            kind="ghost"
                            hasIconOnly
                            iconDescription="Excluir"
                            renderIcon={TrashCan}
                            id="filter"
                            onClick={(e) => {
                              e.stopPropagation();
                              setCompanyTypeDetails(area);
                              setOpenDeleteCompanyTypeModal(true);
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  paginatedCompanyTypes?.map((area, index) => (
                    <TableRow
                      key={String(index)}
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(`/app/areas/${area.id}`)}
                    >
                      {/* <TableSelectRow
                        name={""}
                        id={""}
                        onSelect={console.log}
                        checked={false}
                      /> */}
                      <TableCell>{area.name}</TableCell>

                      <TableCell>
                        {/* <Button
                          kind="ghost"
                          hasIconOnly
                          iconDescription="Detalhes"
                          renderIcon={View}
                          id="filter"
                        /> */}
                        {canEditAreas && (
                          <Button
                            kind="ghost"
                            hasIconOnly
                            iconDescription="Editar"
                            renderIcon={Edit}
                            id="filter"
                            onClick={(e) => {
                              e.stopPropagation();
                              setCompanyTypeDetails(area);
                              setOpenEditCompanyTypeModal(true);
                            }}
                          />
                        )}
                        {canDeleteAreas && (
                          <Button
                            kind="ghost"
                            hasIconOnly
                            iconDescription="Excluir"
                            renderIcon={TrashCan}
                            id="filter"
                            onClick={(e) => {
                              e.stopPropagation();
                              setCompanyTypeDetails(area);
                              setOpenDeleteCompanyTypeModal(true);
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          )}
          <Pagination
            totalItems={companyTypes.length}
            pageSize={itemsPerPage}
            pageSizes={[5, 10, 20, 30, 40, 50]}
            onChange={({ page, pageSize }) => {
              setCurrentPage(page);
              setItemsPerPage(pageSize);
            }}
            page={currentPage}
            size="md"
          />
        </TableContainer>
      </Column>
      <NewCompanyTypeModal
        onClose={() => setOpenNewCompanyTypeModal(false)}
        open={openNewCompanyTypeModal}
      />
      <EditCompanyTypeModal
        onClose={() => setOpenEditCompanyTypeModal(false)}
        open={openEditCompanyTypeModal}
      />
      <DeleteCompanyTypeModal
        onClose={() => setOpenDeleteCompanyTypeModal(false)}
        open={openDeleteCompanyTypeModal}
      />
    </Grid>
  );
};

export default CompanyTypes;
