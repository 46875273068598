import {
  Button,
  Table,
  TableBatchAction,
  TableBatchActions,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
} from "@carbon/react";
import Margin from "~/components/Margin";
import Padding from "~/components/Padding";
import Badge from "~/components/Badge";
import useAttend from "~/hooks/useAttend";
import { useCallback, useEffect } from "react";

import useLoading from "~/hooks/useLoading";
import { getAttendAPI } from "~/services/api/attend";
import { useNavigate } from "react-router-dom";

const tableHeadersAttend: string[] = [
  "Nº",
  "Status",
  "Área",
  "Atendente",
  "SLA",
];

const renderStatus: { [key: string]: React.ReactNode } = {
  opened: <Badge label="Em aberto" color="#e3e3e3" textColor="#696868" />,
  created: <Badge label="Criado" color="#e3e3e3" textColor="#696868" />,
  in_progress: (
    <Badge color="#ffe9c9" textColor="#ff9900" label="Em andamento" />
  ),
  done: <Badge color="#CCEFDF" textColor="#00B360" label="Finalizado" />,
  return_to_client: (
    <Badge color="#bacbff" textColor="#1906ec" label="Retor. solicitante" />
  ),
  canceled: <Badge label="Cancelado" textColor="#ff0000" color="#f9c6c6" />,
};

const SubAttends = () => {
  const { attendDetails, setNewSubAttend, setSubAttends, subAttends } =
    useAttend();
  const { setLoading } = useLoading();
  const navigate = useNavigate();

  const fetchSubAttends = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getAttendAPI();
      const filteredData = data.results.filter(
        (attend) => attend.parent === Number(attendDetails?.id)
      );

      setSubAttends(filteredData.reverse().sort((a, b) => b.id - a.id));

      setLoading(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchSubAttends();
  }, []);

  console.log(subAttends);

  return (
    <div>
      <Margin mb={30} />
      <TableContainer title="Chamados" />
      <Margin mb={-2} />
      <TableContainer>
        <TableToolbar>
          {/*    
              <Button
                tabIndex={0}
                onClick={() => {
                  // fetchAttends();
                }}
                kind="secondary"
                renderIcon={Renew}
              >
                Atualizar
              </Button> */}

          <TableBatchActions onCancel={console.log} totalSelected={0}>
            <TableBatchAction
              hasIconOnly
              iconDescription="Add"
              tabIndex={0}
              onClick={console.log}
            >
              Delete
            </TableBatchAction>
          </TableBatchActions>
          <TableToolbarContent>
            {/* <TableToolbarSearch
                  tabIndex={0}
                  onChange={handleChange}
                  value={values.search}
                  id="search"
                  placeholder="Pesquisar"
                /> */}

            {/* {user.type === "tool_manager" && (
                  <Tooltip defaultOpen label="Exportar relatório" align="top">
                    <Button
                      kind="ghost"
                      hasIconOnly
                      renderIcon={Report}
                      id="filter"
                    />
                  </Tooltip>
                )}
                <Tooltip defaultOpen label="Filtrar" align="top">
                  <Button
                    kind="ghost"
                    hasIconOnly
                    renderIcon={Filter}
                    id="filter"
                    onClick={() => setOpenModal(true)}
                  />
                </Tooltip> */}

            <Button
              tabIndex={0}
              disabled={attendDetails?.parent !== null}
              onClick={() => {
                setNewSubAttend(true);
              }}
              kind="primary"
            >
              Novo chamado
            </Button>
          </TableToolbarContent>
        </TableToolbar>

        <Table>
          <TableHead>
            <TableRow>
              {/* <TableSelectAll
                    name={""}
                    id={""}
                    onSelect={console.log}
                    checked={false}
                    ariaLabel={""}
                  /> */}
              {tableHeadersAttend.map((columnTitle, index) => {
                return (
                  <TableHeader key={String(index)}>{columnTitle}</TableHeader>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {subAttends?.length === 0 ? (
              <TableCell colSpan={tableHeadersAttend.length + 1}>
                <Padding pt={24} pb={24} pl={12}>
                  <div
                    style={{
                      height: "300px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {attendDetails?.parent !== null ? (
                      <h4 style={{ textAlign: "center" }}>
                        Este é um atendimento interno e já está atribuído á um
                        atendimento principal, por este <br /> motivo não é
                        possível criar um chamado interno para ele.
                      </h4>
                    ) : (
                      <h4>Nenhuma chamado criado até o momento.</h4>
                    )}
                  </div>
                </Padding>
              </TableCell>
            ) : subAttends.length > 0 ? (
              subAttends?.map((subAttend, index) => (
                <TableRow
                  key={String(index)}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate(`/app/attends/sub-attend/${subAttend.id}`)
                  }
                >
                  {/* <TableSelectRow
                        name={""}
                        id={""}
                        onSelect={console.log}
                        checked={false}
                      /> */}
                  <TableCell>#{subAttend.id}</TableCell>
                  <TableCell>
                    {renderStatus[subAttend?.status?.slug ?? "opened"]}
                  </TableCell>
                  <TableCell>{subAttend?.area?.name ?? "-"}</TableCell>
                  {/* <TableCell>{attend.area}</TableCell>
                    <TableCell>{attend.area_manager}</TableCell> */}
                  <TableCell>
                    <p>
                      {" "}
                      <b>{subAttend?.owner?.name ?? "-"}</b>
                    </p>
                  </TableCell>
                  <TableCell>
                    <p>SLA</p>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              subAttends?.map((subAttend, index) => (
                <TableRow
                  key={String(index)}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate(`/app/attends/sub-attend/${subAttend.id}`)
                  }
                >
                  {/* <TableSelectRow
                      name={""}
                      id={""}
                      onSelect={console.log}
                      checked={false}
                    /> */}

                  <TableCell>
                    {renderStatus[subAttend?.status?.slug ?? "opened"]}
                  </TableCell>
                  <TableCell>{subAttend?.type?.name ?? "-"}</TableCell>
                  {/* <TableCell>{attend.area}</TableCell>
                  <TableCell>{attend.area_manager}</TableCell> */}
                  <TableCell>
                    <p>
                      {" "}
                      <b>{subAttend?.owner?.name ?? "-"}</b>
                    </p>
                  </TableCell>

                  <TableCell>
                    <p>SLA</p>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SubAttends;
